import axios from "axios";

const fetchClient = () => {
  return axios.create({
    baseURL: window.location.origin,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Security-Policy": "img-src 'self' blob:",
      "Content-Type": "application/json",
      // Authorization: Cookie.get("access_token"),
      country_code: localStorage.getItem("country_code")
    }
  });
};

export default fetchClient;

import { FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";

const FormInput = ({
  label,
  placeholder,
  value,
  type,
  name,
  handleChange,
  error,
  disable
}) => {
  return (
    <FieldWrapper
      className={type === "basic" ? "col-12 col-md-6 mb-3" : "mb-3"}
    >
      <Label editorValid={true}>{label}</Label>
      <Input
        name={name}
        style={
          error
            ? { border: "1px solid #f31700" }
            : { border: "1px solid rgba(66, 66, 66, 0.5)" }
        }
        disabled={disable}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
      />
    </FieldWrapper>
  );
};

export default FormInput;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  country:true,
  city: true,
  office: true
};

export const reloadSlice = createSlice({
  name: "reload",
  initialState,
  reducers: {
    cityChange: (state) => {
      state.city = !state.city;
    },
    officeChange: (state) => {
      state.office = !state.office;
    },
    countryChange: (state) => {
      state.country = !state.country;
    }

  }
});

export const { cityChange, officeChange, countryChange } = reloadSlice.actions;

export default reloadSlice.reducer;

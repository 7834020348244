/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../assets/css/home/HomeHistoryDialog.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";

const HomeHistoryDialog = ({ toggleDialog, cancelItem }) => {
    const { t } = useTranslation();

  return (
    <div>
      <div className="home-history-dialog">
        <Dialog onClose={toggleDialog} className="book-cancel-dialog">
          <div className="dialog-container">
            <p className="dialog-title ">
                          {t("cancel_booking")}
            </p>
            <div className="dialog-footer">
              <button className="dialog-btn btn-no" onClick={toggleDialog}>
                              {t("no")}
              </button>
              <button className="dialog-btn btn-yes" onClick={cancelItem}>
                              {t("yes")}
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default HomeHistoryDialog;

import React from "react";
import {
  BsCalendarEvent,
  BsClock,
  BsDoorOpen,
  BsGeoAltFill,
  BsInfoCircle,
  BsPeopleFill,
  BsPersonFill,
  BsPinAngle,
} from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/home/BookForApprovalModal.css";
import pendingIcon from "../../assets/img/booking_pending.png";

const BookForApprovalModal = () => {
  //Home Navigation Function
  let Navigation = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="right-content w-100">
        <div className="book-success">
          <div className="book-success-box row m-0 px-4 py-5 px-md-5">
            <div className="book-success-header p-0">
              <img src={pendingIcon} alt="pending icon" />
              <h3 className="iBookingForApproval">Meeting room request has been sent for approval</h3>
            </div>
            <div className="book-success-content p-0">
              <div className="d-flex row p-0 m-0">
                <div className="col-12 col-md-6 d-flex align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsCalendarEvent />
                  </div>
                  <div>
                    <span>{location.state.date}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsClock />
                  </div>
                  <div>
                    <span>{location.state.time}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsPersonFill />
                  </div>
                  <div>
                    <span>
                      {location.state.type === 1
                        ? "Individual"
                        : location.state.type === 2
                          ? "Meeting pod"
                          : "Meeting room"}
                    </span>
                  </div>
                </div>

                <div className="col-12 col-md-6 d-flex align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsDoorOpen />
                  </div>
                  <div>
                    <span className="book-highlighted">
                      {location.state.type === 1
                        ? `${location.state.payload.roomName}` +
                        " - " +
                        "Seat # " +
                        `${location.state.payload.seatNumber}`
                        : location.state.type === 2
                          ? location.state.payload.roomName
                          : location.state.payload.roomName}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-self-start align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsGeoAltFill />
                  </div>
                  <div>
                    <span>{location.state.officeName}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center gap-4 mb-4 p-0 px-md-5">
                  <>
                    <div>
                      <BsPinAngle />
                    </div>
                    <div className="success-link" >
                      <span>
                        <div className="d-flex flex-column">
                          <strong>Additional Information:</strong>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={location.state.link}
                            className="reminder-link"
                          >
                            Health and Safety Reminders - Click Here
                          </a>
                        </div>
                      </span>
                    </div>
                  </>
                </div>
                <div className="col-12 col-md-6 d-flex align-self-start align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsPeopleFill />
                  </div>
                  <div>
                    <span className="email-names">
                      {`${location.state.intParticipants || ''}${location.state.intParticipants && location.state.extParticipants ? '; ' : ''}${location.state.extParticipants || ''}`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center justify-content-center gap-4 mb-4 mt-md-4">
                <div>
                  <BsInfoCircle />
                </div>
                <div>
                  <span>
                    You will receive an e-mail notification with this
                    information
                  </span>
                </div>
              </div>
            </div>
            <div className="book-success-footer d-flex justify-content-center p-0 px-2 mt-3 mt-md-5">
              <button
                className="home-book-btn"
                onClick={() => Navigation("/home")}
              >
                <span>Home</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookForApprovalModal;

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import "../../assets/css/home/HomeHistory.css";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import React, { useState, useEffect } from "react";
import HomeHistoryDialog from "./HomeHistoryDialog";
import { Loader } from "@progress/kendo-react-indicators";
import { FaBan } from "react-icons/fa";
import MobileViewTable from "../MobileViewTable";
import { useTranslation } from "react-i18next";
import fetchClient from "../../axios/fetch";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const HomeHistory = () => {
  const { t } = useTranslation();
  let Navigate = useNavigate();
  const officeTrigger = useSelector((state) => state.reload.office);
  const auth = useSelector((state) => state.auth);

  // UseStates
  const [state, setState] = useState({});
  const [bookHistoryData, setBookHistoryData] = useState([]);
  const [isBookHistoryLoading, setIsBookHistoryLoading] = useState(true);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [visible, setVisible] = useState(!true);
  const [currentNum, setCurrentNum] = useState(0);
  const [bookingId, setBookingId] = useState(null);
  const [displayHistory, setDisplayHistory] = useState(true);
  const [officeDetails, setOfficeDetails] = useState({});

  useEffect(() => {
    bookingHistory();
    getOfficeDetails();
  }, [officeTrigger, t]);

  useEffect(() => {
    setState(createState(0, 2));
  }, [bookHistoryData]);

  const getOfficeDetails = () => {
    fetchClient()
      .get("api/office/" + localStorage.getItem("office"))
      .then((res) => {
        const officeName = res.data.data.officeName;
        fetchClient()
          .get(`api/reminder/${localStorage.getItem("office")}`)
          .then((res) => {
            setOfficeDetails({
              officeName,
              link:
                res.data.data.link && res.data.data.isEnable
                  ? res.data.data.link
                  : "",
            });
          })
          .catch((err) => {
            // console.log(err);
            setOfficeDetails({
              officeName,
              link: "",
            });
          });
      })
      .catch();
  };

  const bookingHistory = () => {
    fetchClient()
      .get(
        `api/user/userhistory/${auth.user.id}/${localStorage.getItem("office")}`
      )
      .then((res) => {
        if (res.data.data.length > 0) {
          setDisplayHistory(true);
          setIsBookHistoryLoading(false);
          let holdArray = [];
          res.data.data.map((e) => {
            let bookingType;
            switch (e.bookingType) {
              case "individual":
                bookingType = t("individual");
                break;
              case "meeting room":
                bookingType = t("meeting_room");
                break;
              case "meeting pod":
                bookingType = t("meeting_pod");
                break;
              default:
                break;
            }
            const data = {
              id: e.id,
              status: e.status,
              date: `${e.startDate} - ${e.endDate}`,
              time: `${e.startHour} - ${e.endHour}`,
              bookingType:
                bookingType.charAt(0).toUpperCase() + bookingType.slice(1),
              roomName:
                e.roomName.charAt(0).toUpperCase() + e.roomName.slice(1),
              seatNumber: e.seatNumber,
              internalParticipant: e.internalParticipant,
              externalParticipant: e.externalParticipant,
            };
            holdArray.push(data);
          });
          setBookHistoryData(holdArray);
        } else {
          setDisplayHistory(false);
        }
      })
      .catch((err) => {
        setIsBookHistoryLoading(false);
        // console.log(err);
      });
  };

  // const bookingHistory = () => {
  //   fetchClient()
  //     .get(
  //       `api/user/userhistory/${auth.user}/${localStorage.getItem("office")}`
  //     )
  //     .then((res) => {
  //       if (res.data.data.length > 0) {
  //         setDisplayHistory(true);
  //         setIsBookHistoryLoading(false);
  //         let holdArray = [];
  //         res.data.data.map((e) => {
  //           let bookingType;
  //           switch (e.bookingType) {
  //             case "individual":
  //               bookingType = t("individual");
  //               break;
  //             case "meeting room":
  //               bookingType = t("meeting_room");
  //               break;
  //             case "meeting pod":
  //               bookingType = t("meeting_pod");
  //               break;
  //             default:
  //               break;
  //           }
  //           fetchClient()
  //             .get("api/office/" + localStorage.getItem("office"))
  //             .then((res) => {
  //               const data = {
  //                 id: e.id,
  //                 status: e.status,
  //                 date: `${e.startDate} - ${e.endDate}`,
  //                 time: `${e.startHour} - ${e.endHour}`,
  //                 bookingType:
  //                   bookingType.charAt(0).toUpperCase() + bookingType.slice(1),
  //                 roomName:
  //                   e.roomName.charAt(0).toUpperCase() + e.roomName.slice(1),
  //                 seatNumber: e.seatNumber,
  //                 internalParticipant: e.internalParticipant,
  //                 externalParticipant: e.externalParticipant,
  //                 officeName: res.data.data.officeName,
  //               };
  //               let finalData;
  //               fetchClient()
  //                 .get(`api/reminder/${localStorage.getItem("office")}`)
  //                 .then((res) => {
  //                   finalData = {
  //                     ...data,
  //                     link:
  //                       res.data.data.link && res.data.data.isEnable
  //                         ? res.data.data.link
  //                         : "",
  //                   };
  //                 })
  //                 .catch((err) => {
  //                   console.log(err);
  //                   finalData = {
  //                     ...data,
  //                     link: "",
  //                   };
  //                 })
  //                 .finally(() => {
  //                   holdArray.push(finalData);
  //                   setBookHistoryData(holdArray);
  //                 });
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //             });
  //         });
  //       } else {
  //         setDisplayHistory(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsBookHistoryLoading(false);
  //       console.log(err);
  //     });
  // };

  // Pagination
  const pageSizes = [5, 8, 10];
  const createState = (skip, take) => {
    let pagerSettings = {
      buttonCount: 3,
      info: false,
      type: "numeric",
      pageSizes: pageSizes,
      previousNext: true,
      text: "Rows per page",
    };
    return {
      items: bookHistoryData.slice(skip, skip + take),
      total: bookHistoryData.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings,
    };
  };

  const pageChange = (event) => {
    setState(createState(event.page.skip, event.page.take));
  };

  const statusCell = (props) => {
    let statusValue = "";

    switch (props.dataItem.status) {
      case "Active":
        statusValue = t("active");
        break;
      case "Cancelled":
        statusValue = t("cancelled");
        break;
      case "Approved":
        statusValue = t("approved");
        break;
      case "Declined":
        statusValue = t("declined");
        break;
      case "For Approval":
        statusValue = t("pending");
        break;
      default:
        break;
    }

    if (props.dataItem.status === "Active" || props.dataItem.status === "Approved") {
      return (
        <td colSpan={props.colSpan} style={props.style}>
          <span className="active-status">{statusValue}</span>
        </td>
      );
    } else if (props.dataItem.status === "For Approval") {
      return (
        <td colSpan={props.colSpan} style={props.style}>
          <span className="pending-status">{statusValue}</span>
        </td>
      );
    } else {
      return (
        <td colSpan={props.colSpan} style={props.style}>
          <span className="cancel-status">{statusValue}</span>
        </td>
      );
    }
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const cancelItem = () => {
    fetchClient()
      .get(
        `api/user/cancel/${currentWidth > 767 ? bookingId : bookHistoryData[currentNum].id
        }`
      )
      .then((res) => {
        const bookingData =
          currentWidth > 767
            ? bookHistoryData.find((data) => data.id === bookingId)
            : bookHistoryData[currentNum];
        Navigate("/bookCancel", {
          state: {
            payload: {
              ...bookingData,
              officeName: officeDetails.officeName,
              link: officeDetails.link,
            },
          },
        });
        setBookingId(null);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const cancelIcon = (props) => {
    const isCancelable = props.dataItem.status === "Cancelled" || props.dataItem.status === "Declined";

    return (
      <div className="cancel">
        {!isCancelable ? (
          <FaBan
            size="25px"
            className="cancel-btn"
            onClick={() => {
              setBookingId(props.dataItem.id);
              toggleDialog();
            }}
          />
        ) : (
          <FaBan size="25px" className="cancelled-btn" />
        )}
      </div>
    );
  };
  useEffect(() => {
    setState(createState(0, 5));
  }, [bookHistoryData]);

  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
  }, [currentWidth]);

  // mobile data
  const topLabels = [
    {
      label: t("cancel"),
      data: () => {
        if (bookHistoryData[currentNum].status === "Cancelled" || bookHistoryData[currentNum].status === "Declined") {
          return <FaBan size="25px" className="cancelled-btn" />;
        } else {
          return (
            <FaBan
              size="25px"
              className="cancel-btn"
              onClick={() => {
                toggleDialog(bookHistoryData[currentNum]);
              }}
            />
          );
        }
      },
    },
    {
      label: t("status"),
      data: () => {
        let statusValue = "";

        switch (bookHistoryData[currentNum].status) {
          case "Active":
            statusValue = t("active");
            return (
              <span className="active-status">{statusValue}</span>
            );
          case "Approved":
            statusValue = t("approved");
            return (
              <span className="active-status">{statusValue}</span>
            );
          case "For Approval":
            statusValue = t("pending");
            return (
              <span className="pending-status">{statusValue}</span>
            );
          case "Declined":
            statusValue = t("declined");
            return (
              <span className="cancel-status">{statusValue}</span>
            );
          default:
            statusValue = t("cancelled");
            return (
              <span className="cancel-status">{statusValue}</span>
            );
        }
      },
    },
    {
      label: t("date"),
      data: "date",
    },
    {
      label: t("time"),
      data: "time",
    },
    {
      label: t("booking_type"),
      data: "bookingType",
    },
    {
      label: t("room_name"),
      data: "roomName",
    },
    {
      label: t("seat"),
      data: "seatNumber",
    },
  ];
  const botLabels = [
    {
      label: t("additional_internal_participant"),
      data: "internalParticipant",
    },
    {
      label: t("additional_external_participant"),
      data: "externalParticipant",
    },
  ];

  return (
    <>
      {displayHistory && (
        <div className="history-module">
          <h5 className="history-title">{t("history")} </h5>
          {window.screen.width < 992 || currentWidth < 992 ? (
            <div>
              {isBookHistoryLoading && (
                <div className="overlay">
                  <p>{t("loading")} </p>
                  <Loader size="large" type="pulsing" />
                </div>
              )}
              <MobileViewTable
                currentNum={currentNum}
                setCurrentNum={setCurrentNum}
                data={bookHistoryData}
                topLabels={topLabels}
                botLabels={botLabels}
              />
            </div>
          ) : (
            <div className="history">
              {isBookHistoryLoading && (
                <div className="overlay">
                  <p>{t("loading")} </p>
                  <Loader size="large" type="pulsing" />
                </div>
              )}
              <Grid
                data={state.items}
                onPageChange={pageChange}
                total={state.total}
                skip={state.skip}
                pageable={state.pageable}
                pageSize={state.pageSize}
                itemsPerPageText="Rows per page"
                scrollable="none"
                width="890px"
              >
                <Column
                  field="Cancel"
                  title={t("cancel")}
                  cell={cancelIcon}
                  width="65px"
                />
                <Column
                  field="status"
                  title={t("status")}
                  cell={statusCell}
                  width="68px"
                  className="status"
                />
                <Column field="date" title={t("date")} width="95px" />
                <Column field="time" title={t("time")} width="79px" />
                <Column
                  field="bookingType"
                  title={t("booking_type")}
                  width="107px"
                />
                <Column field="roomName" title={t("room_name")} width="103px" />
                <Column field="seatNumber" title={t("seat")} width="86px" />
                <Column
                  field="internalParticipant"
                  title={t("additional_internal_participant")}
                  width="130px"
                />
                <Column
                  field="externalParticipant"
                  title={t("additional_external_participant")}
                  width="135px"
                />
              </Grid>
            </div>
          )}
          {visible && (
            <HomeHistoryDialog
              toggleDialog={toggleDialog}
              cancelItem={cancelItem}
            />
          )}
        </div>
      )}
    </>
  );
};

export default HomeHistory;

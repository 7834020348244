import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export const axiosInstance = axios.create({
    baseURL: window.location.origin,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Security-Policy": "img-src 'self' blob:",
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers.Country_code = localStorage.getItem("country_code");
  return config;
});

export default function useRequest(props) {
    const { url, method = "get", payload, onMount, isFirstData } = props;
    const [status, setStatus] = useState("idle");
    const [data, setData] = useState();
    const [error, setError] = useState();

    const payloadType = method.toUpperCase() === "GET" ? "params" : "data";

    const request = useCallback(
        (payload) => {
            setStatus("loading");
            axiosInstance({ url, method, [payloadType]: payload })
                .then((response) => {
                    setStatus("success");
                    const responseData = isFirstData ? response.data : response.data.data;
                    setData(responseData);
                })
                .catch((error) => {
                    setStatus("error");
                    setError(error);
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [url]
    );

    useEffect(() => {
        onMount && request(payload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isIdle = status === "idle";
    const isLoading = status === "loading";
    const isSuccess = status === "success";
    const isError = status === "error";

    return [{ isIdle, isLoading, isSuccess, isError, data, error }, request];
}

import { createSlice } from "@reduxjs/toolkit";

import Cookie from "../../axios/Cookies";

const initialState = {
  isAuthenticated: false,
  user: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, actions) => {
      state.user = actions.payload;
      localStorage.setItem("country_code", actions.payload.country);
      localStorage.setItem("city", actions.payload.cityId);
      localStorage.setItem("office", actions.payload.officeId);
    },
    setAuthenticated: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isAuthenticated = false;
      Cookie.remove("sba");
      Cookie.remove("perf_dv6Tr4n");
    },
  },
});

export const { logout, setUser, setAuthenticated} = authSlice.actions;

export default authSlice.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import CountrySquare from "./CountrySquare";
import fetchClient from "../../axios/fetch";
import { setUser } from "../../reducer/slice/authSlice";
import ph from "../../assets/img/philippines.png";
import co from "../../assets/img/rec_Col_Chi.png";
//import cl from "../../assets/img/chile.png";
import "../../assets/css/login/FirstLogin.css";

const phCities = ["Pasig", "Manila", "Cebu"];
const coCities = ["Bogotá", "Medellín"];
//const clCities = ["Santiago"];

const FirstLogin = () => {
  let Navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const countries = [
    {
      name: "DDC-C",
      src: co,
    },
    {
      name: "DDC-P",
      src: ph,
    },
    // {
    //   name: "Chile",
    //   src: cl,
    // },
  ];

  const [cities, setCities] = useState([]);
  const [offices, setOffices] = useState([]);

  const [values, setValues] = useState({
    country: "",
    city: t("select"),
    office: t("select"),
  });

  const [payload, setpayload] = useState({
    id: 0,
    officeId: 0,
    fname: "",
    lname: "",
    email: "",
    userRole: "user",
    platform: "",
    cityId: 0,
  });

  useEffect(() => {
    const countryMap = {
      //"Chile": { code: "cl", cities: clCities },
      "DDC-C": { code: "co", cities: coCities },
      "DDC-P": { code: "ph", cities: phCities }
    };

    // change data of cities dropdown when country is changed
    const selectedCountry = countryMap[values.country];

    if (selectedCountry) {
      localStorage.setItem("country_code", selectedCountry.code);
      getcity();
      setCities(selectedCountry.cities);
      setpayload({ ...payload, country: selectedCountry.code });
      setValues({ ...values, city: t("select") });
    }
  }, [values.country]);

  useEffect(() => {
    // changes data of offices to default when there is no city selected
    if (values.city) {
      setValues({ ...values, office: t("select") });
    }
  }, [values.city]);

  useEffect(() => {
    checkToken();

    // console.log(localStorage.getItem("country_code"));
  }, []);

  const handleChange = (e) => {
    // no component name means country div is being selected
    if (!e.target.name) {
      setValues({ ...values, country: e.currentTarget.innerText });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
      localStorage.setItem(e.target.name, e.target.value.id);

      if (e.target.name === "city") {
        getCountry(e.target.value.id);
        setpayload({ ...payload, cityId: e.target.value.id });
      }

      if (e.target.name === "office") {
        setpayload({ ...payload, officeId: e.target.value.id });
      }

      // console.log(e.target.value);
    }
  };

  const getcity = () => {
    setLoading(true);
    fetchClient()
      .get("api/city")
      .then((res) => {
        setCities(res.data.data);
        setLoading(false);
      });
    // .catch((err) => console.log(err));
  };

  const getCountry = (id) => {
    fetchClient()
      .get("api/office/getoffice/" + id)
      .then((res) => {
        // console.log(res.data);

        if (res.data.success) {
          setOffices(res.data.data);
        } else {
          alert(res.data.exception);
        }
      });
    // .catch((err) => console.log(err));
  };

  const pushLogin = () => {
    fetchClient()
      .post(`api/user`, payload)
      .then((res) => {
        dispatch(setUser(res.data.data));
        setTimeout(() => {
          Navigation("/");
        }, 100);
      })
      .catch(async (err) => {
        try {
          if (
            err.response.status === 400 &&
            err.response.data === "email already exists"
          ) {
            dispatch(setUser(payload));
            setTimeout(() => {
              Navigation("/");
            }, 100);
          }
        } catch (error) {
          //console.error(error);
        }
      });
  };

  const checkToken = () => {
    fetchClient()
      .get("api/authenticate/validate", {
        headers: {
          country_code: "ph",
        },
      })
      .then((res) => {
        setpayload({
          ...payload,
          fname: res.data.unique_name,
          lname: res.data.family_name,
          email: res.data.email,
          platform: res.data.platform,
        });
      });
  };

  return (
    <>
      <div className="first-login d-flex justify-content-center align-items-center px-3 py-4">
        <div className="content d-flex flex-column mb-3">
          <div className="subcont d-flex flex-column">
            <label>{t("country")}:</label>
            <div
              className="d-flex flex-wrap justify-content-evenly justify-content-md-between gap-3 align-items-center contenFlags"
            >
              {countries.map((country, idx) => (
                <CountrySquare
                  handleChange={handleChange}
                  key={idx}
                  country={country.name}
                  src={country.src}
                  selected={values.country}
                />
              ))}
            </div>
          </div>
          <div className="subcont d-flex flex-column">
            <label>{t("city")}:</label>
            <DropDownList
              name="city"
              value={values.city}
              data={cities}
              disabled={
                values.country === "" || loading === true ? true : false
              }
              textField="cityName"
              onChange={handleChange}
            />
          </div>
          <div className="subcont d-flex flex-column">
            <label>{t("office")}:</label>
            <DropDownList
              name="office"
              disabled={values.city === t("select")}
              value={values.office}
              data={offices}
              textField="officeName"
              onChange={handleChange}
            />
          </div>
          <button
            onClick={() => pushLogin()}
            disabled={values.office === t("select")}
            className="next-btn btn align-self-md-end mt-4 text-light fw-bold"
          >
            {t("next")}
          </button>
        </div>
      </div>
    </>
  );
};

export default FirstLogin;

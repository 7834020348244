import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/navbar/sidebar.css";
import logo from "../../assets/img/inchcape.png";
import { MdOutlineSort } from "react-icons/md";
import { IoMdLogIn } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../reducer/slice/authSlice";
import { useTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";


const Sidebar = () => {
  const auth = useSelector((state) => state.auth);
  let Navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [currentContents, setCurrentContents] = useState("main");
 
  return (
    <>
      <Navbar expand="md" className="sidenav">
        <Container className="p-0 flex-md-column align-self-start h-100">
          <div className="mobile-cont d-flex justify-content-between justify-content-md-start align-items-center w-100">
            <Navbar.Brand className="m-0 w-100">
              <Nav.Link
                className="logo d-flex gap-2 flex-column"
                onClick={() => {
                  Navigate("/home");
                }}
                active={location.pathname === "/home"}
              >
                <img className="logo-img" src={logo} alt="Inchcape Logo" />
                <span className="logo-title align-self-start">
                  {t("title")}
                </span>
              </Nav.Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav">
              <MdOutlineSort />
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse id="navbar-nav" className="w-100">
            <Nav className="flex-md-column justify-content-between w-100 h-100">
              <SidebarContent
                content={currentContents}
                setCurrentContents={setCurrentContents}
              />
              <div className="user-logout-module">
                <div className="user-profile">
                  <span className="user user-name">
                    {`${auth.user.fname} ${auth.user.lname}`} 
                  </span>
                  <span className="user user-email">{auth.user.email}</span>
                </div>

                <Button
                  className="logout-btn"
                  onClick={() => {
                    dispatch(logout());                 
                      Navigate("/")
                  }}
                >
                  <IoMdLogIn className="icons" size="1.2em" />
                  {t("logout")}
                </Button>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Sidebar;

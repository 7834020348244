import ApproverManagement from "./approver management/ApproverManagement";
import ListOfBookingToApprove from "./booking to approve/ListOfBookingToApprove";
import History from "./history/ApprovalHistory";

const ApprovalContent = ({ content }) => {
  switch (content) {
    case "approval-booking-list":
      return <ListOfBookingToApprove />;
    case "approval-management":
      return <ApproverManagement />; 
    case "approval-history":
      return <History />;
    default:
      break;
  }
};

export default ApprovalContent;

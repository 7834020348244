import "../../assets/css/approvals/ApproveDialog.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { t } from "i18next";
import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";

const initialDataState = {
  skip: 0,
  take: 5,
};

const ApproveDialog = ({
  toggleDialog,
  handleApproveSave,

  value,
}) => {
  const DATA_ITEM_KEY = "id";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [page, setPage] = React.useState(initialDataState);
  const pageChange = (event) => {
    setPage(event.page);
  };
  const [selectedState] = useState({});

  return (
    <Dialog className="approve-dialog" onClose={toggleDialog} width={450}>
      <div className="title" >
        {t("approval_details")}
      </div>
      <div className="approve-container ">
        <div className="tbl">
          <Grid
            style={{
              alignContent: "center",
              alignItems: "center",
            }}
            data={value
              .map((item) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
              }))
              .slice(page.skip, page.take + page.skip)}
            skip={page.skip}
            take={page.take}
            total={value.length}
            pageable={{
              buttonCount: 4,
              pageSizes: [5, 8, 10],
              info: false,
              type: "numeric",
              previousNext: true,
              text: "Rows per page",
            }}
            onPageChange={pageChange}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            itemsPerPageText="Rows per page"
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: "multiple",
            }}
            fixedScroll={true}
            sortable={true}
            filterable={false} //Change to true to show filter
          >
            <GridColumn
              field="bookedBy"
              title={t("booked_by")}
              width="200px"
            />
            <GridColumn
              field="dateRange"
              title={t("date")}
              width="200px"
              filter="date"
            />
            <GridColumn
              field="timeRange"
              title={t("time")}
              width="150px"
              filter="number"
            />
            <GridColumn
              field="roomName"
              title={t("room_name")}
              width="100px"
            />
            <GridColumn
              field="totalParticipants"
              title={t("headcount")}
              width="150px"
            />
            <GridColumn
              field="participants"
              title={t("participants")}
              width="250px"
            />
          </Grid>

        </div>
      </div>
      <div className="buttons">
        <button
          onClick={toggleDialog}
          className="Nobtn"
        >
          {t("cancel")}
        </button>

        <button
          onClick={handleApproveSave}
          className="Yesbtn btn"
        >
          {t("save")}
        </button>
      </div>
    </Dialog>
  );
};

export default ApproveDialog;

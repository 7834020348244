import React from "react";
import "../../assets/css/Report/report.css";
import ReportsContent from "./ReportsContent";
import { MdOutlineShowChart } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Report = () => {
  const { t } = useTranslation();

  return (
    <div className="report">
      <div className="report-mobile-header d-md-none d-flex align-items-center">
        <MdOutlineShowChart />
        <h1>{t("reports")}</h1>
      </div>
      <div className="reports-box py-3 px-2 py-md-5 px-md-4">
        <div className="reports-title mx-3">
          <h3>{t("search")}</h3>
        </div>
        {/* Reports / Content */}

        <div className="report-module row p-2 p-md-0 m-0">
          <ReportsContent />
        </div>
      </div>
    </div>
  );
};

export default Report;

/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import "../../../assets/css/approvals/history/ApprovalHistory.css";
import { RadioButton } from "@progress/kendo-react-inputs";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import fetchClient from "../../../axios/fetch";
import RejectDialog from "../RejectDialog";
import ApproveDialog from "../ApproveDialog";
import { useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import { filterBy } from "@progress/kendo-data-query";

// Const for the pagination
const initialDataState = {
  skip: 0,
  take: 5,
};

const initialFilter = {
  logic: "and",
  filters: [
    {
      field: "bookedBy",
      operator: "contains",
      value: "",
    },
    {
      field: "roomName",
      operator: "contains",
      value: "",
    },
    {
      field: "participants",
      operator: "contains",
      value: "",
    },
    {
      field: "dateRange",
      operator: "contains",
      value: "",
    },
    {
      field: "timeRange",
      operator: "contains",
      value: "",
    },
    {
      field: "totalParticipants",
      operator: "contains",
      value: "",
    },
  ],
};

const ApprovalHistory = () => {
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const DATA_ITEM_KEY = "id";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [bookings, setBookings] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [loginIsApprover, setLoginIsApprover] = useState(false);
  const [reason, setReason] = useState("");
  const [selectedOption, setSelectedOption] = useState("Approved");

  // Const for Notification
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  //const [currentNum, setCurrentNum] = useState(0);

  // Const for search user
  const [filter, setFilter] = useState(initialFilter);
  const [filteredData, setFilteredData] = useState([]);

  // Const for Pagination of data
  const [page, setPage] = React.useState(initialDataState);
  const pageChange = (event) => {
    setPage(event.page);
  };
  // Const for selected items
  const [selectedState, setSelectedState] = useState({});
  const [selectedBookings, setSelectedBookings] = useState([]);

  // Const for Dialogs
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [approveDialog, setApproveDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);

      const allValuesFalseOrNull = Object.values(newSelectedState).every(
        (value) => value === false
      );

      if (allValuesFalseOrNull) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    },
    [selectedState]
  );
  // For the check box
  const selectToggle = (event) => {
    onSelectionChange(event);
  };

  // Handle radio button change
  const handleRadioChange = (e) => {
    setSelectedState({});
    setSelectedOption(e.value); // Update the selectedOption when the radio button changes
    getBookingsHandleRadio(e.value);
    //statusCell(e.value);
  };

  function showParticipants(internalParticipant, externalParticipant) {
    if (internalParticipant === "-" && externalParticipant === "-") {
      return "";
    } else if (internalParticipant !== null && externalParticipant === "-") {
      return internalParticipant;
    } else if (externalParticipant !== null && internalParticipant === "-") {
      return externalParticipant;
    } else {
      return internalParticipant + "," + externalParticipant;
    }
  }

  const getBookingsHandleRadio = async (value) => {
    try {
      setIsLoading(true);
      setSelectedState({});
      await fetchClient()
        .get(`api/approval/history/${localStorage.getItem("office")}/${value}`)
        .then((res) => {
          const bookingsWithCombinedDates = res.data.data.map((item) => {
            const dateRange = `${item.startDate} - ${item.endDate}`;
            const timeRange = `${item.startHour} - ${item.endHour}`;
            // Concat participants value
            let ip = item.internalParticipant;
            let ep = item.externalParticipant;
            let participants = showParticipants(ip, ep);

            return {
              ...item, // Keep the existing properties
              dateRange,
              timeRange,
              participants,
            };
          });
          setBookings(bookingsWithCombinedDates);
          setFilteredData(bookingsWithCombinedDates);
          setDisableButton(true);

          setFilter(initialFilter);
          setPage(initialDataState);
          pageChange({ page: initialDataState });
        });
      setIsLoading(false);
    } catch (error) { }
  };

  const getBookings = async () => {
    try {
      setSelectedState({});
      setIsLoading(true);
      setDisableButton(true);

      await fetchClient()
        .get(`api/approval/history/${localStorage.getItem("office")}/${selectedOption}`)
        .then((res) => {
          const bookingsWithCombinedDates = res.data.data.map((item) => {
            const dateRange = `${item.startDate} - ${item.endDate}`;
            const timeRange = `${item.startHour} - ${item.endHour}`;
            // Concat participants value
            let ip = item.internalParticipant;
            let ep = item.externalParticipant;
            let participants = showParticipants(ip, ep);

            return {
              ...item, // Keep the existing properties
              dateRange,
              timeRange,
              participants,
            };
          });
          setBookings(bookingsWithCombinedDates);
          setFilteredData(bookingsWithCombinedDates);
          setDisableButton(true);
          setIsLoading(false);
          setFilter(initialFilter);
        });

    } catch (error) { }
  };

  const handleApproveSave = async () => {
    try {
      setIsLoading(true);
      setDisableButton(true);
      setApproveDialog(false);
      // Filter selected IDs for conciseness
      const selectedIds = Object.keys(selectedState)
        .filter((key) => selectedState[key])
        .map((key) => parseInt(key, 10));

      // Use Promise.all for parallel requests
      await Promise.all(
        selectedIds.map((id) =>
          fetchClient().put("api/approval/updatestatus", {
            id,
            status: "Approved",
            updatedBy: auth.user.id,
          })
        )
      );
      setDisableButton(false);
      setIsLoading(false);
      getBookings();
    } catch (error) { }
  };

  const handleRejectSave = async () => {
    try {
      setIsLoading(true);
      setDisableButton(true);
      setVisibleDialog(false);
      // Filter selected IDs for conciseness
      const selectedIds = Object.keys(selectedState)
        .filter((key) => selectedState[key])
        .map((key) => parseInt(key, 10));

      // Use Promise.all for parallel requests
      await Promise.all(
        selectedIds.map((id) =>
          fetchClient().put("api/approval/updatestatus", {
            id: id,
            status: "Declined",
            rejectionReason: reason,
            updatedBy: auth.user.id,
          })
        )
      );
      setDisableButton(false);
      setIsLoading(false);
      getBookings();
    } catch (error) { }
  };

  const handleChangeReason = (e) => {
    setReason(e.target.value);
  };

  const statusCell = (props) => {
    let statusText = "";

    switch (props.dataItem.status) {
      case "Active":
        statusText = t("active");
        break;
      case "Cancelled":
        statusText = t("cancelled");
        break;
      case "Approved":
        statusText = t("approved");
        break;
      case "Declined":
        statusText = t("declined");
        break;
      case "For Approval":
        statusText = t("pending");
        break;
      default:
        statusText = t("active");
        break;
    }
    if (
      props.dataItem.status === "Active" ||
      props.dataItem.status === "Approved"
    ) {
      return (
        <td colSpan={props.colSpan} style={props.style}>
          <span className="active-status">{statusText}</span>
        </td>
      );
    } else if (props.dataItem.status === "For Approval") {
      return (
        <td colSpan={props.colSpan} style={props.style}>
          <span className="pending-status">{statusText}</span>
        </td>
      );
    } else {
      return (
        <td colSpan={props.colSpan} style={props.style}>
          <span className="cancel-status">{statusText}</span>
        </td>
      );
    }
  };

  const handleApproveDialog = () => {
    const newSelectedBookings = bookings.filter(
      (booking) =>
        selectedState.hasOwnProperty(booking.id) &&
        selectedState[booking.id] === true
    );
    setSelectedBookings(newSelectedBookings);
    setApproveDialog(!approveDialog);
    //getBookings();
  };

  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
  }, [currentWidth]);

  useEffect(() => {
    getBookings();
    getApprovers();
  }, []);

  useEffect(() => {
    // Get select column header label after components mount and set to "Select"
    const selectLabel = document.querySelector(
      "th.k-header label.k-checkbox-label"
    );

    if (selectLabel && currentWidth > 767) {
      selectLabel.textContent = t("select");
    }
  }, [currentWidth, t]);

  const getApprovers = () => {
    fetchClient()
      .get("api/approval/approvers")
      .then((res) => {
        // console.log(auth.user.id);
        const approvers = res.data.data;
        const adminApprover = approvers.find(
          (value) => value.userId === auth.user.id && value.isApprover === true
        );
        setLoginIsApprover(adminApprover ? true : false);
      });
  };
  return (
    <div className="history">
      <div className="history-title">
        {t("select_status_to_show")}
      </div>
      <div
        className={
          selectedOption === "Approved"
            ? "col-12 col-lg-6 row align-items-end"
            : "col-12 col-lg-6 row align-items-end"
        }
      >
        <div className="radio-btn col-6 col-md-4 align-items-center p-0 px-md-3 mb-4 mb-md-0">
          <RadioButton
            name="group"
            value="Approved"
            checked={selectedOption === "Approved"}
            label={t("approved")}
            onChange={handleRadioChange}
          />
        </div>

        <div className="radio-btn col-6 col-md-4 d-flex align-items-center p-0 ps-md-0 px-md-3 mb-4 mb-md-0">
          <RadioButton
            name="group"
            value="Declined"
            checked={selectedOption === "Declined"}
            label={t("declined")}
            onChange={handleRadioChange}
          />
        </div>
      </div>

      <div className="tbl">
        <div className="history-rep">
          {window.screen.width < 992 || currentWidth < 992 ? (
            //<MobileViewTable
            //  currentNum={currentNum}
            //  setCurrentNum={setCurrentNum}
            //  data={userState}
            //  topLabels={topLabels}
            //  botLabels={botLabels}
            ///>
            <div className="history">
              {isLoading && (
                <div className="overlay">
                  <p>{t("loading")} </p>
                  <Loader size="large" type="pulsing" />
                </div>
              )}
              {isLoading ? null : (
                <Grid
                  data={filteredData
                    .map((item) => ({
                      ...item,
                      [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))
                    .slice(page.skip, page.take + page.skip)}
                  skip={page.skip}
                  take={page.take}
                  total={filteredData.length}
                  pageable={true}
                  onPageChange={pageChange}
                  dataItemKey={DATA_ITEM_KEY}
                  selectedField={SELECTED_FIELD}
                  selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                  }}
                  fixedScroll={true}
                  sortable={true}
                  filterable={true} //Change to true to show filter
                  filter={filter}
                  onFilterChange={(e) => {
                    setFilter(e.filter);
                    setPage(initialDataState);
                    setFilteredData(filterBy(bookings, e.filter));
                  }}
                >
                  <Column
                    field={SELECTED_FIELD}
                    // title={t("select")}
                    width="8%"
                    filterable={false}
                  />
                  <Column
                    field="status"
                    title={t("status")}
                    width="15%"
                    cell={statusCell}
                  />
                  <Column
                    field="bookedBy"
                    title={t("booked_by")}
                    width="22%"
                  />
                  <Column
                    field="dateRange"
                    title={t("date")}
                    width="20%"
                    filter="date"
                  />
                  <Column
                    field="timeRange"
                    title={t("time")}
                    width="15%"
                    filter="number"
                  />
                  <Column
                    field="roomName"
                    title={t("room_name")}
                    width="15%"
                  />
                  <Column
                    field="totalParticipants"
                    title={t("headcount")}
                    width="10%"
                  />
                  <Column
                    field="participants"
                    title={t("participants")}
                    width="20%"
                  />
                </Grid>
              )}
            </div>

          ) : (
            <div className="history">
              {isLoading && (
                <div className="overlay">
                  <p>{t("loading")} </p>
                  <Loader size="large" type="pulsing" />
                </div>
              )}
              {isLoading ? null : (
                <Grid
                  data={filteredData
                    .map((item) => ({
                      ...item,
                      [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))
                    .slice(page.skip, page.take + page.skip)}
                  skip={page.skip}
                  take={page.take}
                  total={bookings.length}
                  pageable={true}
                  onPageChange={pageChange}
                  dataItemKey={DATA_ITEM_KEY}
                  selectedField={SELECTED_FIELD}
                  selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                  }}
                  fixedScroll={true}
                  sortable={true}
                  onSelectionChange={selectToggle}
                  filterable={true} //Change to true to show filter
                  filter={filter}
                  onFilterChange={(e) => {
                    setFilter(e.filter);
                    setPage(initialDataState);
                    setFilteredData(filterBy(bookings, e.filter));
                  }}
                >
                  <Column
                    field={SELECTED_FIELD}
                    title={t("select")}
                    width="80px"
                    filterable={false}
                  />
                  <Column
                    field="status"
                    title={t("status")}
                    width="150px"
                    cell={statusCell}
                    filterable={false}
                  />
                  <Column
                    field="bookedBy"
                    title={t("booked_by")}
                    width="260px"
                  />
                  <Column
                    field="dateRange"
                    title={t("date")}
                    width="300px"
                  />
                  <Column
                    field="timeRange"
                    title={t("time")}
                    width="260px"
                  />
                  <Column
                    field="roomName"
                    title={t("room_name")}
                    width="260px"
                  />
                  <Column
                    field="totalParticipants"
                    title={t("headcount")}
                    width="260px"
                  />
                  <Column
                    field="participants"
                    title={t("participants")}
                    width="260px"
                  />
                </Grid>

              )}
            </div>
          )}
        </div>

        {loginIsApprover ? (
          <div className="buttons">
            {selectedOption === "Approved" ? (
              <>
                <button
                  disabled={disableButton}
                  onClick={toggleDialog}
                  className="disapprovebtn"
                >
                  {t("declined")}
                </button>
              </>
            ) : (
              <>
                <button
                  disabled={disableButton}
                  onClick={handleApproveDialog}
                  className="approvedbtn"
                >
                  {t("approved")}
                </button>
              </>
            )}
          </div>
        ) : null}
        {/* Do not show button if userLogin is not Approver */}

      </div>

      {visibleDialog && (
        <RejectDialog
          toggleDialog={toggleDialog}
          handleRejectSave={handleRejectSave}
          handleChangeReason={handleChangeReason}
        />
      )}

      {approveDialog && (
        <ApproveDialog
          toggleDialog={handleApproveDialog}
          handleApproveSave={handleApproveSave}
          value={selectedBookings}
        />
      )}
    </div>
  );
};
export default ApprovalHistory;

import { NavLink, Nav, NavItem } from "reactstrap";
import "../../assets/css/approvals/ApprovalNav.css";
import { useTranslation } from "react-i18next";

const ApprovalNav = ({ content, changeContent }) => {
  const { t } = useTranslation();
  return (
    <Nav className="approval-nav flex-column flex-md-row" justified>
      <NavItem>
        <NavLink
          className="d-flex justify-content-center align-items-center"
          href="#"
          name="approval-booking-list"
          onClick={changeContent}
          active={content === "approval-booking-list"}
        >
          {t("booking_to_approve")}
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          href="#"
          className="d-flex justify-content-center align-items-center"
          name="approval-management"
          onClick={changeContent}
          active={content === "approval-management"}
        >
          {t("approver_management")}
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          href="#"
          className="d-flex justify-content-center align-items-center"
          name="approval-history"
          onClick={changeContent}
          active={content === "approval-history"}
        >
          {t("history")}
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default ApprovalNav;

import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import FormInput from "../../countries and offices/FormInput";
import "../../../../assets/css/admin/countries and offices/AreaInput.css";
import { useTranslation } from "react-i18next";
const AreaInput = ({
  areaData,
  handleAreaChange,
  id,
  error,
  disable,
  seatRangeData,
}) => {
  const { t } = useTranslation();
  const handleChange = (event) => handleAreaChange(id, event);
  let label;
  switch (areaData.areaType) {
    case "individual":
      label = t("Individual_Area_name");
      break;
    case "meeting room":
      label = t("Meeting_room_name");
      break;
    case "meeting pod":
      label = t("Meeting_pod_name");
      break;
    default:
      break;
  }
  return (
    <div className="area-input">
      <FormInput
        disable={disable}
        name="roomName"
        label={label}
        value={areaData.roomName}
        handleChange={handleChange}
        placeholder={t("type_name")}
        error={error?.roomName}
      />
      <FormInput
        disable={disable}
        name="capacity"
        label={t("Capacity")}
        value={areaData.capacity}
        handleChange={handleChange}
        placeholder={t("type_capacity")}
        error={error?.capacity}
      />
      <div className="seat-range row p-0 m-0">
        <div className="d-flex flex-column col-6 ps-0">
          <Label>{t("Seat_range")}</Label>
          <DropDownList
            disabled={disable}
            style={
              error?.range || error?.rangeOverlap
                ? { border: "1px solid #f31700" }
                : { border: "1px solid rgba(66, 66, 66, 0.5)" }
            }
            onChange={handleChange}
            value={areaData.seatMinRange}
            name="seatMinRange"
            data={seatRangeData}
          />
        </div>
        <div className="d-flex flex-column col-6 pe-0">
          <Label>{t("Seat_range")}</Label>
          <DropDownList
            disabled={disable}
            onChange={handleChange}
            value={areaData.seatMaxRange || 99}
            style={
              error?.range || error?.rangeOverlap
                ? { border: "1px solid #f31700" }
                : { border: "1px solid rgba(66, 66, 66, 0.5)" }
            }
            name="seatMaxRange"
            data={seatRangeData}
          />
        </div>
      </div>
    </div>
  );
};

export default AreaInput;

import "../../../assets/css/admin/additional information/AdditionalInformationDialog.css";
import FormInput from "../countries and offices/FormInput";
import { Switch } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import fetchClient from "../../../axios/fetch";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


const AdditionalInformationDialog = ({
  toggleDialog,
  selected,
  setNotif,
  getAdditionalInfo,
}) => {
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const initialData = {
    reminderId: 0,
    link: "",
    isEnable: false,
    officeId: selected.id,
    createdBy: auth.user.id,
  };
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(selected.remark);
  const [isError, setIsError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (selected.remark) {
      getCurrentAdditionalInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubmit && !isError) {
      if (selected.remark) {
        fetchClient()
          .put("api/reminder/updatereminder", data)
          .then((res) => {
            getAdditionalInfo();
            setNotif({
              type: "success",
              msg: "Reminder edited successfully",
            });
          });
      } else {
        fetchClient()
          .post("api/reminder", data)
          .then((res) => {
            getAdditionalInfo();
            setNotif({
              type: "success",
              msg: "Reminder added successfully",
            });
          });
      }
      toggleDialog();
      setTimeout(() => {
        setNotif({
          type: "success",
          msg: "",
        });
      }, 10000);
    } else {
      setIsSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit]);

  const getCurrentAdditionalInfo = () => {
    fetchClient()
      .get(`api/reminder/${selected.id}`)
      .then((res) => {
        setData(res.data.data);
        setIsLoading(false);
      });
  };

  const isValidURL = (string) => {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );
    setIsError(!(res !== null));
  };

  const handleSubmit = () => {
    isValidURL(data.link);
    setIsSubmit(true);
  };

  return (
    <Dialog>
      <div className="reminder-dialog">
        {isLoading && (
          <div className="overlay">
            <p>{t("loading")}</p>
            <Loader size="large" type="pulsing" />
          </div>
        )}
        <div className="content">
          <div className="text">
            <h5>
              {`(${selected.officeName})`} {selected.remark ? "Edit " : "Add "}
              {t("additional_information")}
            </h5>
          </div>
          <div className="link">
            <FormInput
              placeholder={t("link")}
              error={isError}
              handleChange={handleChange}
              value={data.link}
              name="link"
              label={t("additional_information")}
            />
          </div>
          <div className="display">
            <p>{t("enable")}</p>
            <Switch
              name="isEnable"
              checked={data.isEnable}
              onChange={handleChange}
            />
          </div>
          <div>
            {isError && <p className="error">*Please enter a valid link.</p>}
            <div className="form-buttons">
              <button className="cancel" onClick={toggleDialog}>
                {t("cancel")}
              </button>
              <button className="save" onClick={handleSubmit}>
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AdditionalInformationDialog;

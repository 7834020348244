import logo from "../../assets/img/inchcape-logo.svg";
import "../../assets/css/login/Landing.css";

import { useTranslation } from "react-i18next";
import packageSettings from "../../../package.json";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <div className="landing">
      <div className="content d-flex flex-column justify-content-between">
        <img
          className="logo align-self-center align-self-md-start"
          src={logo}
          alt="inchcape logo"
        />
        <div className="title-btn d-flex flex-column justify-content">
          <div>
            <p className="title fw-bold text-center text-md-start">
              {t("office_title")}
            </p>
            <p className="title fw-bold text-center text-md-start">
              {t("booking_title")}
            </p>
          </div>
          <a
            href={packageSettings.siteSettings.ssoLoginUrl}
            className="btn fw-bold align-self-md-start"
          >
            {t("Log_in")}
          </a>

          <button
            onClick={() => navigate("/pinglogin")}
            className="btn fw-bold align-self-md-start d-none"
          >
            {t("Log_in")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Landing;

/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import "../../../assets/css/admin/user constraint/AdminHome.css";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { useCallback, useEffect, useState } from "react";
import UserConstraintDialog from "./UserConstraintsDialog";
import MobileViewTable from "../../MobileViewTable";
import { Icon } from "@progress/kendo-react-common";
import {
  Checkbox,
  InputClearValue,
  TextBox,
} from "@progress/kendo-react-inputs";
import fetchClient from "../../../axios/fetch";
import { useTranslation } from "react-i18next";
import UserConstraintHistoryDialog from './UserConstraintHistoryDialog';

// Const for the pagination
const initialDataState = {
  skip: 0,
  take: 5,
};
const AdminHome = () => {
  const { t } = useTranslation();
  const DATA_ITEM_KEY = "id";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [userState, setUserState] = useState([]);
  const [historyPreview, setHistoryPreview] = useState([]);
  const [historyModal, setHistoryModal] = useState(false);
  const pageSizes = [5, 8, 10];
  const createState = (skip, take) => {
    let pagerSettings = {
      buttonCount: 3,
      info: false,
      type: "numeric",
      pageSizes: pageSizes,
      previousNext: true,
      text: "Rows per page",
    };
    return {
      items: historyPreview.slice(skip, skip + take),
      total: historyPreview.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  // Const for Search
  const [value, setValue] = React.useState("");

  // Const for Notification
  const [success, setSuccess] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [currentNum, setCurrentNum] = useState(0);
  const [currentNumHis, setCurrentNumHis] = useState(0);
  const [mobileSelected, setMobileSelected] = useState({});
  // Const for set successful notification after setting the data.
  const onToggle = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 10000);
  };

  useEffect(() => {
    getUsers();
    getHistoryPreview();
  }, [value]);

  useEffect(() => {
    setHistoryPage(createState(0, 5));
  }, [historyPreview]);


  useEffect(() => {
    // Get select column header label after components mount and set to "Select"
    const selectLabel = document.querySelector(
      "th.k-header label.k-checkbox-label"
    );

    if (selectLabel && currentWidth > 767) {
      selectLabel.textContent = t("select");
    }
  }, [currentWidth, t]);
  // Const for Pagination of data
  const [page, setPage] = React.useState(initialDataState);
  const pageChange = (event) => {
    setPage(event.page);
  };
  const [historyPage, setHistoryPage] = useState({});
  const historyPageChange = (event) => {
    setHistoryPage(createState(event.page.skip, event.page.take));
  };

  // Const for selected items
  const [selectedState, setSelectedState] = useState({});
  // Const for getting the data.
  const getUsers = () => {
    if (value) {
      fetchClient()
        .get("api/user/search/" + value)
        .then((res) => {
          setUserState(res.data.data);
          setCurrentNum(0);
        });
    } else {
      fetchClient()
        .get("api/user")
        .then((res) => {
          // console.log(res.data);
          setUserState(
            res.data.data.map((dataItem) =>
              Object.assign(
                {
                  selected: false,
                },
                dataItem
              )
            )
          );
        });
      // .catch((err) => console.log(err));
    }
  };

  // User Constraint History API
  const getHistoryPreview = () => {
    fetchClient()
      .get("api/userconstraint/history/preview")
      .then((res) => {
        setHistoryPreview(res.data.data);
      });
  }


  // Const for Dialogs
  const [visibleDialog, setVisibleDialog] = useState(false);
  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  // For the check box
  const selectToggle = (event) => {
    onSelectionChange(event);
  };

  // Const for enter key press to avoid going to login.
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  // const for the checkbox.
  const handleMobileClick = () => {
    if (userState.length > 0) {
      if (!mobileSelected.hasOwnProperty(userState[currentNum]?.id)) {
        setMobileSelected({
          ...mobileSelected,
          [userState[currentNum].id]: true,
        });
      } else {
        setMobileSelected({
          ...mobileSelected,
          [userState[currentNum].id]: !mobileSelected[userState[currentNum].id],
        });
      }
    }
  };
  // For TextBox
  const handleChange = React.useCallback((event) => {
    setValue(event.target.value);
    setPage({ skip: 0, take: 5 });
  }, []);
  const handleClear = React.useCallback(() => {
    setValue("");
  }, []);

  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
  }, [currentWidth]);

  const handleToggleHistoryModal = () => {
    setHistoryModal(!historyModal);
  };


  // mobile data tabel
  const topLabels = [
    {
      label: t("select"),
      data: () => {
        return (
          <Checkbox
            value={mobileSelected[userState[currentNum].id] || false}
            onClick={handleMobileClick}
          />
        );
      },
    },
    {
      label: t("first_Name"),
      data: "fname",
    },
    {
      label: t("last_Name"),
      data: "lname",
    },
    {
      label: t("platform"),
      data: "platform",
    },
    {
      label: t("email_address"),
      data: "email",
    },
  ];

  const botLabels = [
    {
      label: t("first_Name"),
      data: "fname",
    },
    {
      label: t("last_Name"),
      data: "lname",
    },
  ];

  const topLabelsHistory = [
    {
      label: t("email_address"),
      data: "email",
    },
    {
      label: t("platform"),
      data: "platform",
    },
    {
      label: t("date"),
      data: "dateRange",
    },
    {
      label: t("room_seat"),
      data: "roomName",
    },
  ];

  const botLabelsHistory = [
    {
      label: t("email_address"),
      data: "email",
    },
    {
      label: t("room_seat"),
      data: "roomName",
    },
  ];


  return (
    <div className="admin-home">
      <div className="set-inp">
        {/* Input / delete button for search */}
        <form className="inp col-6 col-md-4">
          <TextBox
            placeholder={t("search")}
            value={value}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            suffix={() => (
              <>
                {value && (
                  <InputClearValue onClick={handleClear}>
                    <Icon name="x" />
                  </InputClearValue>
                )}
              </>
            )}
          />
        </form>
        {/* Set button to toggle dialog and set. */}
        <button
          className="Set1 col-md-8 btn"
          onClick={toggleDialog}
          disabled={
            currentWidth < 992
              ? mobileSelected.length === 0
              : !Object.values(selectedState).includes(true)
          }
        // Hidden feature for set button.
        // hidden={!Object.values(selectedState).includes(true)}
        >
          {t("set")}
        </button>
      </div>

      {/* Table for user data with checkbox  */}
      <div className="tbl">
        <div className="history-rep">
          {window.screen.width < 992 || currentWidth < 992 ? (
            <MobileViewTable
              currentNum={currentNum}
              setCurrentNum={setCurrentNum}
              data={userState}
              topLabels={topLabels}
              botLabels={botLabels}
            />
          ) : (
            <Grid
              style={{
                alignContent: "center",
                alignItems: "center",
              }}
              data={userState
                .map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))
                .slice(page.skip, page.take + page.skip)}
              skip={page.skip}
              take={page.take}
              total={userState.length}
              pageable={true}
              onPageChange={pageChange}
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
              }}
              onSelectionChange={selectToggle}
            >
              <Column field={SELECTED_FIELD} title={t("select")} width="5%" />
              <Column field="fname" title={t("first_Name")} width="20%" />
              <Column field="lname" title={t("last_Name")} width="20%" />
              <Column field="platform" title={t("platform")} width="20%" />
              <Column field="email" title={t("email_address")} width="35%" />
            </Grid>
          )}
        </div>
        {/* Dialog components for data */}
        {visibleDialog && (
          <UserConstraintDialog
            toggleDialog={toggleDialog}
            onToggle={onToggle}
            selectedState={selectedState}
            currentWidth={currentWidth}
            mobileSelected={mobileSelected}
            setMobileSelected={setMobileSelected}
            setUserState={setUserState}
            userState={userState}
            setCurrentNum={setCurrentNum}
            getHistoryPreview={getHistoryPreview}
          />
        )}
        <NotificationGroup>
          <Fade>
            {success && (
              <Notification
                type={{
                  style: "success",
                  icon: true,
                }}
                onClose={() => setSuccess(false)}
              >
                <span>{t("user_cons_save")}</span>
              </Notification>
            )}
          </Fade>
        </NotificationGroup>
      </div>
      <div className="userconstraint">
        <div className="userHistory">
          <div>
            <h5 className="userHistory-title">{t("history")}</h5>
            <div></div>
            <div className="uhistory">
              {window.screen.width < 992 || currentWidth < 992 ? (
                <MobileViewTable
                  currentNum={currentNumHis}
                  setCurrentNum={setCurrentNumHis}
                  data={historyPreview}
                  topLabels={topLabelsHistory}
                  botLabels={botLabelsHistory}
                />
                //<div>Mobile view</div>
              ) : (
                <Grid
                  style={{ alignContent: "center", alignItems: "center" }}
                  data={historyPage.items}
                  onPageChange={historyPageChange}
                  total={historyPreview.total}
                  skip={historyPage.skip}
                  take={historyPage.take}
                  pageable={false}
                  pageSize={historyPage.pageSize}
                >
                  <Column field="email" title={t("email_address")} width="30%" />
                  <Column field="platform" title={t("platform")} width="20%" />
                  <Column field="dateRange" title={t("date_range")} width="30%" />
                  <Column field="roomName" title={t("room_name")} width="20%" />
                  <Column field="seatNumber" title={t("seat_number")} width="15%" />
                </Grid>
              )}
            </div>
          </div>
        </div>
      </div>
      {
        historyPage.items && historyPage.items.length > 0 && (
          <button
            className="view-button"
            onClick={handleToggleHistoryModal}
          >
            {t("view_full_history")}
          </button>
        )
      }
      {
        (historyModal &&
          <UserConstraintHistoryDialog
            toggleDialog={handleToggleHistoryModal}
          />)
      }
    </div>
  );
};
export default AdminHome;

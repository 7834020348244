import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/home/HourPicker.css";
import { IoTimeOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { BsInfo } from "react-icons/bs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { addMinutes, format } from "date-fns";

const HourPickerWeb = ({
  defaultValue,
  disabled,
  time,
  handleTime,
  resetTrigger,
}) => {
  const tooltip = useRef(null);
  const [hourPickerOpen, setHourPickerOpen] = useState(false);
  const [hourPickerValue, setHourPickerValue] = useState(
    defaultValue,
    disabled
  );
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  const toggleHourPicker = () => {
    setHourPickerOpen(!hourPickerOpen);
  };

  useEffect(() => {
    // add event listener to detect clicks outside the dropdown menu
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setHourPickerOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    // remove event listener when the component unmounts
    return (handleClickOutside) => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [time, dropdownRef]);

  useEffect(() => {
    setHourPickerValue(t("select"));
  }, [resetTrigger, t]);

  const timePickerChange = (e) => {
    try {
    let options = e.target.options;
      let value = [];

      let firstIndex = -1; // Initialize with an invalid index
      let lastIndex = -1; // Initialize with an invalid index

      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          if (firstIndex === -1) {
            // If it's the first occurrence, update firstIndex
            firstIndex = i;
            value.push(options[i].value);
          }
          lastIndex = i;
        }
      }

      if (lastIndex !== -1) {
        const lastValue = options[lastIndex].value; // Get the value of the last selected option

        const dateSample = new Date(2024, 0, 8);
        const newEndTime = format(
          addMinutes(
            new Date(
              dateSample.getFullYear(),
              dateSample.getMonth(),
              dateSample.getDate(),
              lastValue.slice(0, 2),
              lastValue.slice(3, 5)
            ),
            30
          ),
          "kk:mm aaaa"
        );
        value.push(newEndTime);
      }


    //label.length > 1 &&
    value.length > 1 &&
        setHourPickerValue(`${value[0]} - ${value[value.length - 1]}`);

      for (let i = 0; i < value.length; i++) {
        value[i] = convertTime(value[i]);
      }

      console.log(value[0], value[1]);
      // value.length > 1 && handleTime(`${timeObjects[0]}`, `${timeObjects[1]}`);
      value.length > 1 && handleTime(value[0], value[1]);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const convertTime = (time) => {
    let trimmedTime = time.slice(0, -5) + ":00";
    return trimmedTime;
  };

  return (
    <>
      <div className="dropdown-multiselect">
        <div className="d-flex">
          <p>*{t("hour")}</p>
          <div
            className="radio-tooltip"
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
          >
            <BsInfo className="info-btn" title="Drag to select " />
            <Tooltip
              ref={tooltip}
              anchorElement="target"
              position="top"
              openDelay={100}
            />
          </div>
        </div>
        {disabled === true ? (
          <div className="dropdown-disabled" onClick={toggleHourPicker}>
            <div className="dropdown-input d-flex align-items-center justify-content-between ">
              <span className="dropdown-input-text">{hourPickerValue}</span>
              <div className="dropdown-icon">
                <IoTimeOutline />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="dropdown"
            onClick={toggleHourPicker}
            ref={dropdownRef}
          >
            <div className="dropdown-input d-flex align-items-center justify-content-between ">
              <span className="dropdown-input-text">{hourPickerValue}</span>
              <div className="dropdown-icon">
                <IoTimeOutline />
              </div>
            </div>
            {hourPickerOpen && (
              <select
                id="hour-time"
                className={hourPickerOpen ? "active" : ""}
                multiple
                onChange={timePickerChange}
              >
                <option className="hide"
                  label="12:00 a.m. - 12:30 a.m."
                  value="12:00 a.m."
                  disabled={time[0] !== 0 ? true : false}

                ></option>
                <option className="hide"
                  label="12:30 a.m. - 01:00 a.m."
                  value="12:30 a.m."
                  disabled={time[1] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="01:00 a.m. - 01:30 a.m."
                  value="01:00 a.m."
                  disabled={time[2] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="01:30 a.m. - 02:00 a.m."
                  value="01:30 a.m."
                  disabled={time[3] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="02:00 a.m. - 02:30 a.m."
                  value="02:00 a.m."
                  disabled={time[4] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="02:30 a.m. - 03:00 a.m."
                  value="02:30 a.m."
                  disabled={time[5] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="03:00 a.m. - 03:30 a.m."
                  value="03:00 a.m."
                  disabled={time[6] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="03:30 a.m. - 04:00 a.m."
                  value="03:30 a.m."
                  disabled={time[7] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="04:00 a.m. - 04:30 a.m."
                  value="04:00 a.m."
                  disabled={time[8] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="04:30 a.m. - 05:00 a.m."
                  value="04:30 a.m."
                  disabled={time[9] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="05:00 a.m. - 05:30 a.m."
                  value="05:00 a.m."
                  disabled={time[10] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="05:30 a.m. - 06:00 a.m."
                  value="05:30 a.m."
                  disabled={time[11] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="06:00 a.m. - 06:30 a.m."
                  value="06:00 a.m."
                  disabled={time[12] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="06:30 a.m. - 07:00 a.m."
                  value="06:30 a.m."
                  disabled={time[13] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="07:00 a.m. - 07:30 a.m."
                  value="07:00 a.m."
                  disabled={time[14] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="07:30 a.m. - 08:00 a.m."
                  value="07:30 a.m."
                  disabled={time[15] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="08:00 am - 08:30 am"
                  value="08:00 a.m."
                  disabled={time[16] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  // aria-selected={true}
                  label="08:30 am - 09:00 am"
                  value="08:30 a.m."
                  disabled={time[17] !== 0 ? true : false}
                ></option>
                <option
                  label="09:00 am - 09:30 am"
                  value="09:00 a.m."
                  disabled={time[18] !== 0 ? true : false}
                ></option>
                <option
                  label="09:30 am - 10:00 am"
                  value="09:30 a.m."
                  disabled={time[19] !== 0 ? true : false}
                ></option>
                <option
                  label="10:00 am - 10:30 am"
                  value="10:00 a.m."
                  disabled={time[20] !== 0 ? true : false}
                ></option>
                <option
                  label="10:30 am - 11:00 am"
                  value="10:30 a.m."
                  disabled={time[21] !== 0 ? true : false}
                ></option>
                <option
                  label="11:00 am - 11:30 am"
                  value="11:00 a.m."
                  disabled={time[22] !== 0 ? true : false}
                ></option>
                <option
                  label="11:30 am - 12:00 pm"
                  value="11:30 a.m."
                  disabled={time[23] !== 0 ? true : false}
                ></option>
                <option
                  label="12:00 pm - 12:30 pm"
                  value="12:00 p.m."
                  disabled={time[24] !== 0 ? true : false}
                ></option>
                <option
                  label="12:30 pm - 01:00 pm"
                  value="12:30 p.m."
                  disabled={time[25] !== 0 ? true : false}
                ></option>
                <option
                  label="01:00 pm - 01:30 pm"
                  value="13:00 p.m."
                  disabled={time[26] !== 0 ? true : false}
                ></option>
                <option
                  label="01:30 pm - 02:00 pm"
                  value="13:30 p.m."
                  disabled={time[27] !== 0 ? true : false}
                ></option>
                <option
                  label="02:00 pm - 02:30 pm"
                  value="14:00 p.m."
                  disabled={time[28] !== 0 ? true : false}
                ></option>
                <option
                  label="02:30 pm - 03:00 pm"
                  value="14:30 p.m."
                  disabled={time[29] !== 0 ? true : false}
                ></option>
                <option
                  label="03:00 pm - 03:30 pm"
                  value="15:00 p.m."
                  disabled={time[30] !== 0 ? true : false}
                ></option>
                <option
                  label="03:30 pm - 04:00 pm"
                  value="15:30 p.m."
                  disabled={time[31] !== 0 ? true : false}
                ></option>
                <option
                  label="04:00 pm - 04:30 pm"
                  value="16:00 p.m."
                  disabled={time[32] !== 0 ? true : false}
                ></option>
                <option
                  label="04:30 pm - 05:00 pm"
                  value="16:30 p.m."
                  disabled={time[33] !== 0 ? true : false}
                ></option>
                <option
                  label="05:00 pm - 05:30 pm"
                  value="17:00 p.m."
                  disabled={time[34] !== 0 ? true : false}
                ></option>
                <option
                  label="05:30 pm - 06:00 pm"
                  value="17:30 p.m."
                  disabled={time[35] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="06:00 pm - 06:30 pm"
                  value="18:00 p.m."
                  disabled={time[36] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="06:30 pm - 07:00 pm"
                  value="18:30 p.m."
                  disabled={time[37] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="07:00 pm - 07:30 pm"
                  value="19:00 p.m."
                  disabled={time[38] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="07:30 pm - 08:00 pm"
                  value="19:30 p.m."
                  disabled={time[39] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="08:00 pm - 08:30 pm"
                  value="20:00 p.m."
                  disabled={time[40] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="08:30 pm - 09:00 pm"
                  value="20:30 p.m."
                  disabled={time[41] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="09:00 pm - 09:30 pm"
                  value="21:00 p.m."
                  disabled={time[42] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="09:30 pm - 10:00 pm"
                  value="21:30 p.m."
                  disabled={time[43] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="10:00 p.m. - 10:30 p.m."
                  value="22:00 p.m."
                  disabled={time[44] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="10:30 p.m. - 11:00 p.m."
                  value="22:30 p.m."
                  disabled={time[45] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="11:00 p.m. - 11:30 p.m."
                  value="23:00 p.m."
                  disabled={time[46] !== 0 ? true : false}
                ></option>
                <option className="hide"
                  label="11:30 p.m. - 12:00 a.m."
                  value="23:30 p.m."
                  disabled={time[47] !== 0 ? true : false}
                ></option>
              </select>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HourPickerWeb;

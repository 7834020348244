import { BiHomeAlt } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineShowChart } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { SlWrench } from "react-icons/sl";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SidebarMain = ({ content, setCurrentContents }) => {
  const auth = useSelector((state) => state.auth);
  let Navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const openSettings = () => setCurrentContents("settings");

  return (
    <div className="sidebar-top d-flex flex-column gap-2">
      <Nav.Link
        className="sidebar-links"
        onClick={() => {
          Navigate("/home");
        }}
        active={location.pathname === "/home" || location.pathname === "/"}
      >
        <BiHomeAlt className="icons" size="1.2em" />
        {t("home")}
      </Nav.Link>

      {auth.user.userRole === "admin" ? (
        <>
          <Nav.Link
            className="sidebar-links"
            onClick={() => {
              Navigate("/admin");
            }}
            active={location.pathname === "/admin"}
          >
            <IoSettingsOutline className="icons" size="1.2em" />
            {t("administrator")}
          </Nav.Link>
          <Nav.Link
            className="sidebar-links"
            onClick={() => {
              Navigate("/report");
            }}
            active={location.pathname === "/report"}
          >
            <MdOutlineShowChart className="icons" size="1.2em" />
            {t("reports")}
          </Nav.Link>
          {auth.user.country === "ph" ? (
            <Nav.Link
              className="sidebar-links"
              onClick={() => {
                Navigate("/approvals");
              }}
              active={location.pathname === "/approvals"}
            >
              <BsFileEarmarkCheck className="icons" size="1.2em" />
              {t("meeting_room_approval")}
            </Nav.Link>
          ) : null}
        </>
      ) : null}
      <Nav.Link
        onClick={openSettings}
        className="sidebar-links settings"
        active={content === "settings"}
      >
        <SlWrench className="icons" size="1.2em" />
        {t("settings")}
      </Nav.Link>

    </div>
  );
};

export default SidebarMain;

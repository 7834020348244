/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog } from "@progress/kendo-react-dialogs";
import { Label } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import phFlag from "../../../../assets/img/emojione_flag-for-philippines.png";
import ukFlag from "../../../../assets/img/emojione_flag-for-uk.png";
import deFlag from "../../../../assets/img/emojione_flag-for-germany.png";
import ecFlag from "../../../../assets/img/emojione_flag-for-ecuador.png";
import thFlag from "../../../../assets/img/emojione_flag-for-thailand.png";
import myFlag from "../../../../assets/img/emojione_flag-for-malaysia.png";
import chFlag from "../../../../assets/img/emojione_flag-for-chile.png";
import coFlag from "../../../../assets/img/emojione_flag-for-colombia.png";
import { BsPlusCircle } from "react-icons/bs";
import FormInput from "../FormInput";
import "../../../../assets/css/admin/countries and offices/CountriesDialog.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import fetchClient from "../../../../axios/fetch";
import { useDispatch } from "react-redux";
import { cityChange } from "../../../../reducer/slice/reloadSlice";

const CountriesDialog = ({ mode, toggleDialog, selected, setNotif }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialData = {
    countryId: 0,
    city: [
      {
        id: 0,
        cityName: ""
      }
    ]
  };
  const countryFlags = [
    {
      name: "Philippines",
      src: phFlag
    },
    {
      name: "United Kingdom",
      src: ukFlag
    },
    {
      name: "Germany",
      src: deFlag
    },
    {
      name: "Ecuador",
      src: ecFlag
    },
    {
      name: "Thailand",
      src: thFlag
    },
    {
      name: "Malaysia",
      src: myFlag
    },
    {
      name: "Chile",
      src: chFlag
    },
    {
      name: "Colombia",
      src: coFlag
    }
  ];

  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (mode === "edit") {
      getCountry();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      console.log(data);
      fetchClient()
        .post("api/city", data)
        .then((res) => {
          showNotif(res.status);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          showNotif(err.status);
        });
      toggleDialog();
    } else {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  const getCountry = () => {
    fetchClient()
      .get(`api/city/${selected.id}`)
      .then((res) => {
        setData({ countryId: selected.id, city: res.data.data });
      })
      .then(() => setIsLoading(false))
      .catch((err) => console.log(err));
  };

  const reloadCities = () => {
    dispatch(cityChange());
  };

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setData({ ...data, image: e.target.src });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleAddCity = () => {
    setData({
      ...data,
      city: data.city.concat({
        id: 0,
        cityName: ""
      })
    });
  };

  const handleCityChange = (e, index) => {
    setData({
      ...data,
      city: data.city.map((city, i) =>
        i === index ? { ...city, cityName: e.target.value } : city
      )
    });
  };

  const showNotif = (status) => {
    if (status === 200) {
      setNotif({
        type: "success",
        msg: `${mode === "edit" ? selected.name : "New country "}
        ${mode === "edit" ? "edited" : "created"} successfully.`
      });
      reloadCities();
    } else {
      setNotif({
        type: "error",
        msg: "Oops... something went wrong."
      });
    }
    setTimeout(() => {
      setNotif({
        type: "success",
        msg: ""
      });
    }, 10000);
  };

  const validate = () => {
    const error = {};
    const cities = [];
    data.city.forEach((city, index) => {
      if (!city.cityName) {
        cities.push({
          index,
          cityName: true
        });
      }
    });
    if (cities.length > 0) {
      error.city = cities;
    }

    return error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setErrors(validate());
  };

  return (
    <Dialog onClose={toggleDialog}>
      <div className="co-dialog-countries">
        {mode === "edit" && isLoading && (
          <div className="overlay">
            <p>{t("loading")}</p>
            <Loader size="large" type="pulsing" />
          </div>
        )}
        <div className="contents">
          <h3>{mode === "edit" ? t("edit_country") : t("new_country")}</h3>
          <div className="country-name">
            <FormInput
              handleChange={handleChange}
              name="name"
              disable={true}
              error={errors.name}
              value={selected.name || ""}
              placeholder="Type Name"
              label={
                mode === "edit" ? t("edit_country_name") : t("country_name")
              }
            />
          </div>
          <div className="cities">
            <div>
              {data.city.map((item, index) => {
                return (
                  <FormInput
                    key={index}
                    placeholder="City Name"
                    label={t("city_name")}
                    name="cityName"
                    error={
                      errors.city?.find((city) => city.index === index)
                        ?.cityName
                    }
                    value={item.cityName}
                    handleChange={(e) => handleCityChange(e, index)}
                  />
                );
              })}
            </div>
            <BsPlusCircle onClick={handleAddCity} />
          </div>
          <div className="flag">
            <Label editorId="co-dialog-countries-imgs">
              {mode === "edit" ? t("change_the_flag") : t("choose_a_flag")}
            </Label>
            <div className="imgs">
              {countryFlags.map((flag, idx) => {
                const isSelected = selected.name === flag.name;
                return (
                  <img
                    className={isSelected ? "selected" : "unselected"}
                    // onClick={handleChange}
                    name="image"
                    key={idx}
                    src={flag.src}
                    alt={`${flag.name.toLowerCase()} flag circle`}
                  />
                );
              })}
            </div>
          </div>
          {Object.keys(errors).length > 0 && (
            <p className="error">* {t("missing_filed")}</p>
          )}
          <div className="co-dialog-countries-btns">
            <button onClick={toggleDialog}>{t("cancel")}</button>
            <button onClick={handleSubmit}>{t("save")}</button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CountriesDialog;

/* eslint-disable react-hooks/exhaustive-deps */
import AreaInput from "./AreaInput";
import { BsPlusCircle, BsInfo } from "react-icons/bs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useEffect, useRef, useState } from "react";
import { ScrollView } from "@progress/kendo-react-scrollview";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

const OfficeAreas = ({
  currentAreas,
  areaToEdit,
  setCurrentAreas,
  errors,
  areasMode,
  allAreas,
  setTotalCapacity,
}) => {
  const tooltip = useRef(null);
  const { t } = useTranslation();
  const [seatRangeData, setSeatRangeData] = useState([]);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [capacityChange, setCapacityChange] = useState({
    trigger: false,
    id: null,
  });
  const initialAreas = [
    {
      id: uuid(),
      areaType: "individual",
      capacity: "",
      seatMinRange: 1,
      seatMaxRange: "",
      roomName: "",
    },
    {
      id: uuid(),
      areaType: "meeting room",
      capacity: "",
      seatMinRange: 1,
      seatMaxRange: "",
      roomName: "",
    },
    {
      id: uuid(),
      areaType: "meeting pod",
      capacity: "",
      seatMinRange: 1,
      seatMaxRange: "",
      roomName: "",
    },
  ];

  useEffect(() => {
    if (!areasMode || areasMode === "create") {
      setCurrentAreas(initialAreas);
    } else {
      // change the data of the field where the area type to be edited matches
      setCurrentAreas(
        initialAreas.map((area) =>
          area.areaType === areaToEdit.areaType.toLowerCase()
            ? { ...areaToEdit, areaType: areaToEdit.areaType.toLowerCase() }
            : area
        )
      );
    }
  }, []);

  const handleAreaChange = (id, e) => {
    // sets the value of the area property if the id matches
    if (e.target.name === "capacity") {
      setCapacityChange({
        trigger: !capacityChange.trigger,
        id,
      });
    }
    setCurrentAreas(
      currentAreas.map((area) =>
        area.id === id ? { ...area, [e.target.name]: e.target.value } : area
      )
    );
  };

  useEffect(() => {
    let areas;
    switch (areasMode) {
      case "create":
        areas = allAreas?.concat(currentAreas.filter((area) => area.roomName));
        break;
      case "edit":
        // change the data of edited area in all areas list so capacity will adjust if changed
        areas = allAreas?.map((area) =>
          areaToEdit.id === area.id
            ? currentAreas.find((area) => area.id === areaToEdit.id)
            : area
        );
        break;
      default:
        areas = currentAreas.filter((area) => area.roomName);
    }

    const total = areas.reduce((total, area) => {
      // add capacity if it's a number
      const pattern = /^[1-9]\d*$/;
      if (area?.capacity.toString().match(pattern)) {
        return total + Number(area.capacity);
      }
      return total;
    }, 0);

    setSeatRangeData(
      // populate seat range from 1 to total capacity, if 0, set total to 99
      Array.from(
        { length: total === 0 || total === undefined ? 99 : total },
        (_, i) => i + 1
      )
    );
    if (currentAreas.length > 0) {
      if (areasMode === "create" || !areasMode) {
        setCurrentAreas(
          currentAreas.map((area, idx) => {
            return area.id === capacityChange.id
              ? {
                  ...area,
                  seatMinRange:
                    idx !== 0
                      ? currentAreas[idx - 1].seatMaxRange + 1
                      : areasMode === "create" && areas[areas.indexOf(area) - 1]
                      ? areas[areas.indexOf(area) - 1].seatMaxRange + 1
                      : 1,
                  seatMaxRange: total,
                }
              : area;
          })
        );
      }
    }
    setTotalCapacity(total);
  }, [capacityChange.trigger]);

  // add new area data to the office data
  const handleAddArea = (e) => {
    const newArea = {
      id: uuid(),
      areaType: "",
      capacity: "",
      seatMinRange: 1,
      seatMaxRange: "",
      roomName: "",
    };
    switch (e.currentTarget.name) {
      case "add-individual":
        newArea.areaType = "individual";
        break;
      case "add-meetingRoom":
        newArea.areaType = "meeting room";
        break;
      case "add-meetingPod":
        newArea.areaType = "meeting pod";
        break;
      default:
        break;
    }
    setCurrentAreas(currentAreas.concat(newArea));
  };

  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);

    window.addEventListener("resize", handleWindowResize);
  }, [currentWidth]);

  if (currentWidth > 767) {
    return (
      <>
        <div className="area-div col-4">
          <div className="area-title mb-3">
            <h4>{t("individual_areas")}</h4>
            <button
              onClick={handleAddArea}
              disabled={areasMode === "edit"}
              name="add-individual"
            >
              <BsPlusCircle />
            </button>
          </div>
          <div className="area-inputs">
            {currentAreas
              .filter((area) => area.areaType === "individual")
              .map((area) => (
                <AreaInput
                  handleAreaChange={handleAreaChange}
                  error={errors.areas?.find((error) => error.id === area.id)}
                  disable={
                    areasMode === "edit" &&
                    areaToEdit.areaType.toLowerCase() !== "individual"
                  }
                  key={area.id}
                  areaData={area}
                  id={area.id}
                  seatRangeData={seatRangeData}
                />
              ))}
          </div>
        </div>
        <div className="area-div col-4">
          <div className="area-title mb-3">
            <div>
              <h4>{t("Meeting_room")}</h4>
              <div
                onMouseOver={(event) =>
                  tooltip.current && tooltip.current.handleMouseOver(event)
                }
                onMouseOut={(event) =>
                  tooltip.current && tooltip.current.handleMouseOut(event)
                }
              >
                <span className="change-color">
                  <BsInfo className="info" title={t("maxattendees")} />
                </span>
                <Tooltip
                  ref={tooltip}
                  anchorElement="target"
                  position="top"
                  appendTo={document.querySelector(".k-dialog")}
                  openDelay={100}
                />
              </div>
            </div>
            <button
              onClick={handleAddArea}
              disabled={areasMode === "edit"}
              name="add-meetingRoom"
            >
              {" "}
              <BsPlusCircle />
            </button>
          </div>
          <div className="area-inputs">
            {currentAreas
              .filter((area) => area.areaType === "meeting room")
              .map((area) => (
                <AreaInput
                  handleAreaChange={handleAreaChange}
                  key={area.id}
                  error={errors.areas?.find((error) => error.id === area.id)}
                  disable={
                    areasMode === "edit" &&
                    areaToEdit.areaType.toLowerCase() !== "meeting room"
                  }
                  areaData={area}
                  id={area.id}
                  seatRangeData={seatRangeData}
                />
              ))}
          </div>
        </div>
        <div className="area-div col-4">
          <div className="area-title mb-3">
            <div>
              <h4>{t("Meeting_pods")}</h4>
              <div
                onMouseOver={(event) =>
                  tooltip.current && tooltip.current.handleMouseOver(event)
                }
                onMouseOut={(event) =>
                  tooltip.current && tooltip.current.handleMouseOut(event)
                }
              >
                <span className="change-color">
                  <BsInfo className="info" title={t("maxattendees")} />
                </span>
                <Tooltip
                  ref={tooltip}
                  anchorElement="target"
                  position="top"
                  appendTo={document.querySelector(".k-dialog")}
                  openDelay={100}
                />
              </div>
            </div>
            <button
              onClick={handleAddArea}
              disabled={areasMode === "edit"}
              name="add-meetingPod"
            >
              <BsPlusCircle />
            </button>
          </div>
          <div className="area-inputs">
            {currentAreas
              .filter((area) => area.areaType === "meeting pod")
              .map((area) => (
                <AreaInput
                  handleAreaChange={handleAreaChange}
                  key={area.id}
                  error={errors.areas?.find((error) => error.id === area.id)}
                  disable={
                    areasMode === "edit" &&
                    areaToEdit.areaType.toLowerCase() !== "meeting pod"
                  }
                  areaData={area}
                  id={area.id}
                  seatRangeData={seatRangeData}
                />
              ))}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <ScrollView dir={"ltr"} automaticViewChange={false}>
          <div className="area-div">
            <div className="area-title">
              <h4>Individual area(s)</h4>
              <button
                onClick={handleAddArea}
                disabled={areasMode === "edit"}
                name="add-individual"
              >
                <BsPlusCircle />
              </button>
            </div>
            <div className="area-inputs">
              {currentAreas
                .filter((area) => area.areaType === "individual")
                .map((area) => (
                  <AreaInput
                    handleAreaChange={handleAreaChange}
                    error={errors.areas?.find((error) => error.id === area.id)}
                    disable={
                      areasMode === "edit" &&
                      areaToEdit.areaType.toLowerCase() !== "individual"
                    }
                    key={area.id}
                    areaData={area}
                    id={area.id}
                    seatRangeData={seatRangeData}
                  />
                ))}
            </div>
          </div>
          <div className="area-div">
            <div className="area-title">
              <div>
                <h4>Meeting room(s)</h4>
                <div
                  onMouseOver={(event) =>
                    tooltip.current && tooltip.current.handleMouseOver(event)
                  }
                  onMouseOut={(event) =>
                    tooltip.current && tooltip.current.handleMouseOut(event)
                  }
                >
                  <span className="change-color">
                    <BsInfo className="info" title="2 attendants minimum" />
                  </span>
                  <Tooltip
                    ref={tooltip}
                    anchorElement="target"
                    position="top"
                    appendTo={document.querySelector(".k-dialog")}
                    openDelay={100}
                  />
                </div>
              </div>
              <button
                onClick={handleAddArea}
                disabled={areasMode === "edit"}
                name="add-meetingRoom"
              >
                {" "}
                <BsPlusCircle />
              </button>
            </div>
            <div className="area-inputs">
              {currentAreas
                .filter((area) => area.areaType === "meeting room")
                .map((area) => (
                  <AreaInput
                    handleAreaChange={handleAreaChange}
                    key={area.id}
                    error={errors.areas?.find((error) => error.id === area.id)}
                    disable={
                      areasMode === "edit" &&
                      areaToEdit.areaType.toLowerCase() !== "meeting room"
                    }
                    areaData={area}
                    id={area.id}
                    seatRangeData={seatRangeData}
                  />
                ))}
            </div>
          </div>
          <div className="area-div">
            <div className="area-title">
              <div>
                <h4>Meeting pod(s)</h4>
                <div
                  onMouseOver={(event) =>
                    tooltip.current && tooltip.current.handleMouseOver(event)
                  }
                  onMouseOut={(event) =>
                    tooltip.current && tooltip.current.handleMouseOut(event)
                  }
                >
                  <span className="change-color">
                    <BsInfo className="info" title="2 attendants minimum" />
                  </span>
                  <Tooltip
                    ref={tooltip}
                    anchorElement="target"
                    position="top"
                    appendTo={document.querySelector(".k-dialog")}
                    openDelay={100}
                  />
                </div>
              </div>
              <button
                onClick={handleAddArea}
                disabled={areasMode === "edit"}
                name="add-meetingPod"
              >
                <BsPlusCircle />
              </button>
            </div>
            <div className="area-inputs">
              {currentAreas
                .filter((area) => area.areaType === "meeting pod")
                .map((area) => (
                  <AreaInput
                    handleAreaChange={handleAreaChange}
                    key={area.id}
                    error={errors.areas?.find((error) => error.id === area.id)}
                    disable={
                      areasMode === "edit" &&
                      areaToEdit.areaType.toLowerCase() !== "meeting pod"
                    }
                    areaData={area}
                    id={area.id}
                    seatRangeData={seatRangeData}
                  />
                ))}
            </div>
          </div>
        </ScrollView>
      </>
    );
  }
};

export default OfficeAreas;

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextArea, TextBox } from "@progress/kendo-react-inputs";
import React, { useState, useEffect } from "react";
import "../../assets/css/home/HomePodRoom.css";
import { useRef } from "react";
import { BsInfo } from "react-icons/bs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import fetchClient from "../../axios/fetch";
import {
  format,
  add,
  intervalToDuration,
  areIntervalsOverlapping,
  parseISO,
} from "date-fns";
import { DateRangePicker, DateInput } from "@progress/kendo-react-dateinputs";
import HourPicker from "./HourPicker";

const HomeMeetingpods = ({ selectedValue, setIsBooking, setErrorMessage }) => {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const tooltip = useRef(null);
  const tooltip1 = useRef(null);
  const tooltip2 = useRef(null);
  const unavailableObj = useRef({});
  const auth = useSelector((state) => state.auth);
  const officeTrigger = useSelector((state) => state.reload.office);
  // useStates
  const [roomName, setRoomName] = useState([]);
  const [daylongState, setDaylongState] = useState(false);
  const [capacity, setCapacity] = useState([]);
  // const [currentOfficehrs, setCurrentOfficehrs] = useState({
  //   start: null,
  //   end: null,
  // });
  const [lockOfficeDates, setlockOfficeDates] = useState([]);
  const [capValue, setCapValue] = useState("");
  const [roomValue, setRoomValue] = useState({});
  const [resetTrigger, setResetTrigger] = useState(false);
  const [officeHours, setOfficeHours] = useState([]);
  const [officeDuration, setOfficeDuration] = useState(0);
  const [bookedDates, setBookedDates] = useState([]);
  const initialHour = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ];
  const initiaPayload = {
    Id: 0,
    usersId: auth.user.id,
    startBookingDate: "",
    endBookingDate: "",
    startBookingTime: "",
    endBookingTime: "",
    seatNumber: [0],
    internalParticipant: "",
    externalParticipant: "",
    reason: "",
    areaType: 2,
    areaId: 0,
    capacity: 0,
    roomName: "",
    status: "Active",
  };
  const [hourHolder, setHourHolder] = useState({
    start: null,
    end: null,
  });
  const [dateValue, setDateValue] = useState({
    start: null,
    end: null,
  });
  const [errors, setErrors] = useState({});
  const [hour, setHour] = useState(initialHour);
  const [payload, setPayload] = useState(initiaPayload);
  // For bulleted form of message in tooltip.
  const TooltipContentTemplate = (props) => {
    return (
      <ul className="attendeeTooltip">
        <li>{props.title1}</li>
        <li>{props.title2}</li>
      </ul>
    );
  };

  const min = new Date();
  const max = new Date(2090, 12, 31);

  useEffect(() => {
    setCapValue(t("select"));
    getRoomName();
    getOffice();
    const datepickers = document.querySelectorAll(".k-daterangepicker");
    datepickers.forEach((datepicker) =>
      datepicker.addEventListener("keydown", (e) => {
        if (
          e.keyCode === 37 ||
          e.keyCode === 38 ||
          e.keyCode === 39 ||
          e.keyCode === 40 ||
          e.keyCode === 8
        ) {
          e.preventDefault();
          e.stopPropagation();
        }
      })
    );
  }, []);

  useEffect(() => {
    setPayload(initiaPayload);
    getRoomName();
    getOffice();
    setRoomValue({
      roomName: t("select"),
    });
    setCapValue(t("select"));
    setDateValue({
      start: null,
      end: null,
    });
    setResetTrigger(!resetTrigger);
    setErrorMessage("");
  }, [officeTrigger]);

  useEffect(() => {
    getOfficeLockDisableDates();
    getDisabledDates();
    setPayload((prevState) => ({
      ...prevState,
      startBookingDate: null,
      endBookingDate: null,
    }));
    setDateValue({
      start: null,
      end: null,
    });
    setResetTrigger(!resetTrigger);
    setErrorMessage("");
  }, [payload.areaId]);

  useEffect(() => {
    adjustDateTime();
    setResetTrigger(!resetTrigger);
    setPayload((prevState) => ({
      ...prevState,
      startBookingTime: "",
      endBookingTime: "",
    }));
    setErrorMessage("");
  }, [dateValue]);

  useEffect(() => {
    setErrors(validate());
  }, [payload]);

  const handleDaylongChange = () => {
    setDaylongState(!daylongState);
    setErrorMessage("");
    if (!daylongState) {
      setHourHolder({
        start: payload.startBookingTime,
        end: payload.endBookingTime,
      });
      setPayload({
        ...payload,
        startBookingTime: "00:00:00",
        endBookingTime: "00:00:00",
      });
    } else {
      setPayload({
        ...payload,
        startBookingTime: hourHolder.start,
        endBookingTime: hourHolder.end,
      });
    }
  };

  const setTime = (start, end) => {
    setPayload({ ...payload, startBookingTime: start, endBookingTime: end });
  };

  const CustomStartDateInput = (props) => {
    return (
      <label>
        <DateInput {...props} label={`*${t("start_date")}`} />
      </label>
    );
  };

  const CustomEndDateInput = (props) => {
    return (
      <label>
        <DateInput {...props} label={`*${t("end_date")}`} />
      </label>
    );
  };
  const checker = (room) => {
    switch (room) {
      case "meeting pod":
        setPayload({ ...payload, areaType: 2 });
        return 2;
      case "meeting room":
        setPayload({ ...payload, areaType: 3 });
        return 3;
      default:
        return 2;
    }
  };

  const getOffice = () => {
    try {
      fetchClient()
        .get("api/office/" + localStorage.getItem("office"))
        .then((res) => {
          var holdTime = [
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1,
          ];

          const startHourHolder = res.data.data.startOfficeHour.slice(0, 2);
          const startMinHolder = res.data.data.startOfficeHour.slice(3, 5);
          const endHourHolder = res.data.data.endOfficeHour.slice(0, 2);
          const endMinHolder = res.data.data.endOfficeHour.slice(3, 5);
          // const startTime = res.data.data.startOfficeHour.split(":");
          // const endTime = res.data.data.endOfficeHour.split(":");
          for (
            let index =
              (parseInt(startHourHolder) + (startMinHolder === "00" ? 0 : 0.5)) *
              2;
            index <
            parseInt(endHourHolder) * 2 - 1 + (endMinHolder === "00" ? 1 : 2); // Just to display the exact end time range in hour picker(removed the 30 mins excess)
            index++
          ) {
            holdTime[index] = 0;
          }

          const now = new Date();
          const startOfficeHour = res.data.data.startOfficeHour.slice(0, 8); // Extracting time string
          const endOfficeHour = res.data.data.endOfficeHour.slice(0, 8);

          // Parsing and constructing valid date objects
          const startDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            parseInt(startOfficeHour.split(":")[0]), // Hours
            parseInt(startOfficeHour.split(":")[1]), // Minutes
            parseInt(startOfficeHour.split(":")[2]) // Seconds
          );

          const endDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            parseInt(endOfficeHour.split(":")[0]), // Hours
            parseInt(endOfficeHour.split(":")[1]), // Minutes
            parseInt(endOfficeHour.split(":")[2]) // Seconds
          );

          const durationObj = intervalToDuration({
            start: startDate,
            end: endDate,
          });
          //This function is to show available working hours at the daylong
          // setCurrentOfficehrs({
          //   start: format(
          //     new Date(
          //       now.getYear(),
          //       now.getMonth(),
          //       now.getDay(),
          //       startTime[0],
          //       startTime[1],
          //       startTime[2]
          //     ),
          //     "p"
          //   ),
          //   end: format(
          //     new Date(
          //       now.getYear(),
          //       now.getMonth(),
          //       now.getDay(),
          //       endTime[0],
          //       endTime[1],
          //       endTime[2]
          //     ),
          //     "p"
          //   ),
          // });  // This is for Daylong to get the Available working hrs values
          setOfficeDuration(durationObj.hours + durationObj.minutes / 60);
          setOfficeHours(holdTime);
          setHour(holdTime);

        });
    } catch (err) {
      setErrorMessage(err.response.data);
    }
  };

  const getDisabledDates = () => {
    fetchClient()
      .get(`api/booking/0/${payload.areaId}/${localStorage.getItem("office")}`)
      .then((res) => setBookedDates(res.data.data));
    //.then((res) => setDisabledDates(res.data.data));
    // .catch((err) => console.log(err));
  };

  const adjustDateTime = () => {
    setPayload({
      ...payload,
      startBookingDate:
        dateValue.start !== null ? format(dateValue.start, "yyyy-MM-dd") : null,
      endBookingDate:
        dateValue.end !== null ? format(dateValue.end, "yyyy-MM-dd") : null,
    });
    if (dateValue.start !== null && dateValue.end !== null) {
      getTime(
        format(dateValue.start, "yyyy-MM-dd"),
        format(dateValue.end, "yyyy-MM-dd")
      );
    }
  };

  const getRoomName = () => {
    fetchClient()
      .get(
        `/api/booking/${localStorage.getItem("office")}/${checker(
          selectedValue
        )}`
      )
      .then((res) => {
        setRoomName(res.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const bookMeetingPod = () => {
    setIsBooking(true);
    let officeName;
    let newPayload;
    fetchClient()
      .get("api/office/" + localStorage.getItem("office"))
      .then((res) => {
        if (daylongState) {
          newPayload = {
            ...payload,
            startBookingTime: res.data.data.startOfficeHour.slice(0, 8),
            endBookingTime: res.data.data.endOfficeHour.slice(0, 8),
            officeId: localStorage.getItem("office"),
          };
        } else {
          newPayload = {
            ...payload,
            officeId: localStorage.getItem("office"),
          };
        }
        officeName = res.data.data.officeName;

        fetchClient()
          .post("/api/booking", newPayload)
          .then((res) => {
            const now = new Date();
            const [startHour, startMinute, startSecond]= newPayload.startBookingTime.split(":").map(Number);
            const [endHour, endMonth, endSecond] = newPayload.endBookingTime.split(":").map(Number);
            const eventStartTime = new Date(
              now.getYear(),
              now.getMonth(),
              now.getDay(),
              startHour,
              startMinute,
              startSecond
            );
            const eventEndTime = new Date(
              now.getYear(),
              now.getMonth(),
              now.getDay(),
              endHour,
              endMonth,
              endSecond
            );
            // eventEndTime.setMinutes(eventEndTime.getMinutes() + 1);
            Navigate("/bookSuccessful", {
              
              state: {
                payload: payload,
                date:
                  format(parseISO(payload.startBookingDate), "PP") +
                  " - " +
                  format(parseISO(payload.endBookingDate), "PP"),
                time:
                  format(eventStartTime, "p") +
                  " - " +
                  format(eventEndTime, "p"),
                type: newPayload.areaType,
                officeName: officeName,
                room: newPayload.areaId,
                intParticipants: newPayload.internalParticipant,
                extParticipants: newPayload.externalParticipant,
              },
            });

            setIsBooking(false);
            setErrorMessage("");
          })
          .catch((err) => {
            if (err.response.status === 400) {
              setErrorMessage(err.response.data);
            }
            setIsBooking(false);
          });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getTime = (start, end) => {
    let areaId = payload.areaId;
    if (areaId) {
      fetchClient()
        .get(
          `/api/booking/0/${start}/${end}/${localStorage.getItem(
            "office"
          )}/${areaId}`
        )
        .then((result) => {
          let returnHour = result.data.data.hour;
          let newHour = [];
          officeHours.map((element, index) => {
            if (returnHour[index] === 1) {
              newHour.push(1);
            } else {
              newHour.push(element);
            }
          });
          setHour(newHour);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const validate = () => {
    const error = {};
    if (!payload.roomName) {
      error.roomName = true;
    }
    if (payload.areaId === 0) {
      error.areaId = true;
    }
    if (!payload.startBookingDate) {
      error.startBookingDate = true;
    }
    if (!payload.endBookingDate) {
      error.endBookingDate = true;
    }
    if (!daylongState && !payload.startBookingTime) {
      error.startBookingTime = true;
    }
    if (!daylongState && !payload.endBookingTime) {
      error.endBookingTime = true;
    }
    if (payload.capacity === 0) {
      error.capacity = true;
    }
    if (payload.internalParticipant && !payload.externalParticipant) {
      if (checkEmail()) {
        error.email = true;
      }
      if (
        payload.capacity - 1 !==
        payload.internalParticipant.split(";").length
      ) {
        error.number = true;
      }
    } else if (payload.internalParticipant && payload.externalParticipant) {
      if (checkEmail()) {
        error.email = true;
      }
      if (
        payload.internalParticipant.split(";").length +
        payload.externalParticipant.split(";").length !==
        payload.capacity - 1
      ) {
        error.number = true;
      }
      //if (!payload.reason) {
      //  error.reason = true;
      //}
    } else if (!payload.internalParticipant && payload.externalParticipant) {
      if (
        payload.externalParticipant.split(";").length !==
        payload.capacity - 1
      ) {
        error.number = true;
      }
      //if (!payload.reason) {
      //  error.reason = true;
      //}
    } else {
      error.number = true;
    }
    return error;
  };

  const checkEmail = () => {
    const pattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const arr = payload.internalParticipant
      .split(";")
      .map((email) => email.trim());
    for (let i = 0; i < arr.length; i++) {
      if (!arr[i].match(pattern)) {
        return true;
      }
    }
    return false;
  };

  //Get AreRestriction if Lock whole office is True in the selected Office
  const getOfficeLockDisableDates = () => {
    fetchClient()
      .get("api/seatcapacity/restrictions/" + localStorage.getItem("office"))
      .then((res) => {
        const seatData = res.data.data.map((item) => ({
          ...item,
          startDate: `${item.startRestrictionDate}`,
          endDate: `${item.endRestrictionDate}`,
          startTime: `${item.startRestrictionHour}`,
          endTime: `${item.endRestrictionHour}`,
        }));
        const blockedSpecificDate = seatData.map((item) => ({
          endDate: format(new Date(item.endDate), "MM-dd-yyyy"),
          endTime: item.endTime,
          startDate: format(new Date(item.startDate), "MM-dd-yyyy"),
          startTime: item.startTime,
        }));
        //console.log(blockedSpecificDate);
        setlockOfficeDates(blockedSpecificDate);
      })
    //.catch((err) => console.log(err));
  };

  //new checking of disable dates
  const disableDates = () => {
    const dateObj = {};

    // // Blocked Specific dates for Holidays
    // const blockedSpecificDate = [
    //   {
    //     endDate: "10-30-2023",
    //     endTime: "18:00:00",
    //     startDate: "10-30-2023",
    //     startTime: "09:00:00",
    //   },
    //   {
    //     endDate: "11-02-2023",
    //     endTime: "18:00:00",
    //     startDate: "11-01-2023",
    //     startTime: "09:00:00",
    //   },
    //   {
    //     endDate: "11-27-2023",
    //     endTime: "18:00:00",
    //     startDate: "11-27-2023",
    //     startTime: "09:00:00",
    //   },
    // ];
    // // Apply the blocked dates
    // blockedSpecificDate.map((item) => {
    //   bookedDates.unshift(item);
    // });

    lockOfficeDates.map((item) => {
      bookedDates.unshift(item);
    });

    bookedDates.forEach((data) => {
      //get time duration object using date-nfs
      const durationObj = intervalToDuration({
        start: new Date(`${data.startDate}, ${data.startTime}`),
        end: new Date(`${data.endDate}, ${data.endTime}`),
      });
      //calculate time duration
      const duration = durationObj.hours + durationObj.minutes / 60;

      //get number of days inbetween the start & end date
      const numberOfDays = intervalToDuration({
        start: new Date(data.startDate),
        end: new Date(data.endDate),
      }).days;

      //if booked on the same date
      if (numberOfDays === 0) {
        // check if date array is already on the dateObj
        if (dateObj.hasOwnProperty(`${data.startDate}`)) {
          dateObj[`${data.startDate}`].push(duration);
        } else {
          // add if not
          dateObj[`${data.startDate}`] = [duration];
        }
      } else {
        // booked on a date range
        let currentDate = format(new Date(data.startDate), "MM-dd-yyyy");
        let i = 0;
        // loop through all the booked dates within the range
        do {
          // check if date array is already on the dateObj
          if (dateObj.hasOwnProperty(`${currentDate}`)) {
            dateObj[`${currentDate}`].push(duration);
          } else {
            // add if not
            dateObj[`${currentDate}`] = [duration];
          }
          currentDate = format(add(new Date(currentDate), { days: 1 }), "MM-dd-yyyy");
          i++;
        } while (i <= numberOfDays);
      }
    });

    const disabled = [];
    // loop through all the dates in dateObj
    for (const date in dateObj) {
      // check if the total hours booked within that date is equal to the number of office hours
      const diff = dateObj[date].reduce((accumulator, currentValue) => accumulator - currentValue, officeDuration);
      if (diff <= 0) {
        const curDate = format(new Date(date), "EEEE, LLLL d, yyyy");
        const disableDate = document.querySelector(`td[title="${curDate}"]`);
        disabled.push(curDate);
        if (disableDate) {
          disableDate.classList.add("k-disabled");
        }
      }
    }
    unavailableObj.current = disabled;
  };

  return (
    <>
      <div className="dropdown-room-name dropdown col-12 col-md-6 col-lg-3 align-self-end mb-5 p-0 px-md-3">
        <p className="RoomN">*{t("room_name")}</p>
        <DropDownList
          className="room-name"
          data={roomName}
          value={roomValue}
          textField="roomName"
          onChange={(e) => {
            setRoomValue(e.target.value);
            let hold = [];
            for (let index = 2; index < e.target.value.capacity + 1; index++) {
              hold.push(index.toString());
            }
            setCapacity(hold);
            setPayload({
              ...payload,
              areaId: e.target.value.id,
              roomName:
                e.target.value.roomName.charAt(0).toUpperCase() +
                e.target.value.roomName.slice(1),
            });
          }}
        />
      </div>
      <div className="date-range-picker col-12 col-lg-6 align-self-end mb-5 mb-md-4 p-0 px-md-3">
        <DateRangePicker
          disabled={payload.areaId === 0}
          className="book-daterangepicker"
          startDateInput={CustomStartDateInput}
          endDateInput={CustomEndDateInput}
          value={dateValue}
          onFocus={() => {

            const dates = document.querySelectorAll(".k-dateinput .k-input-inner");
            const calendars = document.querySelectorAll(".k-calendar");
            dates.forEach((date) => date.setAttribute("readonly", true));
            calendars.forEach((calendar) =>
              calendar.addEventListener("mousemove", disableDates)
            );
            setTimeout(disableDates, 100);
          }}
          onChange={(e) => {
            // check if the selected date overlaps with the disabled dates
            const noOverlaps = unavailableObj.current.every((date) => {
              if (e.target.value.end !== null) {
                const isOverlapping = areIntervalsOverlapping(
                  {
                    start: e.target.value.start,
                    end: e.target.value.end,
                  },
                  {
                    start: new Date(date),
                    end: new Date(date),
                  }
                );
                // break loop if it overlaps
                return isOverlapping ? false : true;
              }
            });
            setDateValue({
              start: e.target.value.start,
              end:
                e.target.value.end < // check if date is within a month
                  add(e.target.value.start, { months: 1 }) && noOverlaps
                  ? e.target.value.end // if yes, set e.target.value as the end date
                  : null,
            });
          }}
          min={min}
          max={max}
        />
      </div>
      <div className="time-daylong col-12 col-lg-6 row p-0 px-md-3 mb-5 mb-md-4 ">
        <div className="hour-time col-6 align-self-end ps-0 px-md-3 ">
          <HourPicker
            defaultValue={t("select")}
            disabled={daylongState || payload.areaId === 0}
            time={hour}
            resetTrigger={resetTrigger}
            handleTime={setTime}
            mobile={true}
          />
        </div>
        <div className="hour-daylong col-6 align-self-end px-3">
          <div className="d-flex align-items-center gap-2">
            <Checkbox
              className="radio-daylong d-flex align-items-center"
              label={t("daylong")}
              onChange={handleDaylongChange}
            />
            <div
              className="radio-tooltip"
              onMouseOver={(event) =>
                tooltip.current && tooltip.current.handleMouseOver(event)
              }
              onMouseOut={(event) =>
                tooltip.current && tooltip.current.handleMouseOut(event)
              }
            >
              <BsInfo
                className="info-btn"
                title={t("day1")}
              />
              <Tooltip
                className="hour-daylong"
                ref={tooltip}
                // content={(props) => (
                //   <TooltipContentTemplate
                //     title1={t("day1")}
                //     title2={`${currentOfficehrs.start} - ${currentOfficehrs.end}`}
                //   />
                // )}
                anchorElement="target"
                position="top"
                openDelay={100}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="dropdown-attendees dropdown col-12 col-md-6 col-lg-3 align-self-end mb-5 p-0 px-md-3">
        <p>*{t("no_particpants")}</p>
        <DropDownList
          data={capacity}
          value={capValue}
          onChange={(e) => {
            setCapValue(e.target.value);
            setPayload({ ...payload, capacity: e.target.value });
          }}
          disabled={payload.areaId === 0}
        />
      </div>
      <div className="textbox col-12 col-md-6 col-lg-3 align-self-end mb-5 p-0 px-md-3">
        <div className="d-flex">
          <p>*{t("additional_internal_participant")}</p>
          <div
            className="radio-tooltip"
            onMouseOver={(event) =>
              tooltip1.current && tooltip1.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip1.current && tooltip1.current.handleMouseOut(event)
            }
          >
            <BsInfo className="info-btn" title={"title"} />
            <Tooltip
              ref={tooltip1}
              content={(props) => (
                <TooltipContentTemplate title1={t("pod1")} title2={t("pod2")} />
              )}
              anchorElement="target"
              position="top"
              openDelay={100}
            />
          </div>
        </div>
        <TextBox
          className="add-internal"
          placeholder={t("email")}
          onChange={(e) => {
            setPayload({ ...payload, internalParticipant: e.target.value });
          }}
        />
      </div>
      <div className="textbox col-12 col-md-6 col-lg-3 align-self-end mb-5 p-0 px-md-3">
        <div className="d-flex">
          <p>{t("additional_external_participant")}</p>
          <div
            className="radio-tooltip"
            onMouseOver={(event) =>
              tooltip2.current && tooltip2.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip2.current && tooltip2.current.handleMouseOut(event)
            }
          >
            <BsInfo className="info-btn" title={"title"} />
            <Tooltip
              ref={tooltip2}
              content={(props) => (
                <TooltipContentTemplate title1={t("pod1")} title2={t("pod3")} />
              )}
              anchorElement="target"
              position="top"
              openDelay={100}
            />
          </div>
        </div>
        <TextBox
          className="add-external"
          placeholder="Full name"
          onChange={(e) => {
            setPayload({ ...payload, externalParticipant: e.target.value });
          }}
        />
      </div>
      <div className="textbox-reason col-12 col-md-6 col-lg-3 align-self-end mb-5 p-0 px-md-3">
        <p>{t("reason_for_external")}</p>
        <TextArea
          rows={3}
          placeholder="In case of including external participants, write the reason"
          onChange={(e) => {
            setPayload({ ...payload, reason: e.target.value });
          }}
        />
      </div>

      <div className="col-12 col-lg-12 d-flex justify-content-md-end p-0 px-md-3">
        <button
          className="booking-btn"
          onClick={() => {
            bookMeetingPod();
          }}
          disabled={Object.keys(errors).length !== 0}
        >
          <span>Book</span>
        </button>
      </div>
    </>
  );
};

export default HomeMeetingpods;

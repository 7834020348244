/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import "../../../assets/css/admin/seat capacity/SeatCapacity.css";
import { DateInput, DateRangePicker } from "@progress/kendo-react-dateinputs";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Modal, ModalBody } from "react-bootstrap";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import MobileViewTable from "../../MobileViewTable";
import { BsImages } from "react-icons/bs";
import fetchImg from "../../../axios/imgFetch";
import { Loader } from "@progress/kendo-react-indicators";
import { useState, useEffect } from "react";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { BsTrash } from "react-icons/bs";
import SeatCapacityHistoryDialog from "./SeatCapacityHistoryDialog";
import fetchClient from "../../../axios/fetch";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Checkbox, TextBox } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";

const SeatCapacity = () => {
  const auth = useSelector((state) => state.auth);
  const today = new Date();
  const min = new Date();
  const max = new Date(2099, 12, 31);
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const [offices, setOffices] = useState([]);
  const [officedata, setofficedata] = useState({ officeName: t("select") });
  const [areavalues, setAreavalues] = useState([]);
  const [areadata, setareadata] = useState({
    roomName: t("select"),
  });
  const [percent, setpercet] = useState(40);
  const [NaSeat, setNaSeat] = useState(0);
  const [seatState, setSeatState] = useState([]);
  const [seatData, setSeatdata] = useState([]);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [currentNum, setCurrentNum] = useState(0);
  const [state, setState] = useState({});
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [notifSuccess, setNotifSuccess] = useState(false);
  const [multValue, setMultValue] = useState([]);
  const [officeLayout, setOfficeLayout] = useState("");
  const [selectedRestraint, setSelectedRestraint] = useState(null);
  const [dateValue, setDateValue] = useState({
    start: null,
    end: null,
  });
  const [seatCapacityLoading, setSeatCapacityLoading] = useState(false);

  // for the time Picker
  const [timeValue, setTimeValue] = useState({
    start: new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate(),
      8,
      0,
      0
    ),
    end: new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate(),
      17,
      0,
      0
    ),
  });
  const initialData = {
    id: 0,
    areaId: 0,
    startRestrictionDate: "",
    endRestrictionDate: "",
    startRestrictionHour: `${format(timeValue.start, "HH:mm")}:00`,
    endRestrictionHour: `${format(timeValue.end, "HH:mm")}:00`,
    isActive: true,
    unavailableSeats: 0,
    occupancyRule: "40",
    seatNumber: [],
    isofficelock: false,
    reason: "",
    officeId: 0,
    createdBy: auth.user.id,
  };

  const [payload, setPayload] = useState(initialData);
  const [visible, setVisible] = useState(!true);
  const toggleDialog = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    getOfficeLayout();
  }, []);

  useEffect(() => {
    getOffice();
    gethistory();
  }, []);

  useEffect(() => {
    setareadata({
      roomName: t("select"),
    });
    setMultValue([]);
    setNaSeat(0);
    setPayload({
      ...payload,
      unavailableSeats: 0,
      areaId: 0,
    });
  }, [officedata]);

  const gethistory = () => {
    fetchClient()
      .get("api/seatcapacity/history")
      .then((res) => {
        setSeatState(
          res.data.data.map((e) => ({
            ...e,
            date: `${e.startDate}-${e.endDate}`,
            time: `${e.startHour}-${e.endHour}`,
            occupancyRule: e.occupancyRule + "%",
          }))
        );
      });
    // .catch((err) => console.log(err));
  };
  const getOffice = () => {
    fetchClient()
      .get("api/office")
      .then((res) => {
        setOffices(res.data.data);
      });
    // .catch((err) => console.log(err));
  };

  const getallArea = (officeid) => {
    fetchClient()
      .get(`api/office/getallareabyoffice?office=${officeid}`)
      .then((res) => {
        setAreavalues(res.data.data);
      })
      .catch();
  };

  const getOfficeLayout = () => {
    fetchClient()
      .get("api/office/" + localStorage.getItem("office"))
      .then((res) => setOfficeLayout(res.data.data.officeLayout));
    // .catch((err) => console.log(err));
  };
  // For the custome label of date range picker.
  const CustomStartDateInput = (props) => {
    return (
      <label>
        <span className="Startdate">{t("start_date")}</span>
        <DateInput {...props} label={undefined} />
      </label>
    );
  };
  const CustomEndDateInput = (props) => {
    return (
      <label>
        <span className="Enddate">{t("end_date")}</span>
        <DateInput {...props} label={undefined} />
      </label>
    );
  };
  // For the success notification
  const onToggle = () => {
    setTimeout(() => {
      setSuccess(false);
    }, 10000);
  };

  const setModalHide = () => setModalShow(!modalShow);

  // Dropdown for Occupancy Rule
  const occupancyRule = [
    "10%",
    "20%",
    "30%",
    "40%",
    "50%",
    "60%",
    "70%",
    "80%",
    "90%",
    "100%",
  ];

  // Pagination
  const pageSizes = [5, 8, 10];
  const createState = (skip, take) => {
    let pagerSettings = {
      buttonCount: 3,
      info: false,
      type: "numeric",
      pageSizes: pageSizes,
      previousNext: true,
      text: "Rows per page",
    };
    return {
      items: seatState.slice(skip, skip + take),
      total: seatState.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  // For the Pagination
  const pageChange = (event) => {
    setState(createState(event.page.skip, event.page.take));
  };

  useEffect(() => {
    setState(createState(0, 5));
  }, [seatState]);
  // For validation
  useEffect(() => {
    setErrors(validate());
  }, [payload]);
  // for office data dropdown

  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
  }, [currentWidth]);

  const CancelIcon = (props) => (
    <td>
      <BsTrash
        size="25px"
        className="cancel-btn1"
        onClick={() => {
          toggleDialog();
          setSelectedRestraint(props.dataItem.id);
        }}
      />
    </td>
  );

  const CellOccupancyRule = (props) => {
    // console.log(props);
    let cellValue = props.dataItem.occupancyRule;
    return <td>{cellValue === "0%" ? "" : cellValue}</td>;
  };

  // mobile data tabel
  const topLabels = [
    {
      label: t("delete"),
      data: () => (
        <BsTrash
          onClick={() => {
            toggleDialog();
            setSelectedRestraint(seatState[currentNum].id);
          }}
          className="deleteIcon"
        />
      ),
    },
    {
      label: t("date"),
      data: "date",
    },
    {
      label: t("time"),
      data: "time",
    },
    {
      label: t("office"),
      data: "officeName",
    },
    {
      label: t("room_name"),
      data: "roomName",
    },
  ];
  const botLabels = [
    {
      label: t("occupancy_rule"),
      data: "occupancyRule",
    },
    {
      label: t("seats_with_restriction"),
      data: "restrictedSeats",
    },
  ];

  useEffect(() => {
    if (areadata.capacity) {
      occupiedseat(areadata.capacity);
    }
  }, [percent]);
  // function of cancel button we're removing the all inputed values.
  const handleCancel = () => {
    setareadata({
      roomName: t("select"),
    });
    setofficedata({
      officeName: t("select"),
    });
    setMultValue([]);
    setDateValue({
      start: null,
      end: null,
    });
    setTimeValue({
      start: new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        today.getDate(),
        8,
        0,
        0
      ),
      end: new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        today.getDate(),
        17,
        0,
        0
      ),
    });
    setNaSeat(0);
    setPayload(initialData);
  };
  // For the computation of available and not available seats
  const occupiedseat = (totalseat) => {
    fetchClient()
      .get(`api/seatcapacity/${percent}/${totalseat}`)
      .then((res) => {
        setNaSeat(res.data);
        setPayload({ ...payload, unavailableSeats: res.data });
      });
    // .catch((err) => {
    //   console.log(err);
    // });
  };
  // for the set button we're setting all the inputed values.
  const setRestriction = async () => {
    setSeatCapacityLoading(true); // Show loading indicator if sending of Email still in progress at the backend
    await fetchClient()
      .post("api/seatcapacity", payload)
      .then((res) => {
        setareadata({
          roomName: t("select"),
        });
        setofficedata({
          officeName: t("select"),
        });
        setMultValue([]);
        setTimeValue({
          start: new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            today.getDate(),
            8,
            0,
            0
          ),
          end: new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            today.getDate(),
            17,
            0,
            0
          ),
        });
        setDateValue({
          start: null,
          end: null,
        });
        setNaSeat(0);
        setPayload(initialData);
        gethistory();
        setNotifSuccess(true);
        setTimeout(() => {
          setNotifSuccess(false);
        }, 10000);
      });
    // .catch((err) => {
    //   console.log(err);
    // });
    setSeatCapacityLoading(false);
  };
  // For getting the seats by id of office
  const getNaseat = (id) => {
    fetchClient()
      .get("api/seatcapacity/seats/" + id)
      .then((res) => {
        var hold = [];

        res.data.data.map((elemnt) => {
          hold.push({
            id: elemnt.id,
            seatNumber: elemnt.seatNumber.toString(),
          });
        });
        setSeatdata(hold);
        setPayload({ ...payload, areaId: id });
      });
    // .catch((err) => {
    //   console.log(err);
    // });
  };
  //Get Office Hours and Set
  const getOfficeHours = (officeId) => {
    fetchClient()
      .get(`/api/office/${officeId}`)
      .then((res) => {
        setPayload({
          ...payload,
          startRestrictionHour: `${format(
            new Date(`1970-01-01T${res.data.data.startOfficeHour}`),
            "HH:mm"
          )}:00`,
          endRestrictionHour: `${format(
            new Date(`1970-01-01T${res.data.data.endOfficeHour}`),
            "HH:mm"
          )}:00`,
          officeId: officeId,
        });

        // Setting Hours when Office Selected
        let startValue = customDateFormatForOfficeHours(
          res.data.data.startOfficeHour
        );

        let endValue = customDateFormatForOfficeHours(
          res.data.data.endOfficeHour
        );
        setTimeValue({
          ...timeValue,
          start: startValue,
          end: endValue,
        });
      });
  };

  const customDateFormatForOfficeHours = (dateTime) => {
    const originalTime = dateTime;

    // Splitting the time components
    const [hours, minutes, seconds] = originalTime.split(":");

    // Creating a new Date object with the current date and the given time components
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds.substring(0, 2)); // Considering only seconds part without milliseconds

    // Function to pad zeros
    const padZero = (value) => (value < 10 ? `0${value}` : value);

    // Formatting the date
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    const hour = padZero(date.getHours());
    const minute = padZero(date.getMinutes());
    const second = padZero(date.getSeconds());

    const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth} ${year} ${hour}:${minute}:${second} GMT+0800 (Singapore Standard Time)`;

    const cformattedDateString = formattedDate;

    // Check if the formatted date string is in a valid format
    const isValidDateFormat = (dateString) => {
      return !isNaN(Date.parse(dateString));
    };

    // If the date string is in a valid format, create a Date object
    if (isValidDateFormat(cformattedDateString)) {
      const dateObject = new Date(cformattedDateString);
      return dateObject;
    }
  };

  // Validation
  const validate = () => {
    const error = {};
    if (payload.isofficelock === true) {
      if (!payload.startRestrictionDate) {
        error.startRestrictionDate = true;
      }
      if (!payload.endRestrictionDate) {
        error.endRestrictionDate = true;
      }
      if (!payload.reason) {
        error.reason = true;
      }
      payload.areaId = 0;
      payload.occupancyRule = 0;
      payload.unavailableSeats = 0;
      payload.seatNumber = [];

    } else {
      if (payload.areaId === 0) {
        error.areaId = true;
      }
      if (payload.seatNumber[0] === 0 || payload.seatNumber.length === 0) {
        error.seats = true;
      }
      if (!payload.startRestrictionDate) {
        error.startRestrictionDate = true;
      }
      if (!payload.endRestrictionDate) {
        error.endRestrictionDate = true;
      }
      if (payload.seatNumber.length !== NaSeat) {
        error.seats = true;
      }
    }
    return error;
  };

  const handleChangeLockOffice = (event) => {
    setareadata({
      roomName: t("select"),
    });
    setMultValue([]);
    setNaSeat(0);
    //setDateValue({ start: null, end: null });
    setPayload({ ...payload, isofficelock: event.value });
  };

  return (
    <div className="admin-seat">
      <div className="seat-header">
        <h5 className="seatcapacity-title">{t("seat_capacity")}</h5>
        <div className="floor-layout">
          <button className="floor-layout-btn" onClick={setModalHide}>
            <span>{t("view_floor_layout")}</span>
            <BsImages className="image-icon" />
          </button>
          <Modal
            show={modalShow}
            onHide={setModalShow}
            dialogClassName="modal-style"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton></Modal.Header>
            <ModalBody className="align-self-center">
              {officeLayout ? (
                <img
                  className="office-layout"
                  src={fetchImg(officeLayout)}
                  alt="office layout"
                />
              ) : (
                <p>No layout available</p>
              )}
            </ModalBody>
          </Modal>
        </div>
      </div>

      <div className="row">
        {/* Date Picker */}
        <div className="inputDate col-12 col-lg-6 mb-5">
          <DateRangePicker
            startDateInput={CustomStartDateInput}
            endDateInput={CustomEndDateInput}
            value={dateValue}
            min={min}
            max={max}
            onFocus={() => {
              const dates = document.querySelectorAll(
                ".k-dateinput .k-input-inner"
              );

              dates.forEach((date) => date.setAttribute("readonly", true));
            }}
            onChange={(e) => {
              setDateValue({
                start: e.target.value.start,
                end: e.target.value.end,
              });
              if (e.target.value.start && e.target.value.end) {
                setPayload({
                  ...payload,
                  startRestrictionDate: format(
                    new Date(e.target.value.start),
                    "yyyy-MM-dd"
                  ),
                  endRestrictionDate: format(
                    new Date(e.target.value.end),
                    "yyyy-MM-dd"
                  ),
                });
              }
            }}
          />
        </div>
        {/* Time range picker */}

        <div className="StartTimerange col-6 col-lg-3 mb-5 px-4">
          <label className="lblStarttime">{t("start_hour")}</label>
          <TimePicker
            disabled={true}
            value={timeValue.start}
            steps={{
              hour: 0,
              minute: 30,
              second: 60,
            }}
            onChange={(e) => {
              setTimeValue({
                ...timeValue,
                start: e.target.value,
              });
              setPayload({
                ...payload,
                startRestrictionHour: `${format(
                  new Date(e.target.value),
                  "HH:mm"
                )}:00`,
              });
            }}
          />
        </div>
        <div className="EndtTimerange col-6 col-lg-3 mb-5 px-4">
          <label className="lblEndtime">{t("end_hour")}</label>
          <TimePicker
            disabled={true}
            value={timeValue.end}
            steps={{
              hour: 0,
              minute: 30,
              second: 60,
            }}
            onChange={(e) => {
              setTimeValue({
                ...timeValue,
                end: e.target.value,
              });
              setPayload({
                ...payload,
                endRestrictionHour: `${format(
                  new Date(e.target.value),
                  "HH:mm"
                )}:00`,
              });
            }}
          />
        </div>
        {/* For second div */}
        <div className="roomNameImg col-12 col-md-6 col-lg-3 mb-5 px-4">
          <div>
            <label className="officedr">{t("office")}</label>
            <DropDownList
              name="office"
              data={offices}
              textField="officeName"
              onChange={(e) => {
                setofficedata(e.target.value);
                getallArea(e.target.value.id);
                getOfficeHours(e.target.value.id);
                setPayload({
                  ...payload,
                  officeId: e.target.value.id,
                });
              }}
              //  onChange={handleChange}
              value={officedata}
            />
          </div>
        </div>
        <div className="roomSeat col-12 col-md-6 col-lg-3 mb-5 px-4  mb-5 ">
          <div>
            <label className="lblRoomname">{t("room_seat")}</label>
            <DropDownList
              className="roomName"
              textField="roomName"
              data={areavalues}
              onChange={(e) => {
                setareadata(e.target.value);
                occupiedseat(e.target.value.capacity);
                getNaseat(e.target.value.id);
                setMultValue([]);
              }}
              value={areadata}
              disabled={officedata.officeName === t("select") ||
                payload.isofficelock === true
              }
            />
          </div>
        </div>
        <div className="lblOccAvail flex-column flex-lg-row col-12 col-md-6 row mb-5 mx-0 px-0 align-items-lg-end">
          <div className="col-12 col-lg-6 px-4">
            {/* Lable / Dropdown for occupancy rule */}
            <label className="lblOccupancy">{t("occupancy_rule")}</label>
            <DropDownList
              value={payload.occupancyRule + "%"}
              className="occupancyRule"
              data={occupancyRule}
              onChange={(e) => {
                setMultValue([]);
                setpercet(
                  e.target.value === "100%"
                    ? e.target.value.slice(0, 3)
                    : e.target.value.slice(0, 2)
                );
                setPayload({
                  ...payload,
                  occupancyRule:
                    e.target.value === "100%"
                      ? e.target.value.slice(0, 3)
                      : e.target.value.slice(0, 2),
                });
              }}
              disabled={payload.isofficelock === true}
            />
          </div>
          {/* Lable for available seats */}
          <label className="lblAvailableseats col pt-2 px-4">
            {NaSeat} {t("seats_with_restriction")}
          </label>
        </div>
        <div className="col-12 col-md-6 col-lg-3 px-4 mb-5">
          {/* Lable / multiselect for seat no. */}

          <label className="lblSelectseats">
            {t("select_seats_with_restriction")}
          </label>
          <div className="selectSeats">
            <MultiSelect
              data={seatData}
              value={multValue}
              placeholder={t("select")}
              textField="seatNumber"
              onChange={(e) => {
                var hold = [];

                e.target.value.map((elm) => {
                  hold.push(elm.id);
                });

                setMultValue(e.value.slice(0, NaSeat));
                setPayload({
                  ...payload,

                  seatNumber: hold.slice(0, NaSeat),
                });
              }}
              disabled={
                areadata.roomName === t("select") ||
                payload.isofficelock === true
              }
            />
          </div>

          <Checkbox
            className="lockOffice"
            label={t("lock_the_whole_office")}
            checked={payload.isofficelock}
            onChange={handleChangeLockOffice}
          />
        </div>

        <div className="col-12 col-md-6 col-lg-3 px-4 mb-5">
          <label className="lblReason">
            {t("reason_for_cancellation")}
          </label>
          <TextBox
            className="writeTheReson"
            placeholder={t("write_the_reason")}
            value={payload.reason}
            onChange={(e) =>
              setPayload({ ...payload, reason: e.target.value })
            }
          />
        </div>

        {/* Buttons for cancel and set */}
        <div className="buttons col-12 col-lg-5 d-flex justify-content-end gap-2 align-items-end px-4 mb-5">
          <button onClick={handleCancel} className="Cancel">
            {t("cancel")}
          </button>
          <button
            className="Set"
            onClick={() => {
              setRestriction();
            }}
            disabled={Object.keys(errors).length !== 0}
          >
            {t("set")}
          </button>
        </div>
      </div>

      {/* Loading indicator */}
      {seatCapacityLoading && (
        <div className="overlay">
          <p>{t("loading")} </p>
          <Loader size="large" type="pulsing" />
        </div>
      )}

      <div className="SeatCapacityHistory">
        <div>
          <h5 className="seatcapacityhistory-title">{t("history")}</h5>
          <div></div>
          <div className="seathistory">
            {window.screen.width < 992 || currentWidth < 992 ? (
              <MobileViewTable
                currentNum={currentNum}
                setCurrentNum={setCurrentNum}
                data={seatState}
                topLabels={topLabels}
                botLabels={botLabels}
              />
            ) : (
              <Grid
                style={{ alignContent: "center", alignItems: "center" }}
                data={state.items}
                onPageChange={pageChange}
                total={state.total}
                skip={state.skip}
                pageable={state.pageable}
                pageSize={state.pageSize}
                itemsPerPageText="Rows per page"
              >
                <Column
                  field="delete"
                  title={t("delete")}
                  cell={CancelIcon}
                  onToggle={onToggle}
                  width="8%"
                />
                <Column field="date" title={t("date")} width="23%" />
                <Column field="time" title={t("time")} width="17%" />
                <Column field="officeName" title={t("office")} width="15%" />
                <Column field="roomName" title={t("room_name")} width="15%" />
                <Column
                  field="occupancyRule"
                  title={t("occupancy_rule")}
                  width="10%"
                  cell={CellOccupancyRule}
                />
                <Column
                  field="restrictedSeats"
                  title={t("seats_with_restriction")}
                  width="12%"
                />
              </Grid>
            )}
          </div>
          <NotificationGroup>
            <Fade>
              {success && (
                <Notification
                  type={{
                    style: "success",
                    icon: true,
                  }}
                >
                  <span>{t("delete_seat_cap")}</span>
                </Notification>
              )}
            </Fade>
          </NotificationGroup>
          <NotificationGroup>
            <Fade>
              {notifSuccess && (
                <Notification
                  type={{
                    style: "success",
                    icon: true,
                  }}
                >
                  <span>{t("success_seat_cap")}</span>
                </Notification>
              )}
            </Fade>
          </NotificationGroup>
        </div>
      </div>
      {visible && (
        <SeatCapacityHistoryDialog
          onToggle={onToggle}
          toggleDialog={toggleDialog}
          setSuccess={setSuccess}
          gethistory={gethistory}
          id={selectedRestraint}
        />
      )}
    </div>
  );
};
export default SeatCapacity;

import "../../assets/css/login/CountrySquare.css";

const CountrySquare = ({ src, country, handleChange, selected }) => {
  return (
    <div
      onClick={handleChange}
      className={(selected === country ? "selected " : "").concat(
        "country-sq d-flex flex-column justify-content-center align-items-center"
      )}
    >
      <img src={src} alt={`${country} icon`} />
      <p className="text-center fw-bold">{country}</p>
    </div>
  );
};

export default CountrySquare;

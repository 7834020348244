/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import "../../../assets/css/approvals/booking to approve/ListOfBookingToApprove.css";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import { filterBy } from "@progress/kendo-data-query";
//import MobileViewTable from "../../MobileViewTable";
import fetchClient from "../../../axios/fetch";
import RejectDialog from "../RejectDialog";
import ApproveDialog from "../ApproveDialog";


// Const for the pagination
const initialDataState = {
  skip: 0,
  take: 5,
};

const initialFilter = {
  logic: "and",
  filters: [
    {
      field: "bookedBy",
      operator: "contains",
      value: "",
    },
    {
      field: "roomName",
      operator: "contains",
      value: "",
    },
    {
      field: "participants",
      operator: "contains",
      value: "",
    },
    {
      field: "dateRange",
      operator: "contains",
      value: "",
    },
    {
      field: "timeRange",
      operator: "contains",
      value: "",
    },
    {
      field: "totalParticipants",
      operator: "contains",
      value: "",
    },
  ],
};

const ListOfBookingToApprove = () => {
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const DATA_ITEM_KEY = "id";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [bookings, setBookings] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [loginIsApprover, setLoginIsApprover] = useState(false);
  // const [currentNum, setCurrentNum] = useState(0);
  // const [visible, setVisible] = useState(!true);

  // Const for Search
  const [reason, setReason] = useState("");

  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  //const [currentNum, setCurrentNum] = useState(0);

  // Const for Pagination of data
  const [page, setPage] = React.useState(initialDataState);
  const pageChange = (event) => {
    setPage(event.page);
  };
  // Const for selected items
  const [selectedState, setSelectedState] = useState({});

  // Const for search user
  const [filter, setFilter] = useState(initialFilter);
  const [filteredData, setFilteredData] = useState([]);

  // Const for Dialogs
  const [rejectDialog, setRejectDialog] = useState(false);
  const [approveDialog, setApproveDialog] = useState(false);
  const [isBookHistoryLoading, setIsBookHistoryLoading] = useState(true);

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);
      // Check if no selected items
      const allValuesFalseOrNull = Object.values(newSelectedState).every(
        (value) => value === false
      );

      if (allValuesFalseOrNull) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    },
    [selectedState]
  );
  // For the check box
  const selectToggle = (event) => {
    onSelectionChange(event);
  };

  const getBookings = async () => {
    try {
      setSelectedState({});
      setIsBookHistoryLoading(true);

      await fetchClient()
        .get("api/approval/" + localStorage.getItem("office"))
        .then((res) => {
          setIsBookHistoryLoading(true);
          const bookingsWithCombinedDates = res.data.data.map((item) => {
            const dateRange = `${item.startDate} - ${item.endDate}`;
            const timeRange = `${item.startHour} - ${item.endHour}`;

            let intparticipants;
            let extparticipants;
            let participants;

            intparticipants =
              item.internalParticipant === "-" ? "" : item.internalParticipant;

            extparticipants =
              item.externalParticipant === "-"
                ? (extparticipants = "")
                : item.externalParticipant;

            if (intparticipants === "" && extparticipants === "") {
              participants = "";
            } else if (intparticipants !== null && extparticipants === "") {
              participants = intparticipants;
            } else if (extparticipants !== null && intparticipants === "") {
              participants = extparticipants;
            } else {
              participants = intparticipants + "," + extparticipants;
            }

            return {
              ...item, // Keep the existing properties
              dateRange,
              timeRange,
              participants,
            };
          });

          bookingsWithCombinedDates.sort((a, b) => {
            const dateA = new Date(a.startDate);
            const dateB = new Date(b.startDate);
            return dateA - dateB;
          });
          setBookings(bookingsWithCombinedDates);
          setFilteredData(bookingsWithCombinedDates);
          setDisableButton(true);
          setIsBookHistoryLoading(false);
          setFilter(initialFilter);
        });
    } catch (error) { }
  };

  const handleApproveSave = async () => {
    try {
      setIsBookHistoryLoading(true);
      setApproveDialog(false);
      setDisableButton(true);
      const selectedIds = Object.keys(selectedState);

      for (const key of selectedIds) {
        const id = parseInt(key, 10);
        const isSelected = selectedState[key];

        if (isSelected) {
          try {
            await fetchClient().put("api/approval/updatestatus", {
              id: id,
              status: "Approved",
              updatedBy: auth.user.id,
            });
          } catch (error) { }
        }
      }
      setDisableButton(false);
      setIsBookHistoryLoading(false);
      getBookings();
    } catch (error) { }
  };

  const handleApproveDialog = () => {
    const newSelectedBookings = bookings.filter(
      (booking) =>
        selectedState.hasOwnProperty(booking.id) &&
        selectedState[booking.id] === true
    );
    setSelectedBookings(newSelectedBookings);
    setApproveDialog(!approveDialog);
  };

  const handleRejectSave = async () => {
    try {
      setIsBookHistoryLoading(true);
      setRejectDialog(false);
      setDisableButton(true);
      const selectedIds = Object.keys(selectedState);

      for (const key of selectedIds) {
        const id = parseInt(key, 10);
        const isSelected = selectedState[key];

        if (isSelected) {
          try {
            await fetchClient().put("api/approval/updatestatus", {
              id: id,
              status: "Declined",
              updatedBy: auth.user.id,
              rejectionReason: reason,
            });
          } catch (error) { }
        }
      }
      setDisableButton(false);
      setIsBookHistoryLoading(false);
      handleRejectDialog();
      getBookings();
    } catch (error) { }
  };

  const handleRejectDialog = () => {
    setRejectDialog(!rejectDialog);
  };

  const handleChangeReason = (e) => {
    setReason(e.target.value);
  };

  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
  }, [currentWidth]);

  useEffect(() => {
    getBookings();
    getApprovers();
  }, []);

  const getApprovers = () => {
    fetchClient()
      .get("api/approval/approvers")
      .then((res) => {
        // console.log(auth.user.id);
        const approvers = res.data.data;
        const adminApprover = approvers.find(
          (value) => value.userId === auth.user.id && value.isApprover === true
        );
        setLoginIsApprover(adminApprover ? true : false);
      });
  };

  useEffect(() => {
    // Get select column header label after components mount and set to "Select"
    const selectLabel = document.querySelector(
      "th.k-header label.k-checkbox-label"
    );

    if (selectLabel && currentWidth > 767) {
      selectLabel.textContent = t("select");
    }
  }, [currentWidth, t]);

  // // mobile data
  // const topLabels = [
  //   {
  //     label: t("status"),
  //     data: () => {
  //       let statusText = "";

  //       switch (bookings[currentNum].status) {
  //         case "Active":
  //           statusText = t("active");
  //           return <span className="active-status">{statusText}</span>;
  //         case "Approved":
  //           statusText = t("Approved");
  //           return <span className="active-status">{statusText}</span>;
  //         case "For Approval":
  //           statusText = t("For Approval");
  //           return <span className="active-status">{statusText}</span>;
  //         default:
  //           statusText = t("cancelled");
  //           return <span className="cancel-status">{statusText}</span>;
  //       }
  //     },
  //   },
  //   {
  //     label: t("date"),
  //     data: "dateRange",
  //   },
  //   {
  //     label: t("time"),
  //     data: "timeRange",
  //   },
  //   {
  //     label: t("room_name"),
  //     data: "roomName",
  //   },
  // ];
  // const botLabels = [
  //   {
  //     label: t("additional_internal_participant"),
  //     data: "internalParticipant",
  //   },
  //   {
  //     label: t("additional_external_participant"),
  //     data: "externalParticipant",
  //   },
  // ];

  // const toggleDialog = () => {
  //   setVisible(!visible);
  // };

  return (
    <div className="list-of-booking-to-approve">
      <div className="list-of-booking-to-approve-title">
        {t("booking_to_approve")}
      </div>
      <div className="tbl">
        <div className="history-rep">
          {window.screen.width < 992 || currentWidth < 992 ? (
            <>
              {isBookHistoryLoading && (
                <div className="overlay">
                  <p>{t("loading")} </p>
                  <Loader size="large" type="pulsing" />
                </div>
              )}
              {/* <MobileViewTable
                currentNum={currentNum}
                setCurrentNum={setCurrentNum}
                data={bookings}
                topLabels={topLabels}
                botLabels={botLabels}
              /> */}

              {isBookHistoryLoading ? null : (
                <Grid
                  data={filterBy(
                    bookings
                      .map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                      }))
                      .slice(page.skip, page.take + page.skip),
                    filter
                  )}
                  skip={page.skip}
                  take={page.take}
                  total={bookings.length}
                  pageable={true}
                  onPageChange={pageChange}
                  dataItemKey={DATA_ITEM_KEY}
                  selectedField={SELECTED_FIELD}
                  selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                  }}
                  fixedScroll={true}
                  sortable={true}
                  filterable={false} //Change to true to show filter
                  onSelectionChange={selectToggle}
                >
                  <Column
                    field={SELECTED_FIELD}
                    title={t("select")}
                    width="8%"
                    filterable={false}
                  />
                  <Column
                    field="bookedBy"
                    title={t("booked_by")}
                    width="22%"
                  />
                  <Column
                    field="dateRange"
                    title={t("date")}
                    width="20%"
                  />
                  <Column
                    field="timeRange"
                    title={t("time")}
                    width="15%"
                  />
                  <Column
                    field="roomName"
                    title={t("room_name")}
                    width="15%"
                  />
                  <Column
                    field="totalParticipants"
                    title={t("headcount")}
                    width="10%"
                  />
                  <Column
                    field="participants"
                    title={t("participants")}
                    width="20%"
                  />
                </Grid>
              )}

            </>
          ) : (
            <div className="list-of-booking-to-approve">
              {isBookHistoryLoading && (
                <div className="overlay">
                  <p>{t("loading")} </p>
                  <Loader size="large" type="pulsing" />
                </div>
              )}

              {isBookHistoryLoading ? null : (
                <Grid
                  data={filteredData
                    .map((item) => ({
                      ...item,
                      [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))
                    .slice(page.skip, page.take + page.skip)}
                  skip={page.skip}
                  take={page.take}
                  total={filteredData.length}
                  pageable={true}
                  onPageChange={pageChange}
                  dataItemKey={DATA_ITEM_KEY}
                  selectedField={SELECTED_FIELD}
                  selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                  }}
                  fixedScroll={true}
                  sortable={true}
                  onSelectionChange={selectToggle}
                  filterable={true} //Change to true to show filter
                  filter={filter}
                  onFilterChange={(e) => {
                    setFilter(e.filter);
                    setPage(initialDataState);
                    setFilteredData(filterBy(bookings, e.filter));
                  }}
                >
                  <Column
                    field={SELECTED_FIELD}
                    title={t("select")}
                    width="80px"
                    filterable={false}
                  />
                  <Column
                    field="bookedBy"
                    title={t("booked_by")}
                    width="260px"
                  />
                  <Column
                    field="dateRange"
                    title={t("date")}
                    width="300px"
                  />
                  <Column
                    field="timeRange"
                    title={t("time")}
                    width="260px"
                  />
                  <Column
                    field="roomName"
                    title={t("room_name")}
                    width="260px"
                  />
                  <Column
                    field="totalParticipants"
                    title={t("headcount")}
                    width="260px"
                  />
                  <Column
                    field="participants"
                    title={t("participants")}
                    width="260px"
                  />
                </Grid>

              )}
            </div>
          )}
        </div>

        {loginIsApprover ? (
          <div className="buttons">
            <button
              disabled={disableButton}
              onClick={handleApproveDialog}
              className="approve btn"
            >
              {t("approve")}
            </button>
            <button
              disabled={disableButton}
              onClick={handleRejectDialog}
              className="rejectbtn"
            >
              {t("declined")}
            </button>
          </div>
        ) : null}
        {/* Do not show button if userLogin is not Approver */}

      </div>

      {rejectDialog && (
        <RejectDialog
          toggleDialog={handleRejectDialog}
          handleRejectSave={handleRejectSave}
          handleChangeReason={handleChangeReason}
        />
      )}
      {approveDialog && (
        <ApproveDialog
          toggleDialog={handleApproveDialog}
          handleApproveSave={handleApproveSave}
          value={selectedBookings}
        />
      )}
    </div>
  );
};
export default ListOfBookingToApprove;

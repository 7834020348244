import { BsBuildings, BsGlobe, BsInfo } from "react-icons/bs";
import COSquare from "./COSquare";
import "../../../assets/css/admin/countries and offices/CountriesOffices.css";
import { useState, useEffect } from "react";
import CountriesDialog from "../countries and offices/countries/CountriesDialog";
import OfficesDialog from "../countries and offices/offices/OfficesDialog";
import { Notification } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import fetchClient from "../../../axios/fetch";
import { useTranslation } from "react-i18next";

const CountriesOffices = () => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([
    {
      id: 1,
      name:
          localStorage.getItem("country_code") === "ph" ? "Philippines" : "Colombia",
    },
  ]);
  const [offices, setOffices] = useState([]);

  // determines which list of contents to display
  const [selectedOpt, setSelectedOpt] = useState("");

  const [visibleDialog, setVisibleDialog] = useState({
    countries: false,
    offices: false,
  });

  const [selected, setSelected] = useState({
    country: null,
    office: null,
  });

  // sets edit or create when opening modal
  const [mode, setMode] = useState("");

  const [notif, setNotif] = useState({
    type: "success",
    msg: "",
  });

  useEffect(() => {
    getOffices();
  }, []);

  const getOffices = () => {
    fetchClient()
      .get("api/office")
      .then((res) => {
        setOffices(res.data.data);
      });
    // .catch(err); //=> console.log(err));
  };

  const toggleDialog = () => {
    if (selectedOpt === "countries") {
      setVisibleDialog({
        ...visibleDialog,
        countries: !visibleDialog.countries,
      });
    } else {
      setVisibleDialog({ ...visibleDialog, offices: !visibleDialog.offices });
    }
  };

  const changeSelected = (e) => {
    e.currentTarget.innerText === t("offices")
      ? setSelectedOpt("offices")
      : setSelectedOpt("countries");
  };

  const setCreateMode = () => {
    setMode("create");
    toggleDialog();

    // no country/office will be passed to the dialog in create mode
    selectedOpt === "countries"
      ? setSelected({
          ...selected,
          country: null,
        })
      : setSelected({
          ...selected,
          office: null,
        });
  };

  const setEditMode = () => {
    setMode("edit");
    toggleDialog();
  };

  const selectItem = (e) => {
    if (selectedOpt === "countries") {
      // searches the country object that matches the name on the box
      const country = countries.find(
        (country) => country.name === e.currentTarget.parentElement.innerText
      );
      setSelected({ ...selected, country });
    } else {
      // searches the office object that matches the name on the box
      const office = offices.find(
        (office) =>
          office.officeName === e.currentTarget.parentElement.innerText
      );
      // console.log(office);
      setSelected({ ...selected, office });
    }
  };

  return (
    <div className="admin-countries-offices d-flex flex-column flex-lg-row p-0 w-100">
      <Fade>
        {notif.msg && (
          <Notification
            type={{
              style: notif.type,
              icon: true,
            }}
            closable={false}
          >
            <span>{notif.msg}</span>
          </Notification>
        )}
      </Fade>
      <div className="co-list d-flex flex-wrap flex-sm-nowrap flex-lg-column">
        <div
          onClick={changeSelected}
          className={(selectedOpt === "countries" ? "selected " : "").concat(
            "d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-center gap-2"
          )}
        >
          <BsBuildings />
          <p>{t("countries")}</p>
        </div>
        <div
          onClick={changeSelected}
          className={(selectedOpt === "offices" ? "selected " : "").concat(
            "d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-center gap-2"
          )}
        >
          <BsGlobe />
          <p>{t("offices")}</p>
        </div>
      </div>
      <div className="co-contents">
        {!selectedOpt && (
          <div className="info d-flex justify-content-center align-items-center w-100 h-100">
            <div className="d-flex gap-1">
              <BsInfo className="mt-1" />
              <p className="p-0 m-0 text-center">{t("new_office_modal")}</p>
            </div>
          </div>
        )}
        {selectedOpt && (
          <div className="block-contents w-100">
            {selectedOpt &&
              (selectedOpt === "countries" ? countries : offices).map(
                (item) => {
                  return (
                    <COSquare
                      selectItem={selectItem}
                      setEditMode={setEditMode}
                      key={item.id}
                      selectedOpt={selectedOpt}
                      content={selectedOpt === "countries" ? item.name : item}
                    />
                  );
                }
              )}
            {selectedOpt === "offices" && (
              <button onClick={setCreateMode}>
                +&nbsp;
                {selectedOpt === "countries"
                  ? t("new_country")
                  : t("new_office")}
              </button>
            )}
          </div>
        )}
      </div>
      {visibleDialog.countries && (
        <CountriesDialog
          selected={selected.country}
          mode={mode}
          countries={countries}
          setCountries={setCountries}
          setNotif={setNotif}
          toggleDialog={toggleDialog}
        />
      )}
      {visibleDialog.offices && (
        <OfficesDialog
          mode={mode}
          setOffices={setOffices}
          setNotif={setNotif}
          toggleDialog={toggleDialog}
          offices={offices}
          selected={selected.office}
          countries={countries}
          getOffices={getOffices}
        />
      )}
    </div>
  );
};

export default CountriesOffices;

import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/home/HourPicker.css";
import { IoTimeOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { BsInfo } from "react-icons/bs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { addMinutes, format } from "date-fns";

const HourPickerMobile = ({
  defaultValue,
  disabled,
  time,
  handleTime,
  resetTrigger
}) => {
  const tooltip = useRef(null);
  const [hourPickerOpen, setHourPickerOpen] = useState(false);
  const [hourPickerValue, setHourPickerValue] = useState(
    defaultValue,
    disabled
  );
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  const toggleHourPicker = () => {
    setHourPickerOpen(!hourPickerOpen);
  };

  useEffect(() => {
    // add event listener to detect clicks outside the dropdown menu
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setHourPickerOpen(false);
      }
    };
    document.addEventListener("touchstart", handleClickOutside);
    // remove event listener when the component unmounts
    return (handleClickOutside) => {
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [time, dropdownRef]);

  useEffect(() => {
    setHourPickerValue(t("select"));
  }, [resetTrigger, t]);

  const timePickerChange = (e) => {
    let options = e.target.options;
    const now = new Date();
    const date = `${now.getFullYear()}, ${now.getMonth()}, ${now.getDate()}`;
    let value = [];
    for (var i = 0, j = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        // avoid adding time options that is not 30 minutes after the previous selection
        // to prevent the selection of disabled times inbetween
        if (value.length === 0) {
          value.push(options[i].value);
          j++;
        } else {
          if (
            format(
              addMinutes(
                new Date(`${date}
            , ${value[j - 1].slice(0, 5)}:00`),
                30
              ),
              "kk:mm:00"
            ) === `${options[i].value.slice(0, 5)}:00`
          ) {
            value.push(options[i].value);
            j++;
          } else break;
        }
      }
    }

    let label = [];
    for (let i = 0, j = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        // avoid adding time options that is not 30 minutes after the previous selection
        // to prevent the selection of disabled times inbetween
        if (label.length === 0) {
          label.push(options[i].label);
          j++;
        } else {
          if (
            format(
              addMinutes(
                new Date(`${date}
            , ${value[j - 1].slice(0, 5)}:00`),
                30
              ),
              "kk:mm:00"
            ) === `${options[i].value.slice(0, 5)}:00`
          ) {
            label.push(options[i].label);
            j++;
          } else break;
        }
      }
    }

    label.length > 1 &&
      setHourPickerValue(`${label[0]} - ${label[label.length - 1]}`);
    var startTime = value[0].slice(0, 5) + ":00";
    var endTime = value[value.length - 1].slice(0, 5) + ":00";

    value.length > 1 && handleTime(startTime, endTime);
  };

  return (
    <>
      <div className="dropdown-multiselect">
        <div className="d-flex">
          <p>{t("hour")}</p>
          <div
            className="radio-tooltip"
            onTouchStart={(event) =>
              tooltip.current && tooltip.current.handleTouchStart(event)
            }
            onTouchEnd={(event) =>
              tooltip.current && tooltip.current.handleTouchEnd(event)
            }
          >
            <BsInfo className="info-btn" title="Drag to select " />
            <Tooltip
              ref={tooltip}
              anchorElement="target"
              position="top"
              openDelay={100}
            />
          </div>
        </div>
        {disabled === true ? (
          <div className="dropdown-disabled" onTouchStart={toggleHourPicker}>
            <div className="dropdown-input d-flex align-items-center justify-content-between ">
              <span className="dropdown-input-text">{hourPickerValue}</span>
              <div className="dropdown-icon">
                <IoTimeOutline />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="dropdown"
            onTouchStart={toggleHourPicker}
            ref={dropdownRef}
          >
            <div className="dropdown-input d-flex align-items-center justify-content-between ">
              <span className="dropdown-input-text">{hourPickerValue}</span>
              <div className="dropdown-icon">
                <IoTimeOutline />
              </div>
            </div>
            {hourPickerOpen && (
              <select
                id="hour-time"
                className={hourPickerOpen ? "active" : ""}
                multiple
                onChange={timePickerChange}
              >
                <option
                  label="12:00 a.m."
                  value="12:00 a.m."
                  disabled={time[0] !== 0 ? true : false}
                ></option>
                <option
                  label="12:30 a.m."
                  value="12:30 a.m."
                  disabled={time[1] !== 0 ? true : false}
                ></option>
                <option
                  label="01:00 a.m."
                  value="01:00 a.m."
                  disabled={time[2] !== 0 ? true : false}
                ></option>
                <option
                  label="01:30 a.m."
                  value="01:30 a.m."
                  disabled={time[3] !== 0 ? true : false}
                ></option>
                <option
                  label="02:00 a.m."
                  value="02:00 a.m."
                  disabled={time[4] !== 0 ? true : false}
                ></option>
                <option
                  label="02:30 a.m."
                  value="02:30 a.m."
                  disabled={time[5] !== 0 ? true : false}
                ></option>
                <option
                  label="03:00 a.m."
                  value="03:00 a.m."
                  disabled={time[6] !== 0 ? true : false}
                ></option>
                <option
                  label="03:30 a.m."
                  value="03:30 a.m."
                  disabled={time[7] !== 0 ? true : false}
                ></option>
                <option
                  label="04:00 a.m."
                  value="04:00 a.m."
                  disabled={time[8] !== 0 ? true : false}
                ></option>
                <option
                  label="04:30 a.m."
                  value="04:30 a.m."
                  disabled={time[9] !== 0 ? true : false}
                ></option>
                <option
                  label="05:00 a.m."
                  value="05:00 a.m."
                  disabled={time[10] !== 0 ? true : false}
                ></option>
                <option
                  label="05:30 a.m."
                  value="05:30 a.m."
                  disabled={time[11] !== 0 ? true : false}
                ></option>
                <option
                  label="06:00 a.m."
                  value="06:00 a.m."
                  disabled={time[12] !== 0 ? true : false}
                ></option>
                <option
                  label="06:30 a.m."
                  value="06:30 a.m."
                  disabled={time[13] !== 0 ? true : false}
                ></option>
                <option
                  label="07:00 a.m."
                  value="07:00 a.m."
                  disabled={time[14] !== 0 ? true : false}
                ></option>
                <option
                  label="07:30 a.m."
                  value="07:30 a.m."
                  disabled={time[15] !== 0 ? true : false}
                ></option>
                <option
                  label="08:00 a.m."
                  value="08:00 a.m."
                  disabled={time[16] !== 0 ? true : false}
                ></option>
                <option
                  // aria-selected={true}
                  label="08:30 a.m."
                  value="08:30 a.m."
                  disabled={time[17] !== 0 ? true : false}
                ></option>
                <option
                  label="09:00 a.m."
                  value="09:00 a.m."
                  disabled={time[18] !== 0 ? true : false}
                ></option>
                <option
                  label="09:30 a.m."
                  value="09:30 a.m."
                  disabled={time[19] !== 0 ? true : false}
                ></option>
                <option
                  label="10:00 a.m."
                  value="10:00 a.m."
                  disabled={time[20] !== 0 ? true : false}
                ></option>
                <option
                  label="10:30 a.m."
                  value="10:30 a.m."
                  disabled={time[21] !== 0 ? true : false}
                ></option>
                <option
                  label="11:00 a.m."
                  value="11:00 a.m."
                  disabled={time[22] !== 0 ? true : false}
                ></option>
                <option
                  label="11:30 a.m."
                  value="11:30 a.m."
                  disabled={time[23] !== 0 ? true : false}
                ></option>
                <option
                  label="12:00 p.m."
                  value="12:00 p.m."
                  disabled={time[24] !== 0 ? true : false}
                ></option>
                <option
                  label="12:30 p.m."
                  value="12:30 p.m."
                  disabled={time[25] !== 0 ? true : false}
                ></option>
                <option
                  label="01:00 p.m."
                  value="13:00 p.m."
                  disabled={time[26] !== 0 ? true : false}
                ></option>
                <option
                  label="01:30 p.m."
                  value="13:30 p.m."
                  disabled={time[27] !== 0 ? true : false}
                ></option>
                <option
                  label="02:00 p.m."
                  value="14:00 p.m."
                  disabled={time[28] !== 0 ? true : false}
                ></option>
                <option
                  label="02:30 p.m."
                  value="14:30 p.m."
                  disabled={time[29] !== 0 ? true : false}
                ></option>
                <option
                  label="03:00 p.m."
                  value="15:00 p.m."
                  disabled={time[30] !== 0 ? true : false}
                ></option>
                <option
                  label="03:30 p.m."
                  value="15:30 p.m."
                  disabled={time[31] !== 0 ? true : false}
                ></option>
                <option
                  label="04:00 p.m."
                  value="16:00 p.m."
                  disabled={time[32] !== 0 ? true : false}
                ></option>
                <option
                  label="04:30 p.m."
                  value="16:30 p.m."
                  disabled={time[33] !== 0 ? true : false}
                ></option>
                <option
                  label="05:00 p.m."
                  value="17:00 p.m."
                  disabled={time[34] !== 0 ? true : false}
                ></option>
                <option
                  label="05:30 p.m."
                  value="17:30 p.m."
                  disabled={time[35] !== 0 ? true : false}
                ></option>
                <option
                  label="06:00 p.m."
                  value="18:00 p.m."
                  disabled={time[36] !== 0 ? true : false}
                ></option>
                <option
                  label="06:30 p.m."
                  value="18:30 p.m."
                  disabled={time[37] !== 0 ? true : false}
                ></option>
                <option
                  label="07:00 p.m."
                  value="19:00 p.m."
                  disabled={time[38] !== 0 ? true : false}
                ></option>
                <option
                  label="07:30 p.m."
                  value="19:30 p.m."
                  disabled={time[39] !== 0 ? true : false}
                ></option>
                <option
                  label="08:00 p.m."
                  value="20:00 p.m."
                  disabled={time[40] !== 0 ? true : false}
                ></option>
                <option
                  label="08:30 p.m."
                  value="20:30 p.m."
                  disabled={time[41] !== 0 ? true : false}
                ></option>
                <option
                  label="09:00 p.m."
                  value="21:00 p.m."
                  disabled={time[42] !== 0 ? true : false}
                ></option>
                <option
                  label="09:30 p.m."
                  value="21:30 p.m."
                  disabled={time[43] !== 0 ? true : false}
                ></option>
                <option
                  label="10:00 p.m."
                  value="22:00 p.m."
                  disabled={time[44] !== 0 ? true : false}
                ></option>
                <option
                  label="10:30 p.m."
                  value="22:30 p.m."
                  disabled={time[45] !== 0 ? true : false}
                ></option>
                <option
                  label="11:00 p.m."
                  value="23:00 p.m."
                  disabled={time[46] !== 0 ? true : false}
                ></option>
                <option
                  label="11:30 p.m."
                  value="23:30 p.m."
                  disabled={time[47] !== 0 ? true : false}
                ></option>
              </select>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HourPickerMobile;

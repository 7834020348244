import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import reloadSlice from "./slice/reloadSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    reload: reloadSlice
  }
});

/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import "../../assets/css/Report/ReportsContent.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DateInput, DateRangePicker } from "@progress/kendo-react-dateinputs";
import { useState, useEffect } from "react";
import { Loader } from "@progress/kendo-react-indicators";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import fetchClient from "../../axios/fetch";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

const ReportsContent = () => {
  const { t } = useTranslation();
  // Dropdown for office
  const [city, setcity] = useState([]);
  const [office, setoffice] = useState([]);

  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const sampleCountries = [
    {
      name:
      localStorage.getItem("country_code") === "ph" ? "Philippines" : "Colombia",
      code: localStorage.getItem("country_code") === "ph" ? "ph" : "co",
    },
  ];

  // Dropdown for seat no.

  const [checker, setChecker] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [payload, setPayload] = useState({
    officeId: 0,
    startBookingDate: null,
    endBookingDate: null,
  });
  useEffect(() => {
    validate();
  }, [payload]);

  // Const for Notification
  const [successAutoEmail, setSuccessAutoEmail] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [noData, setNoData] = React.useState(false);
  const [isLoading, setisLoading] = React.useState(false);
  const [country, setcountry] = useState("ph");
  // For the place holder of each dropdowns with translation.
  const [countryValue, setCountryValue] = useState({
    value: {
      name: t("select"),
    },
  });

  const [cityValue, setCityValue] = useState({
    value: {
      cityName: t("select"),
    },
  });

  const [officeValue, setOfficeValue] = useState({
    value: {
      officeName: t("select"),
    },
  });
  const [datevalue, setDatevalue] = useState({
    start: null,
    end: null,
  });
  const [bookingdata, setbookingdata] = useState([]);
  // Const for date picker
  const handleKeyPress = (event) => {
    if (event.key >= "0" && event.key <= "9") {
      event.preventDefault();
    }
  };
  // function for the set button
  const onToggle = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 10000);
  };
  // For the clear function we're removing all the inputed values.
  const handleClear = () => {
    setCountryValue({
      value: {
        name: t("select"),
      },
    });
    setCityValue({
      value: {
        cityName: t("select"),
      },
    });
    setOfficeValue({
      value: {
        officeName: t("select"),
      },
    });
    setDatevalue({
      start: null,
      end: null,
    });
    setPayload({
      officeId: 0,
      startBookingDate: null,
      endBookingDate: null,
    });
  };
  useEffect(() => {
    getcity();
  }, [country]);

  const getOffice = (cityId) => {
    fetchClient()
      .get("api/office/getoffice/" + cityId, {
        headers: {
          country_code: country,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setoffice(res.data.data);
      });
    // .catch((err) => console.log(err));
  };

  const getcity = () => {
    fetchClient()
      .get("api/city", {
        headers: {
          country_code: country,
        },
      })
      .then((res) => {
        setcity(res.data.data);
      });
    // .catch((err) => console.log(err));
  };
  const validate = () => {
    // console.log(payload);
    setChecker(false);
    setNoData(false);

    if (
      !(
        payload.officeId === 0 ||
        payload.startBookingDate == null ||
        payload.endBookingDate == null
      )
    ) {
      setisLoading(true);
      fetchClient()
        .get(
          "api/reports/GetDataReport",
          { params: payload },
          {
            headers: {
              country_code: country,
            },
          }
        )
        .then((res) => {
          if (res.data.data.length !== 0) {
            //console.log(res.data.data);
            setbookingdata(res.data.data);
            setChecker(true);
          } else {
            setNoData(true);
          }
          setisLoading(false);
        });
      // .catch((err) => console.log(err));
    }
  };
  // Send Email button
  const autoSendEmail = () => {
    fetchClient()
    .get("api/reports/ExportExcelReport",
    {params: payload},
    {
      headers: {country_code: country,},
    })
    .then((res) => {
      if (res.status === 200)
      {
        //console.log("Sending Email")
        setSuccessAutoEmail(true);
        setTimeout(() => {
          setSuccessAutoEmail(false);
        }, 10000);
        setChecker(true);
      } else {
        setNoData(true);
      }
    });
  };


  // Date range picker label

  const CustomStartDateInput = (props) => {
    return (
      <label>
        <span className="StartDate">*{t("start_date")}</span>
        <DateInput {...props} label={undefined} />
      </label>
    );
  };
  const CustomEndDateInput = (props) => {
    return (
      <label>
        <span className="EndDate">*{t("end_date")}</span>
        <DateInput {...props} label={undefined} />
      </label>
    );
  };
  return (
    <div className="row align-items-end p-0 m-0 justify-content-center">
      <ExcelExport
        data={bookingdata}
        ref={_export}
        filterable={true}
        fileName={`Booking_reports - ${payload.startBookingDate} - ${payload.endBookingDate}`}
      >
        <ExcelExportColumn
          field="country"
          title="Country"
          locked={true}
          width={80}
        />
        <ExcelExportColumn
          field="office"
          title="Office"
          locked={true}
          width={140}
        />
        <ExcelExportColumn
          field="name"
          title="Booked By"
          locked={true}
          width={200}
        />
        <ExcelExportColumn
          field="roomName"
          title="Room Name"
          locked={true}
          width={130}
        />
        <ExcelExportColumn
          field="seatNumber"
          title="Seat Number"
          locked={true}
          width={110}
        />
        <ExcelExportColumn
          field="startBookingDate"
          title="Start Booking Date"
          locked={true}
          width={140}
        />
        <ExcelExportColumn
          field="endBookingDate"
          title="End Booking Date"
          locked={true}
          width={130}
        />
        <ExcelExportColumn
          field="bookingDate"
          title="Booking Date"
          locked={true}
          width={130}
        />
        <ExcelExportColumn
          field="startTime"
          title="Start Time"
          locked={true}
          width={80}
        />
        <ExcelExportColumn
          field="endTime"
          title="End Time"
          locked={true}
          width={80}
        />
        <ExcelExportColumn
          field="totalHours"
          title="Hour(s)"
          locked={true}
          width={65}
        />
        <ExcelExportColumn
          field="totalAttendees"
          title="No.of Attendees"
          locked={true}
          width={120}
        />
        <ExcelExportColumn
          field="participantEmail"
          title="Participants Email"
          locked={true}
          width={200}
        />
        <ExcelExportColumn
          field="participantType"
          title="Participant Type"
          locked={true}
          width={200}
        />
      </ExcelExport>

      <div className="dropdown-country dropdown col-12 col-sm-4 col-lg-2 mb-3">
        <label className="lblcountry">*{t("country")}</label>
        <DropDownList
          data={sampleCountries}
          textField="name"
          dataItemKey="code"
          value={countryValue.value}
          onChange={(e) => {
            // console.log(e.target.value);
            setcountry(e.target.value.code);
            setCountryValue({ value: e.target.value });
          }}
        />
      </div>
      <div className="dropdown-city dropdown col-12 col-sm-4 col-lg-2 mb-3">
        <label className="lblcity">*{t("city")}</label>
        <DropDownList
          data={city}
          textField="cityName"
          name="city"
          value={cityValue.value}
          disabled={countryValue.value.name === t("select")}
          onChange={(e) => {
            // console.log(e.target.value);
            getOffice(e.target.value.id);
            setCityValue({ value: e.target.value });
          }}
        />
      </div>

      <div className="dropdown-office dropdown col-12 col-sm-4 col-lg-2 mb-3">
        <label className="lbloffice">*{t("office")}</label>
        <DropDownList
          data={office}
          name="office"
          textField="officeName"
          value={officeValue.value}
          onChange={(e) => {
            setPayload({
              ...payload,

              officeId: e.target.value.id,
            });
            getOffice(e.target.value.cityId);
            setOfficeValue({ value: e.target.value });
          }}
          disabled={cityValue.value.cityName === t("select")}
        />
      </div>

      <div className="daterange-picker col-md col-lg-4 mb-3">
        <DateRangePicker
          startDateInput={CustomStartDateInput}
          endDateInput={CustomEndDateInput}
          className="dateRange gap-3"
          value={datevalue}
          onKeyPress={handleKeyPress}
          onChange={(e) => {
            setDatevalue({
              start: e.target.value.start,

              end: e.target.value.end,
            });
            if (e.target.value.start != null && e.target.value.end != null) {
              setPayload({
                ...payload,

                startBookingDate: format(
                  new Date(e.target.value.start),
                  "MMM dd, yyyy"
                ),
                endBookingDate: format(
                  new Date(e.target.value.end),
                  "MMM dd, yyyy"
                ),
              });
            }
          }}
        />
      </div>
      <div>
        {isLoading ? (
          <h4 className="loading">
            {"Loading "}

            <Loader size="large" type="pulsing" />
          </h4>
        ) : null}

        {noData ? <h6 className="Nodata">{t("no_report")}</h6> : null}
      </div>
            
      
      <div className="d-flex justify-content-center justify-content-sm-end mt-3 flex-wrap gap-2">
        <div>
            {/* Button to send Excel via email */}
            {!checker ? (
                <button className="reports-btn send-email" disabled>
                    <span>Send Email Report</span>
                </button>
            ) : (
                <button className="reports-btn send-email" onClick={() => {
                    autoSendEmail();
                    //ontoggle();
                }}>
                    <span>Send Email Report</span>
                </button>
            )
            }
        </div>  

        <div>
          <button className="reports-btn clear" onClick={handleClear}>
            <span>{t("clear")}</span>
          </button>
        </div>

        {!checker ? (
          <button className="reports-btn download" disabled>
            <span>{t("download")}</span>
          </button>
        ) : (
          <button
            className="reports-btn download"
            onClick={() => {
              excelExport();
              onToggle();
            }}
          >
            <span>{t("download")}</span>
          </button>
        )}
      </div>
      <NotificationGroup>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              onClose={() => setSuccess(false)}
            >
              <span>Report successfully downloaded.</span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
      <NotificationGroup>
        <Fade>
          {successAutoEmail && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              onClose={() => setSuccessAutoEmail(false)}
            >
              <span>Email sent successfully.</span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </div>
  );
};
export default ReportsContent;

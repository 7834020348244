/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { BiHomeAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import HomeHistory from "././HomeHistory";
import HomeBookModule from "./HomeBookModule";
import "../../assets/css/home/Home.css";

const Home = () => {
  const [reload, setreload] = useState(false);
  const { t } = useTranslation();

  const check = () => {
    setreload(!reload);
  };

  return (
    <div className="homepage">
      {/* Book a Seat/room Content */}
      <div className="home-mobile-header d-md-none d-flex align-items-center">
        <BiHomeAlt />
        <h1>{t("home")}</h1>
      </div>
      <div className="home-module">
        <HomeBookModule />
        <HomeHistory reloader={check} />
      </div>
    </div>
  );
};

export default Home;

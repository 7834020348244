import "../../../assets/css/admin/user constraint/UserConstraintHistoryDialog.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { t } from "i18next";
import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import fetchClient from "../../../axios/fetch";
import { useEffect } from "react";

const initialDataState = {
  skip: 0,
  take: 5,
};

const UserConstraintHistoryDialog = ({
  toggleDialog
}) => {
  const [page, setPage] = useState(initialDataState);
  const [value, setValue] = useState([])
  const pageChange = (event) => {
    setPage(event.page);
  };

  // User Constraint History API
  const getHistory = () => {
    fetchClient()
      .get("api/userconstraint/history")
      .then((res) => {
        setValue(res.data.data);
      });
  }

  useEffect(() => {
    getHistory();
  })

  return (
    <Dialog className="history-dialog" onClose={toggleDialog} width={450}>
      <div className="title" >
          {t("history")}
      </div>
      <div className="history-container ">
        <div className="tbl">
          <Grid
            style={{
              alignContent: "center",
              alignItems: "center",
            }}
            data={value.slice(page.skip, page.take + page.skip)}
            skip={page.skip}
            take={page.take}
            total={value.length}
            pageable={{
              buttonCount: 4,
              pageSizes: [5, 8, 10],
              info: false,
              type: "numeric",
              previousNext: true,
              text: "Rows per page",
            }}
            onPageChange={pageChange}
            itemsPerPageText="Rows per page"
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: "multiple",
            }}
            fixedScroll={true}
            sortable={true}
            filterable={false} //Change to true to show filter
          >
            <GridColumn
              field="email"
              title={t("email_address")}
              width="250px"
            />
            <GridColumn
              field="platform"
              title={t("platform")}
              width="200px"
            />
            <GridColumn
              field="dateRange"
              title={t("date_range")}
              width="250px"
            />
            <GridColumn
              field="roomName"
              title={t("room_name")}
              width="200px"
            />
            <GridColumn
              field="seatNumber"
              title={t("seat_number")}
              width="100px"
            />
          </Grid>
        </div>
      </div>
      <div className="buttons">
        <button
          onClick={toggleDialog}
          className="Yesbtn btn"
        >
          {t("return")}
        </button>
      </div>
    </Dialog>
  );
};

export default UserConstraintHistoryDialog;

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import SidebarMain from "./SidebarMain";
import SidebarSettings from "./SidebarSettings";
import i18n from "i18next";
import fetchClient from "../../axios/fetch";
import { useTranslation } from "react-i18next";
import { countryChange, officeChange } from "../../reducer/slice/reloadSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const SidebarContent = ({ content, setCurrentContents }) => {
  const { t } = useTranslation();
  let Navigate = useNavigate();
  const dispatch = useDispatch();
  const officeTrigger = useSelector((state) => state.reload.office);
  const cityTrigger = useSelector((state) => state.reload.city);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [city, setcity] = useState([]);
  const [office, setoffice] = useState([]);
  const mainSettings = [
    {
      label: t("change_language"),
      func: () => setCurrentContents("settings-lang"),
    },
    {
      label: t("change_country"),
      func: () => setCurrentContents("settings-count"),
    },
    {
      label: t("change_city"),
      func: () => setCurrentContents("settings-city"),
    },
    {
      label: t("change_office"),
      func: () => setCurrentContents("settings-off"),
    },
  ];
  useEffect(() => {
    reload();
  }, []);

  useEffect(() => {
    reload();
  }, [officeTrigger, cityTrigger]);
  useEffect(() => {
    reload();
  }, []);

  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    if (window.screen.width > 767 || currentWidth > 767) {
      setCurrentContents("main");
    }
  }, [currentWidth, setCurrentContents]);

  const reload = () => {
    fetchClient()
      .get("api/city")
      .then((res) => {
        setcity(res.data.data);

        var holddata = res.data.data;

        var dataArr = [];

        holddata.map((element, key) => {
          var city = {
            label: element.cityName,
            id: `${element.id}`,
            func: () => {
              localStorage.setItem("city", element.id);
              reload();
              setCurrentContents("settings");
            },
          };

          dataArr.push(city);
        });

        setcity(dataArr);
      });
    // .catch((err) => console.log(err));
    fetchClient()
      .get("api/office/getoffice/" + localStorage.getItem("city"))
      .then((res) => {
        var holddata = res.data.data;

        var dataArr = [];

        holddata.map((element, key) => {
          var office = {
            label: element.officeName,
            id: `${element.id}`,
            func: () => {
              localStorage.setItem("office", element.id);
              reloadOffice();
              setCurrentContents("settings");
            },
          };

          dataArr.push(office);
        });

        setoffice(dataArr);
      });
    // .catch((err) => console.log(err));
  };

  const reloadOffice = () => {
    dispatch(officeChange());
  };

  const reloadCountry = () => {
    dispatch(countryChange());
  };

  const lang = [
    {
      label: "English",
      code: "en",
      func: () => {
        i18n.changeLanguage("en");
        setCurrentContents("settings");
      },
    },
    {
      label: "Spanish",
      code: "es",
      func: () => {
        i18n.changeLanguage("es");
        setCurrentContents("settings");
      },
    },
  ];
  const refresh = () => window.location.reload(true);

  const countries = [
    {
      label: "Philippines",
      code: "ph",
      func: () => {
        localStorage.setItem("country_code", "ph");
        reloadCountry();
        Navigate("/home");
        reload();
        refresh();
      },
    },
    {
      label: "Colombia",
      code: "co",
      func: () => {
        localStorage.setItem("country_code", "co");
        reloadCountry();
        Navigate("/home");
        reload();
        refresh();
      },
    },
  ];

  const handleBack = () => {
    setCurrentContents(content === "settings" ? "main" : "settings");
  };

  switch (content) {
    case "settings":
      return <SidebarSettings items={mainSettings} handleBack={handleBack} />;
    case "settings-lang":
      return (
        <SidebarSettings
          items={lang}
          handleBack={handleBack}
          setting="language"
        />
      );
    case "settings-count":
      return (
        <SidebarSettings
          items={countries}
          handleBack={handleBack}
          setting="country"
        />
      );
    case "settings-city":
      return (
        <SidebarSettings items={city} handleBack={handleBack} setting="city" />
      );
    case "settings-off":
      return (
        <SidebarSettings
          items={office}
          handleBack={handleBack}
          setting="office"
        />
      );
    default:
      return (
        <SidebarMain
          content={content}
          setCurrentContents={setCurrentContents}
        />
      );
  }
};

export default SidebarContent;

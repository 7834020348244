import React, { useRef } from "react";
import "../../assets/css/home/HomeNavigation.css";
import { RadioButton } from "@progress/kendo-react-inputs";
import { BsInfo } from "react-icons/bs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useTranslation } from "react-i18next";

const HomeNavigation = ({ selectedValue, handleChange }) => {
  const tooltip = useRef(null);
  const { t } = useTranslation();

  // Load tool tip by Country
  let meetingRoomTooltip = t("maxattendees");

  //if (localStorage.getItem("country_code") === "ph") {
  //  meetingRoomTooltip = t("minattendees");
  //}
  //else {
  //  meetingRoomTooltip = t("maxattendees");
  //}

  return (
    <>
      <div className="book-type-title m-0 col-12 col-lg-3 mb-3 mb-md-5 align-self-end p-0 px-md-3">
        <span>{t("choose_a_booking_type")}:</span>
      </div>
      <div
        className={
          selectedValue === "individual"
            ? "col-12 col-lg-9 row align-items-end mb-5"
            : "col-12 col-lg-6 row align-items-end mb-5"
        }
      >
        <div className="radio-btn col-6 col-md-4 align-items-center p-0 px-md-3 mb-4 mb-md-0">
          <RadioButton
            name="group"
            value="individual"
            checked={selectedValue === "individual"}
            label={t("individual")}
            onChange={handleChange}
          />
        </div>
        <div className="radio-btn col-6 col-md-4 d-flex align-items-center p-0 ps-md-0 px-md-3 mb-4 mb-md-0">
          <RadioButton
            name="group"
            value="meeting pod"
            checked={selectedValue === "meeting pod"}
            label={t("meeting_pod")}
            onChange={handleChange}
          />
          <div
            className="radio-tooltip ps-3 ps-md-0"
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
          >
            <BsInfo className="info-btn" title={t("maxattendees")} />
            <Tooltip
              //className="Meetpod"
              ref={tooltip}
              anchorElement="target"
              position="top"
              openDelay={100}
            />
          </div>
        </div>
        <div className="radio-btn col-12 col-md-4 d-flex align-items-center p-0 px-md-3">
          <RadioButton
            name="group"
            value="meeting room"
            checked={selectedValue === "meeting room"}
            //disabled={true} // Disable the Meeting room until release 2
            label={t("meeting_room")}
            onChange={handleChange}
          />
          <div
            className="radio-tooltip ps-3 ps-md-0"
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
          >
            <BsInfo className="info-btn" title={meetingRoomTooltip} />
            <Tooltip
              //className="Meetroom"
              ref={tooltip}
              anchorElement="target"
              position="top"
              openDelay={100}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeNavigation;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
//import fetchClient from "../../axios/fetch";
import { setAuthenticated } from "../../reducer/slice/authSlice";
import FormInput from "../admin/countries and offices/FormInput";
import fetchClient from "../../axios/fetch";

const PingLogin = () => {
  const Navigate = useNavigate();

  // GET DATA
  //const auth = useSelector((state) => state.auth);

  // SET DATA
  const dispatch = useDispatch();

  const [data, setData] = useState({
    username: null,
    password: null,
  });

  // const login = () => {
  //   fetchClient("ph")
  //     .get("/login")
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const tesktemail = () => {
    fetchClient()
      .get("api/test")
      .then((res) => {
        // console.log(res);
        alert(JSON.stringify(res.data));
      });
    // .catch((err) => console.log(err));
  };

  const pushlogin = () => {
      if (data.username === "admin" && data.password === "admin") {
      dispatch(setAuthenticated());
      Navigate("/firstlogin");
    } else {
      Navigate("/invalidlogin");
    }
  };

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });
  // console.log(data);
  return (
    <>
      {" "}
      <div
        style={{
          width: "380px",
          height: "340px",
          border: "1px solid #666666",
          padding: "20px 30px",
          display: "flex",
          flexDirection: "column",
          margin: " 2rem auto",
          gap: "1.5rem",
          alignItems: "center",
        }}
      >
        <h1>Single Sign On</h1>
        <FormInput
          label={"ENTER YOUR INCHCAPE EMAIL ADDRESS:"}
          name="username"
          handleChange={handleChange}
        />
        <FormInput
          label={"ENTER YOUR INCHCAPE COMPUTER PASSWORD:"}
          name="password"
          handleChange={handleChange}
        />
        <button
          onClick={() => {
            pushlogin();

            // login();
          }}
        >
          Sign On
        </button>
      </div>
      <br></br>
      <br></br>
      <button className="btn-lg-success" onClick={() => tesktemail()}>
        TEST EMAIL
      </button>
    </>
  );
};

export default PingLogin;

import { BsFillCaretUpFill, BsCaretDownFill } from "react-icons/bs";
import "../assets/css/MobileViewTable.css";

const MobileViewTable = ({
  currentNum,
  setCurrentNum,
  data,
  topLabels,
  botLabels
}) => {
  // pass data, top & bottom labels & property name

  const handleScrollUp = () => {
    if (currentNum !== 0) {
      setCurrentNum(currentNum - 1);
    }
  };

  const handleScrollDown = () => {
    if (currentNum !== data?.length - 1) {
      setCurrentNum(currentNum + 1);
    }
  };

  return (
    <div className="mobile-table">
      <div className="top">
        <div>
          {topLabels.map((info, i) => (
            <div key={i} className="line">
              <label>{info.label}:</label>
              <p>
                {data?.length > 0
                  ? typeof topLabels[i].data === "function"
                    ? topLabels[i].data()
                    : data[currentNum][info.data] === null
                    ? "null"
                    : data[currentNum][info.data]
                  : ""}
              </p>
            </div>
          ))}
        </div>
        <BsFillCaretUpFill
          className="arrow"
          style={
            currentNum === 0 && {
              width: "21px",
              userSelect: "none",
              cursor: "default",
              color: "#666666"
            }
          }
          onClick={handleScrollUp}
        />
      </div>
      <div className="bot">
        <div>
          {botLabels.map((info, i) => (
            <div key={i} className="line">
              <label>{info.label}:</label>
              <p>{data?.length > 0 ? data[currentNum][info.data] : ""}</p>
            </div>
          ))}
        </div>
        <BsCaretDownFill
          className="arrow"
          style={
            currentNum === data?.length - 1 && {
              userSelect: "none",
              cursor: "default",
              color: "#666666"
            }
          }
          onClick={handleScrollDown}
        />
      </div>
    </div>
  );
};

export default MobileViewTable;

import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useRequest from "./services/useRequest";
import Cookie from "./axios/Cookies";
import { logout, setAuthenticated, setUser } from "./reducer/slice/authSlice";
import Layout from "./components/Layout";
import AuthLanding from "./components/login/AuthLanding";
import { AppRoutes, AuthRoutes } from "./AppRoutes";

function App() {
  const navigateTo = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [tokenClaims, getTokenClaims] = useRequest({ url: "/api/authenticate/validate", isFirstData: true });
  const [emailValidation, validateEmail] = useRequest({ url: "/api/user/emailcheck" });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get("sba")) {
      Cookie.set("sba", searchParams.get("sba"));
    }

    getTokenClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tokenClaims.isSuccess) {
      validateEmail({ email: tokenClaims.data.email });
    }

    if (tokenClaims.isError) {
      dispatch(logout());
      navigateTo("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenClaims.isSuccess, tokenClaims.isError]);

  useEffect(() => {
    if (emailValidation.isSuccess || emailValidation.isError) {
      dispatch(setAuthenticated());
    }

    if (emailValidation.isSuccess) {
      if (emailValidation.data) {
        dispatch(setUser(emailValidation.data));
        navigateTo("/home");
      } else {
        navigateTo("/firstlogin");
      }
    }

    if (emailValidation.isError) {
      navigateTo("/firstlogin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValidation.isSuccess, emailValidation.isError]);

  const routes = auth.isAuthenticated ? AppRoutes : AuthRoutes;
  const isLoading = tokenClaims.isLoading || emailValidation.isIdle || emailValidation.isLoading;
  const hasToken = !!Cookie.get("sba");

  return (
    <Layout>
       <Routes>
        {hasToken && isLoading ? <Route path="*" element={<AuthLanding />} /> : routes.map((route, index) => <Route key={index} {...route} />)}
      </Routes>
    </Layout>
  );
}

export default App;

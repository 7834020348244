import "../../assets/css/admin/admin.css";
import AdminNav from "././AdminNav";
import AdminContent from "././AdminContent";
import { useState } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const Admin = () => {
  const { t } = useTranslation();
  const [content, setContent] = useState("admin-home");
  const changeContent = (e) => setContent(e.target.name);

  return (
    <div className="admin-cont">
      <div className="admin-mobile-header d-md-none d-flex align-items-center">
        <IoSettingsOutline />
        <h1>{t("administrator")}</h1>
      </div>
      {/* For Navigation and the contents. */}
      <div className="admin">
        <AdminNav content={content} changeContent={changeContent} />
        <AdminContent content={content} />
      </div>
    </div>
  );
};

export default Admin;

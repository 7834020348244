import React from 'react';
import HourPickerWeb from './HourPickerWeb';
import HourPickerMobile from './HourPickerMobile';

const isWindows = /Windows/.test(navigator.userAgent);

const HourPicker = (props) => {
  if (isWindows === true) {
    return <HourPickerWeb {...props} />;
  } else {
    return <HourPickerMobile {...props} />;
  }
};

export default HourPicker;
import "../../../assets/css/approvals/approver management/ApproverManagement.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  GridColumn as Column
} from "@progress/kendo-react-grid";
import { filterBy } from "@progress/kendo-data-query";
import { Switch } from "@progress/kendo-react-inputs";
//import MobileViewTable from "../../MobileViewTable";
import { getter } from "@progress/kendo-react-common";
import fetchClient from "../../../axios/fetch";
import EnableDisableDialog from "./EnableDisableDialog";
const initialFilter = {
  logic: "and",
  filters: [
    {
      field: "fname",
      operator: "contains",
      value: "",
    },
  ],
};

// Const for the pagination
const initialDataState = {
  skip: 0,
  take: 5,
};

const ApproverManagement = () => {
  const { t } = useTranslation();
  const DATA_ITEM_KEY = "id";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const [filter, setFilter] = useState(initialFilter);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [approverList, setApproverList] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [limit, setLimit] = useState(false);

  const [approver, setApprover] = useState({
    id: "",
    isApprover: "",
  });

  const [approverCount, setApproverCount] = useState(0);

  // Const for Pagination of data
  const [page, setPage] = useState(initialDataState);
  const pageChange = (event) => {
    setPage(event.page);
  };

  const toggleDialog = (e) => {
    setShowDialog(!showDialog);
  };

  const toggleSwitch = (id, isApprover) => {
    let title = isApprover
      ? "Do you want to disable the user?"
      : "Do you want to enable the user?";
    setDialogTitle(title);
    if (approverCount === 3 && isApprover === false) {
      setLimit(true);
    } else if (isApprover === true) {
      setLimit(false);
    }
    const currentDate = new Date();

    setApprover({
      id: id,
      isApprover: !isApprover,
      UpdatedDate: currentDate,
    });

    toggleDialog();
  };

  const handleChecked = () => {
    if (approverCount < 3 || approver.isApprover === false) {
      try {
        fetchClient()
          .put("api/approval/updateapprover", {
            ...approver,
          })
          .then((res) => {
            getApprovers();
            setShowDialog(!showDialog);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };

  const switchToggle = (props) => {
    const { id, isApprover } = props.dataItem;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 16,
          paddingBottom: 16,
          border: 1,
        }}
      >
        <Switch
          size="small"
          onChange={(e) => {
            toggleSwitch(id, isApprover);
          }}
          checked={isApprover}
        />
      </div>
    );
  };

  // Const for Pagination of data
  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
  }, [currentWidth]);

  // Const for selected items
  const [selectedState] = useState({});

  useEffect(() => {
    getApprovers();
  }, []);

  const getApprovers = () => {
    try {
      fetchClient()
        .get("api/approval/approvers")
        .then((res) => {
          const approverCount = res.data.data.reduce((count, item) => {
            if (item.isApprover) {
              count += 1;
            }
            return count;
          }, 0);

          setApproverCount(approverCount);

          setApproverList(
            res.data.data.map((dataItem) =>
              Object.assign(
                {
                  selected: false,
                },
                dataItem
              )
            )
          );
        });
    } catch (error) {}
  };

  return (
    <div className="approval-management">
      <div className="approval-management-title">
        {t("approver_management")}
      </div>

      <div className="tbl">
        <div className="">
          <Grid
            style={{
              alignContent: "center",
              alignItems: "center",
            }}
            data={filterBy(
              approverList
                .map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))
                .slice(page.skip, page.take + page.skip),
              filter
            )}
            skip={page.skip}
            take={page.take}
            total={approverList.length}
            pageable={true}
            onPageChange={pageChange}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
            }}
            sortable={true}
            filterable={true}
            filter={filter}
            onFilterChange={(e) => setFilter(e.filter)}
          >
            <Column
              field="isApprover"
              title="Enable/Disable"
              width="20%"
              cell={switchToggle}
              filterable={false}
            />
            <Column field="fname" title="Name" width="40%" />
            <Column field="email" title="E-mail Address" width="40%" />
          </Grid>
        </div>
      </div>

       {showDialog && (
        <EnableDisableDialog
          toggleDialog={toggleDialog}
          dialogTitle={dialogTitle}
          handleChecked={handleChecked}
          limit={limit}
        />
      )} 
    </div>
  );
};

export default ApproverManagement;

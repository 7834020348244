import "../../assets/css/approvals/MeetingRoomApproval.css";
import { useState } from "react";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import ApprovalContent from "./ApprovalContent"
import ApprovalNav from "./ApprovalNav";

const MeetingRoomApproval = () => {
  const { t } = useTranslation();
 const [content, setContent] = useState("approval-booking-list");
 const changeContent = (e) => setContent(e.target.name);

  return (
    <div className="approval-cont">
      <div className="approval-mobile-header d-md-none d-flex align-items-center">
        <BsFileEarmarkCheck />
        <h1>{t("meeting_room_approval")}</h1>
      </div>
      {/* For Navigation and the contents. */}
      <div className="approval">
      <ApprovalNav content={content} changeContent={changeContent} />
      <ApprovalContent content={content} />
      </div>
    </div>
  );
};

export default MeetingRoomApproval;

import Landing from "./components/login/Landing";
import InvalidLogin from "./components/login/InvalidLogin";
import FirstLogin from "./components/login/FirstLogin";
import Admin from "./components/admin/Admin";
import Home from "./components/home/Home";
import Report from "./components/reports/Report";
import BookCancelModal from "./components/home/BookCancelModal";
import BookSuccessModal from "./components/home/BookSuccessModal";
import Sidebar from "./components/navbar/Sidebar";
import PingLogin from "./components/login/PingLogin";
import TopNav from "./components/navbar/TopNav";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineShowChart } from "react-icons/md";
import { BiHomeAlt } from "react-icons/bi";
import { BsFileEarmarkCheck } from "react-icons/bs";
import AuthLanding from "./components/login/AuthLanding";
import MeetingRoomApproval from "./components/approvals/MeetingRoomApproval";
import BookForApprovalModal from "./components/home/BookForApprovalModal";

const AppRoutes = [
  {
    path: "/",
    element: (
      <div className="d-flex flex-column flex-md-row w-100">
        <Sidebar />
        <div className="right-content w-100">
          <TopNav title="home" icon={<BiHomeAlt />} />
          <Home />
        </div>
      </div>
    ),
  },
  {
    index: true,
    path: "/home",
    element: (
      <div className="d-flex flex-column flex-md-row w-100">
        <Sidebar />
        <div className="right-content w-100">
          <TopNav title="home" icon={<BiHomeAlt />} />
          <Home />
        </div>
      </div>
    ),
  },
  {
    path: "/firstLogin",
    element: <FirstLogin />,
  },
  {
    path: "/admin",
    element: (
      <div className="d-flex flex-column flex-md-row w-100">
        <Sidebar />
        <div className="right-content w-100">
          <TopNav title="administrator" icon={<IoSettingsOutline />} />
          <Admin />
        </div>
      </div>
    ),
  },
  {
    path: "/report",
    element: (
      <div className="d-flex flex-column flex-md-row w-100">
        <Sidebar />
        <div className="right-content w-100">
          <TopNav title="reports" icon={<MdOutlineShowChart />} />
          <Report />
        </div>
      </div>
    ),
  },
  {
    path: "/approvals",
    element: (
      <div className="d-flex flex-column flex-md-row w-100">
        <Sidebar />
        <div className="right-content w-100">
          <TopNav title="Meeting room approval" icon={<BsFileEarmarkCheck />} />
          <MeetingRoomApproval />
        </div>
      </div>
    ),
  },
  {
    path: "/bookSuccessful",
    element: (
      <div className="d-flex flex-column flex-md-row w-100">
        <Sidebar />
        <BookSuccessModal />
      </div>
    ),
  },
  {
    path: "/bookCancel",
    element: (
      <div className="d-flex flex-column flex-md-row w-100">
        <Sidebar />
        <BookCancelModal />
      </div>
    ),
  },
  {
    path: "/bookForApproval",
    element: (
      <div className="d-flex flex-column flex-md-row w-100">
        <Sidebar />
        <BookForApprovalModal />
      </div>
    ),
  },
];

const AuthRoutes = [
  {
    index: true,
    element: <Landing />,
  },
  {
    path: "/pingLogin",
    element: <PingLogin />,
  },
  {
    path: "/auth",
    element: <AuthLanding />,
  },
  {
    path: "/invalidlogin",
    element: (
      <>
        <InvalidLogin />
      </>
    ),
  },
];

export { AppRoutes, AuthRoutes };

import "../../assets/css/login/InvalidLogin.css";

//Serve as loading page only
const AuthLanding = () => {
 
  return (
    <div className="invalid-login">
      <div className="d-flex flex-column gap-4 justify-content-center align-items-center p-3">
        <h1 className="fw-bold">Loading . . .</h1>
      </div>
    </div>
  );
};

export default AuthLanding;

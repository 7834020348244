import i18n from "i18next";

const SidebarSettings = ({ items, handleBack, setting }) => {
  const getStyle = (item) => {
    let style = {};
    switch (setting) {
      case "language":
        style = i18n.language === item.code ? { color: "#16bae7" } : {};
        break;
      case "country":
        style =
          localStorage.getItem("country_code") === item.code
            ? { color: "#16bae7" }
            : {};
        break;
      case "office":
        style =
          localStorage.getItem("office") === item.id
            ? { color: "#16bae7" }
            : {};
        break;
      case "city":
        style =
          localStorage.getItem("city") === item.id ? { color: "#16bae7" } : {};
        break;
      default:
        style = {};
    }
    // console.log(style, item);
    return style;
  };

  return (
    <div className="sidebar-top">
      <p onClick={handleBack} className="back">
        Back
      </p>
      {items.map((item, idx) => (
        <p
          key={idx}
          style={getStyle(item)}
          onClick={item.func ?? undefined}
          className="item"
        >
          {item.label}
        </p>
      ))}
    </div>
  );
};

export default SidebarSettings;

import "../../../assets/css/admin/seat capacity/SeatCapacityHistoryDialog.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";
import fetchClient from "../../../axios/fetch";

const SeatCapacityHistoryDialog = ({
  toggleDialog,
  setSuccess,
  gethistory,
  id,
}) => {
  // Set Dialog State
  const { t } = useTranslation();
  const handleSubmit = () => {
    fetchClient()
      .delete(`api/seatcapacity/delete/${id}`)
      .then((res) => {
        // console.log(res);
        gethistory();
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 10000);
      });
    // .catch((err) => console.log(err));
    toggleDialog();
  };
  return (
    <div>
      <div>
        <Dialog onClose={toggleDialog} width="480px" height="172px">
          <div className="dialog-container1">
            <p className="dialog-title1">{t("modal_constrain")}</p>
            <div className="dialog-footer1">
              <button className="dialog-btn btn-no1" onClick={toggleDialog}>
                {t("no")}
              </button>
              <button className="dialog-btn btn-yes1" onClick={handleSubmit}>
                {t("yes")}
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default SeatCapacityHistoryDialog;

import { useState, useRef, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import "../../assets/css/navbar/TopNav.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Tooltip } from "@progress/kendo-react-tooltip";
//import clFlag from "../../assets/img/emojione_flag-for-chile.png";
import coFlag from "../../assets/img/circle_Col_Chi.png";
import phFlag from "../../assets/img/emojione_flag-for-philippines.png";
import office1 from "../../assets/img/sample_office1.webp";
import { BsBuildings, BsGeoFill, BsGlobe, BsTranslate } from "react-icons/bs";
import fetchImg from "../../axios/imgFetch";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import i18n from "i18next";
import fetchClient from "../../axios/fetch";
import { officeChange, countryChange } from "../../reducer/slice/reloadSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const TopNav = ({ title, icon }) => {
  const { t } = useTranslation();
  const officeTrigger = useSelector((state) => state.reload.office);
  const cityTrigger = useSelector((state) => state.reload.city);

  const [city, setcity] = useState([]);
  const [office, setoffice] = useState([]);
  const [curOffice, setCurOffice] = useState(
    Number(localStorage.getItem("office"))
  );
  let Navigate = useNavigate();
  const dispatch = useDispatch();
  const sampleCountries = [
    {
      name: "DDC-P",
      img: phFlag,
      code: "ph",
    },
    {
      name: "DDC-C",
      img: coFlag,
      code: "co",
    },
    // {
    //   name: "Chile",
    //   img: clFlag,
    //   code: "cl",
    // }
  ];

  // Tooltip state
  const tooltip = useRef(null);
  const refresh = () => window.location.reload(true);

  useEffect(() => {
    reload();
  }, []);

  useEffect(() => {
    reload();
  }, [officeTrigger, cityTrigger]);

  const reload = () => {
    fetchClient()
      .get("api/city")
      .then((res) => {
        setcity(res.data.data);
        // console.log(res.data.data);
      });
    // .catch((err) => console.log(err));
    fetchClient()
      .get("api/office/getoffice/" + localStorage.getItem("city"))
      .then((res) => {
        setoffice(res.data.data);
      });
    // .catch((err) => console.log(err));
  };

  const reloadOffice = () => {
    dispatch(officeChange());
  };

  const reloadCountry = () => {
    dispatch(countryChange());
  };

  return (
    <Navbar className="topnav">
      <Container>
        <Navbar.Brand className="brand">
          {icon && <span>{icon}</span>}
          <h1>{t(title)}</h1>
        </Navbar.Brand>
        <Nav>
          <div
            className="radio-tooltip"
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
          >
            <span className="change-color">
              <DropdownButton
                title={
                  <BsTranslate className="topnav-icon" title={t("language")} />
                }
              >
                <div className="dropdown-cont language">
                  <Dropdown.Item
                    className="item"
                    onClick={() => {
                      i18n.changeLanguage("en");
                    }}
                    style={i18n.language === "en" ? { color: "#16bae7" } : {}}
                  >
                    English
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="item"
                    onClick={() => {
                      i18n.changeLanguage("es");
                    }}
                    style={i18n.language === "es" ? { color: "#16bae7" } : {}}
                  >
                    Spanish
                  </Dropdown.Item>
                </div>
              </DropdownButton>
            </span>
            <Tooltip
              ref={tooltip}
              anchorElement="target"
              position="left"
              openDelay={100}
            />
          </div>
          <div
            className="radio-tooltip"
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
          >
            <span className="change-color">
              <DropdownButton
                title={<BsGlobe className="topnav-icon" title={t("country")} />}
              >
                <div className="dropdown-cont country">
                  {sampleCountries.map((country, index) => (
                    <Dropdown.Item
                      key={index}
                      className="item"
                      onClick={() => {
                        localStorage.setItem("country_code", country.code);
                        reloadCountry();
                        Navigate("/home");
                        reload();
                        refresh();
                      }}
                    >
                      <img src={country.img} alt={`${country.name} flag`} />
                      <p
                        style={
                          country.code === localStorage.getItem("country_code")
                            ? { color: "#16bae7" }
                            : {}
                        }
                      >
                        {country.name}
                      </p>
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            </span>
            <Tooltip
              ref={tooltip}
              anchorElement="target"
              position="top"
              openDelay={100}
            />
          </div>
          <div
            className="radio-tooltip"
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
          >
            <span className="change-color">
              <DropdownButton
                title={
                  <BsBuildings className="topnav-icon" title={t("city")} />
                }
              >
                <div className="dropdown-cont city">
                  {city.map((element, key) => (
                    <Dropdown.Item
                      key={key}
                      style={
                        element.id === Number(localStorage.getItem("city"))
                          ? { color: "#16bae7" }
                          : {}
                      }
                      className="item"
                      onClick={() => {
                        localStorage.setItem("city", element.id);
                        reload();
                      }}
                    >
                      {element.cityName}
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            </span>
            <Tooltip
              ref={tooltip}
              anchorElement="target"
              position="top"
              openDelay={100}
            />
          </div>
          <div
            className="radio-tooltip"
            onMouseOver={(event) =>
              tooltip.current && tooltip.current.handleMouseOver(event)
            }
            onMouseOut={(event) =>
              tooltip.current && tooltip.current.handleMouseOut(event)
            }
          >
            <span className="change-color">
              <DropdownButton
                title={
                  <BsGeoFill className="topnav-icon" title={t("office")} />
                }
              >
                <div className="dropdown-cont office">
                  {office.map((office, index) => (
                    <Dropdown.Item
                      key={index}
                      className="item"
                      onClick={() => {
                        localStorage.setItem("office", office.id);
                        setCurOffice(office.id);
                        reloadOffice();
                        // console.log(fetchImg(office.imgThumbnail));
                      }}
                      style={{
                        background: `url(${
                          office.imgThumbnail === null
                            ? office1
                            : fetchImg(office.imgThumbnail)
                        }) center / cover`,
                        boxShadow:
                          curOffice === office.id
                            ? `inset 0 140px 20px 0 rgba(217, 217, 217, 0.2),
                        inset 0 -60px 100px 5px #00233a, 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 18px rgba(22, 186, 231, 0.7)`
                            : `inset 0 140px 20px 0 rgba(217, 217, 217, 0.2),
                        inset 0 -60px 100px 5px #00233a`,
                      }}
                    >
                      <h2>{office.officeName}</h2>
                      <p>{office.officeAddress}</p>
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            </span>
            <Tooltip
              ref={tooltip}
              anchorElement="target"
              position="top"
              openDelay={100}
            />
          </div>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default TopNav;

/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { BsPencil, BsPlusCircleDotted } from "react-icons/bs";
import "../../../assets/css/admin/additional information/AdditionalInformation.css";
import AdditionalInformationDialog from "./AdditionalInformationDialog";
import { Notification } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { useState, useEffect } from "react";
import fetchClient from "../../../axios/fetch";
import fetchImg from "../../../axios/imgFetch";
import MobileViewTable from "../../MobileViewTable";
import office1 from "../../../assets/img/sample_office1.webp";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { format } from "date-fns";

const AdditionalInformation = () => {
  const { t } = useTranslation();
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [visibleDialogAdditionalInfo, setVisibleDialogAdditionalInfo] =
    useState(false);
  const [selectedAdditionalInfo, setSelectedAdditionalInfo] = useState(null);
  const [notif, setNotif] = useState({
    type: "success",
    msg: "",
  });

  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [currentNum, setCurrentNum] = useState(0);
  //const [historyData, setHistoryData] = useState([]);
  const [historyState, setHistoryState] = useState([]);
  const [state, setState] = useState({});

  const toggleDialogAdditionalInfo = () => {
    setVisibleDialogAdditionalInfo(!visibleDialogAdditionalInfo);
  };
  
  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
  }, [currentWidth]);

  const gethistory = () => {
    fetchClient()
      .get("api/reminder/history")
      .then((res) => {
        const updatedFetchData = res.data.data.map((item) => {
          const dateTime = new Date(item.createdDate);
          // Get time components
          const hours = dateTime.getHours();
          const minutes = dateTime.getMinutes();

          return {
            ...item,
            date: format(new Date(dateTime), "MMM dd, yyyy"),
            time: hours + ":" + minutes,
          };
        });
        setHistoryState(updatedFetchData);
      });

  };

  const pageSizes = [5, 8, 10];
  const createState = (skip, take) => {
    let pagerSettings = {
      buttonCount: 3,
      info: false,
      type: "numeric",
      pageSizes: pageSizes,
      previousNext: true,
      text: "Rows per page",
    };
    return {
      items: historyState.slice(skip, skip + take),
      total: historyState.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings,
    };
  };

  useEffect(() => {
    getAdditionalInfo();
    gethistory();
  }, []);

  const getAdditionalInfo = () => {
    fetchClient()
      .get("api/reminder")
      .then((res) => {
        setAdditionalInfo(
          res.data.data.sort((a, b) => (a.remark > b.remark ? -1 : 1))
        );
      });
    // .catch((err) => console.log(err));
    gethistory();
  };

  const selectAdditionalInfo = (e) => {
    const office = additionalInfo.find((info) => {
      return info.officeName === e.currentTarget.nextSibling.outerText;
    });
    setSelectedAdditionalInfo(office);
  };

  const handleClick = (e) => {
    toggleDialogAdditionalInfo();
    selectAdditionalInfo(e);
  };

  const pageChange = (event) => {
    setState(createState(event.page.skip, event.page.take));
  };

  useEffect(() => {
    setState(createState(0, 5));
  }, [historyState]);

  // mobile data tabel
  const topLabels = [
    {
      label: t("admin_name"),
      data: "createdByName",
    },
    {
      label: t("link_added"),
      data: "link",
    },
  ];

  const botLabels = [
    {
      label: t("time"),
      data: "time",
    },
    {
      label: t("date"),
      data: "date",
    },
  ];

  return (
    <div className="reminder">
      <Fade>
        {notif.msg && (
          <Notification
            type={{
              style: notif.type,
              icon: true,
            }}
            closable={false}
          >
            <span>{notif.msg}</span>
          </Notification>
        )}
      </Fade>
      <div className="contents">
        {additionalInfo.map((info, idx) => {
          return (
            <div
              className="offices"
              key={idx}
              style={{
                background: `url(${info.imgThumbnail === null
                  ? office1
                  : fetchImg(info.imgThumbnail)
                  }) center / cover`,
              }}
            >
              {info.remark ? (
                <BsPencil onClick={handleClick} />
              ) : (
                <BsPlusCircleDotted onClick={handleClick} />
              )}
              <p>{info.officeName}</p>
            </div>
          );
        })}
      </div>

      <div className="reminderHistory">
        <div>
          <h5 className="reminderHistory-title">{t("history")}</h5>
          <div></div>
          <div className="rehistory">
            {window.screen.width < 992 || currentWidth < 992 ? (
              <MobileViewTable
                currentNum={currentNum}
                setCurrentNum={setCurrentNum}
                data={historyState}
                topLabels={topLabels}
                botLabels={botLabels}
              />
            ) : (
              <Grid
                style={{ alignContent: "center", alignItems: "center" }}
                data={state.items}
                onPageChange={pageChange}
                total={state.total}
                skip={state.skip}
                pageable={state.pageable}
                pageSize={state.pageSize}
                itemsPerPageText="Rows per page"
              >
                <Column
                  field="createdByName"
                  title={t("admin_name")}
                  width="30%"
                />
                <Column field="link" title={t("link_added")} width="40%" />
                <Column field="time" title={t("time")} width="15%" />
                <Column field="date" title={t("date")} width="15%" />
              </Grid>
            )}
          </div>
        </div>
      </div>

      {visibleDialogAdditionalInfo && (
        <AdditionalInformationDialog
          toggleDialog={toggleDialogAdditionalInfo}
          selected={selectedAdditionalInfo}
          setNotif={setNotif}
          getAdditionalInfo={getAdditionalInfo}
        />
      )}
    </div>
  );
};

export default AdditionalInformation;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import * as React from "react";
import { DateInput, DateRangePicker } from "@progress/kendo-react-dateinputs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Dialog } from "@progress/kendo-react-dialogs";
import { BsImages } from "react-icons/bs";
import "../../../assets/css/admin/user constraint/UserConstraintsDialog.css";
import { BsInfoCircle } from "react-icons/bs";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import fetchClient from "../../../axios/fetch";
import axios from "axios";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const UserConstraintDialog = ({
  toggleDialog,
  onToggle,
  selectedState,
  currentWidth,
  mobileSelected,
  setMobileSelected,
  setUserState,
  userState,
  setCurrentNum,
  getHistoryPreview
}) => {
  const { t } = useTranslation();
  const min = new Date();
  const max = new Date(2099, 12, 31);

  const auth = useSelector((state) => state.auth);

  // Const for date picker
  const [seatsnumber, setSeatsnumber] = useState({
    individualRooms: [],
    meetingPodRooms: [],
  });
  // Const for getting the office
  const [office, setOffice] = useState({
    officeName: "Office 1",
  });

  const [errors, setErrors] = useState({});
  const [payload, setPayload] = useState({
    createdBy: auth ? auth.user.id : null,
    userIdentity: [],
    startDate: null,
    endDate: null,
    individualSeats: [],
    areaid: [],
  });
  const [individualValue, setIndividualValue] = useState({
    value: {
      seatNumber: "Select",
    },
  });
  useEffect(() => {
    getRooms();
    getOffice();
    const datepickers = document.querySelectorAll(".k-daterangepicker");
    datepickers.forEach((datepicker) =>
      datepicker.addEventListener("keydown", (e) => {
        if (
          e.keyCode === 37 ||
          e.keyCode === 38 ||
          e.keyCode === 39 ||
          e.keyCode === 40 ||
          e.keyCode === 8
        ) {
          e.preventDefault();
          e.stopPropagation();
        }
      })
    );
  }, []);

  useEffect(() => {
    // console.log(payload);
  }, [payload]);
  const getOffice = () => {
    fetchClient()
      .get("api/office/" + localStorage.getItem("office"))
      .then((res) => {
        setOffice(res.data.data);
      });
  };
  // Conts for getting the rooms by Office
  const getRooms = () => {
    axios
      .all([
        fetchClient().get(
          "api/userconstraint/individual/" + localStorage.getItem("office")
        ),
        fetchClient().get(
          "api/userconstraint/room/" + localStorage.getItem("office")
        ),
      ])

      .then(
        axios.spread((seats, room) => {
          const ids = [];
          for (const id in currentWidth >= 992
            ? selectedState
            : mobileSelected) {
            if (selectedState[id]) {
              ids.push(id);
            }

            setPayload({ ...payload, userIdentity: ids });
          }
          setSeatsnumber({
            meetingPodRooms: room.data.data,
            individualRooms: seats.data.data,
          });
        })
      );

    // .catch((err) => console.log(err));
  };
  // For the Submit button function.
  const handleSubmit = () => {
    onToggle();
    toggleDialog();

    fetchClient()
      .post("api/userconstraint", payload)
      .then((res) => {
        console.log(res.data.data);
        setMobileSelected([]);
        setUserState(userState.map((item) => ({ ...item, selected: false })));
        setCurrentNum(0);
        getHistoryPreview();
      });
    // .catch((err) => console.log(err));
  };
  // For validator we're the set button is disabled if there's missing input.
  const disableSet = () => {
    const err = {};
    if (payload.startDate === null) {
      err.startDate = true;
    }
    if (payload.endDate === null) {
      err.endDate = true;
    }
    if (payload.individualSeats.length === 0 && payload.areaid.length === 0) {
      err.area = true;
    }
    setErrors(err);
  };

  useEffect(() => {
    disableSet();
  }, [payload]);
  // Date range picker label
  const CustomStartDateInput = (props) => {
    return (
      <label>
        <span className="startDate">{t("start_date")}</span>
        <DateInput {...props} label={undefined} />
      </label>
    );
  };
  const CustomEndDateInput = (props) => {
    return (
      <label>
        <span className="endDate">{t("end_date")}</span>
        <DateInput {...props} label={undefined} />
      </label>
    );
  };
  return (
    <Dialog className="sec-data" onClose={toggleDialog}>
      <div className="title">
        <h3
          className="title-dialog"
          style={{
            textAlign: "center",
            gap: "25px",
          }}
        >
          {t("user_constraints")}
        </h3>
        <BsImages />
      </div>
      <div className="information">
        <div>
          <BsInfoCircle className="info" />
        </div>
        <span className="infotext">
          {t("user_restriction1")} <strong>{office.officeName}</strong>,
          {t("user_restriction2")}
        </span>
      </div>
      <div className="date-range">
        <DateRangePicker
          className="dateRange1"
          min={min}
          max={max}
          onFocus={() => {
            const dates = document.querySelectorAll(
              ".k-dateinput .k-input-inner"
            );

            dates.forEach((date) => date.setAttribute("readonly", true));
          }}
          onChange={(e) => {
            if (e.target.value.start && e.target.value.end) {
              setPayload({
                ...payload,
                startDate: format(new Date(e.target.value.start), "yyyy-MM-dd"),
                endDate: format(new Date(e.target.value.end), "yyyy-MM-dd"),
              });
            }
          }}
          startDateInput={CustomStartDateInput}
          endDateInput={CustomEndDateInput}
        />
      </div>

      <div className="area-type">
        <label className="individual">{t("individual")}</label>
        <MultiSelect
          defaultItem="Seats"
          name="Seats"
          onChange={(e) => {
            var hold = [];

            e.value.map((val) => {
              hold.push(val);
            });
            setPayload({ ...payload, individualSeats: hold });
            setIndividualValue({ value: e.target.value });
          }}
          data={seatsnumber.individualRooms}
          textField="seatNumber"
          value={individualValue.value}
          placeholder={t("seat")}
        />
      </div>
      <div className="area-type">
        <label className="Room-type">{t("user_meetingpods")}</label>
        <MultiSelect
          defaultItem="Room"
          name="meetroomName"
          onChange={(e) => {
            var hold = [];
            e.value.map((element) => {
              hold.push(element.id);
            });

            setPayload({ ...payload, areaid: hold });
          }}
          data={seatsnumber.meetingPodRooms}
          textField="roomName"
          placeholder={t("room")}
        />
      </div>
      <div className="buttons">
        <button className="Nobtn" onClick={toggleDialog}>
          {t("cancel")}
        </button>
        {/* Temporary Notification function */}
        <React.Fragment>
          <button
            className="Yesbtn btn"
            disabled={Object.keys(errors).length !== 0}
            onClick={handleSubmit}
          >
            {t("set")}
          </button>
        </React.Fragment>
      </div>
    </Dialog>
  );
};

export default UserConstraintDialog;

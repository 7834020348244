import { NavLink, Nav, NavItem } from "reactstrap";
import "../../assets/css/admin/AdminNav.css";
import { useTranslation } from "react-i18next";
const AdminNav = ({ content, changeContent }) => {
  const { t } = useTranslation();
  return (
    <Nav className="admin-nav flex-column flex-md-row" justified>
      <NavItem>
        <NavLink
          className="d-flex justify-content-center align-items-center"
          href="#"
          name="admin-home"
          onClick={changeContent}
          active={content === "admin-home"}
        >
          {t("user_constraints")}
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          href="#"
          className="d-flex justify-content-center align-items-center"
          name="admin-seat"
          onClick={changeContent}
          active={content === "admin-seat"}
        >
          {t("seat_capacity")}
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          href="#"
          className="d-flex justify-content-center align-items-center"
          name="admin-co"
          onClick={changeContent}
          active={content === "admin-co"}
        >
          {t("countries_and_offices")}
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          href="#"
          className="d-flex justify-content-center align-items-center"
          name="admin-ai"
          onClick={changeContent}
          active={content === "admin-ai"}
        >
          {t("additional_information")}
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default AdminNav;

import { BsPencil } from "react-icons/bs";
import fetchImg from "../../../axios/imgFetch";
import office1 from "../../../assets/img/sample_office1.webp";

const COSquare = ({ content, setEditMode, selectItem, selectedOpt }) => {
  const handleClick = (e) => {
    setEditMode();
    selectItem(e);
  };

  return (
    <div
      className={
        selectedOpt === "offices" ? "co-square co-offices" : "co-square"
      }
      style={
        selectedOpt === "offices"
          ? {
              background: `url(${
                content.imgThumbnail === null
                  ? office1
                  : fetchImg(content.imgThumbnail)
              }) center / cover`
            }
          : {}
      }
    >
      <button onClick={handleClick}>
        <BsPencil />
      </button>
      <p>{selectedOpt === "offices" ? content.officeName : content}</p>
    </div>
  );
};

export default COSquare;

/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import MobileViewTable from "../../../MobileViewTable";
import "../../../../assets/css/admin/countries and offices/AreasTable.css";
import { useTranslation } from "react-i18next";

const AreasTable = ({
  officeData,
  setAreaToEdit,
  setAreasMode,
  setAreasNotif,
  getAllAreas,
}) => {
  const [areasData, setAreasData] = useState([]);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const [currentNum, setCurrentNum] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setAreasData(
      officeData.areas
        ?.map((area) => {
          let areaType;
          switch (area.areaType) {
            case "individual":
              areaType = t("individual");
              break;
            case "meeting room":
              areaType = t("meeting_room");
              break;
            case "meeting pod":
              areaType = t("meeting_pod");
              break;
            default:
              break;
          }
          return {
            ...area,
            areaType: areaType[0].toUpperCase() + areaType.slice(1),
            seatRange: `${area.seatMinRange} - ${area.seatMaxRange}`,
          };
        })
        .sort((a, b) => (a.seatMinRange > b.seatMinRange ? 1 : -1))
    );
  }, [officeData.areas]);

  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);

    window.addEventListener("resize", handleWindowResize);
  }, [currentWidth]);

  // const handleDelete = (id) => {
  //   const area = officeData.areas.find((area) => area.id === id);
  //   setSelected(area);
  //   toggleDeleteDialog();
  // };

  // const handleSaveDelete = () => {
  //   //const area = officeData.areas.find((area) => area.id === selected.id);
  //   fetchClient()
  //     .delete(`api/office/RemoveOfficeArea/${selected.id}`)
  //     .then((res) => {
  //       console.log(res);
  //       setAreasNotif({
  //         type: "success",
  //         msg: "Area deleted successfully",
  //       });
  //       getAllAreas(officeData);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setAreasNotif({
  //         type: "error",
  //         msg: "Something went wrong, area not deleted",
  //       });
  //     });
  //   setSelected(null);
  //   toggleDeleteDialog();

  //   // setOfficeData({
  //   //   ...officeData,
  //   //   areas: officeData.areas.filter((curArea) => curArea !== area)
  //   // });

  //   // // checks if delete area is an existing area
  //   // // only existing areas will be added to the areas to delete state
  //   // if (!newAreasId.includes(selected.id)) {
  //   //   setAreasToDelete(areasToDelete.concat(selected.id));
  //   // }

  //   // // checks if deleted area is included in edited areas
  //   // // if it is, remove from edited areas state
  //   // if (editedAreasId.includes(selected.id)) {
  //   //   setEditedAreasId(editedAreasId.filter((id) => id !== selected.id));
  //   // }
  //   setTimeout(() => {
  //     setAreasNotif({
  //       type: "success",
  //       msg: "",
  //     });
  //   }, 2500);
  // };

  //data for mobile view
  const topLabels = [
    // {
    //   label: t("delete"),
    //   data: () => (
    //     <BsTrash
    //       onClick={() => handleDelete(areasData[currentNum].id)}
    //       className="deleteIcon"
    //     />
    //   ),
    // },
    {
      label: t("edit"),
      data: () => (
        <BsPencilSquare
          onClick={() => {
            setAreasMode("edit");
            setAreaToEdit({
              ...areasData[currentNum],
              areaType: areasData[currentNum].areaType.toLowerCase(),
            });
          }}
          className="editIcon"
        />
      ),
    },
    {
      label: t("areaType"),
      data: "areaType",
    },
    {
      label: t("area_name"),
      data: "roomName",
    },
    {
      label: t("Capacity"),
      data: "capacity",
    },
    {
      label: t("Seat_range"),
      data: "seatRange",
    },
  ];
  const botLabels = [
    {
      label: t("area_name"),
      data: "roomName",
    },
  ];

  // const deleteCell = ({ dataItem }) => {
  //   return (
  //     <td>
  //       <BsTrash
  //         onClick={() => handleDelete(dataItem.id)}
  //         className="deleteIcon"
  //       />
  //     </td>
  //   );
  // };

  const editCell = ({ dataItem }) => (
    <td>
      <BsPencilSquare
        onClick={() => {
          setAreasMode("edit");
          setAreaToEdit({
            ...dataItem,
            areaType: dataItem.areaType.toLowerCase(),
          });
        }}
        className="editIcon"
      />
    </td>
  );

  return (
    <div className="areas-table">
      {currentWidth > 767 ? (
        <Grid data={areasData}>
          {/* <Column title={t("delete")} cell={deleteCell} width={"80px"} /> */}
          <Column title={t("edit")} cell={editCell} width={"80px"} />
          <Column field="areaType" title={t("areaType")} />
          <Column field="roomName" title={t("room_name")} />
          <Column field="capacity" title={t("Capacity")} />
          <Column field="seatRange" title={t("Seat_range")} />
        </Grid>
      ) : (
        <MobileViewTable
          currentNum={currentNum}
          setCurrentNum={setCurrentNum}
          data={areasData}
          topLabels={topLabels}
          botLabels={botLabels}
        />
      )}
      {/* {showDelete && (
        <DeleteAreaDialog
          area={selected.roomName}
          setAreasNotif={setAreasNotif}
          toggleDialog={toggleDeleteDialog}
          handleSaveDelete={handleSaveDelete}
        />
      )} */}
    </div>
  );
};

export default AreasTable;

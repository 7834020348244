import React from "react";
import {
  BsCalendarEvent,
  BsClock,
  BsDoorOpen,
  BsGeoAltFill,
  BsInfoCircle,
  BsPeopleFill,
  BsPersonFill,
  BsPinAngle,
} from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/home/BookCancelModal.css";
import checkIcon from "../../assets/img/check-icon.png";

const BookCancelModal = () => {
  //Navigation
  let Navigation = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="right-content w-100">
        <div className="book-cancel">
          <div className="book-cancel-box row m-0 px-4 py-5 px-md-5">
            <div className="book-cancel-header p-0">
              <img src={checkIcon} alt="check icon" />
              <h3 className="iBooking">Request was successfully cancelled</h3>
            </div>
            <div className="book-cancel-content p-0">
              <div className="d-flex row p-0 m-0">
                <div className="col-12 col-md-6 d-flex align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsCalendarEvent />
                  </div>
                  <div>
                    <span>{location.state.payload.date}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsClock />
                  </div>
                  <div>
                    <span>{location.state.payload.time}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsPersonFill />
                  </div>
                  <div>
                    <span>
                      {location.state.payload.bookingType === "Individual"
                        ? "Individual"
                        : location.state.payload.bookingType === "Meeting pod"
                          ? "Meeting pod"
                          : "Meeting room"}
                    </span>
                  </div>
                </div>

                <div className="col-12 col-md-6 d-flex align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsDoorOpen />
                  </div>
                  <div>
                    <span className="book-cancel-highlighted">
                      {/* Room Name - #SeatNumber */}
                      {location.state.payload.bookingType === "Individual"
                        ? `${location.state.payload.roomName}` +
                        " - " +
                        "Seat # " +
                        `${location.state.payload.seatNumber}`
                        : location.state.payload.bookingType === "Meeting pod"
                          ? location.state.payload.roomName
                          : location.state.payload.roomName}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-self-start align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsGeoAltFill />
                  </div>
                  <div>
                    <span>{location.state.payload.officeName}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsPinAngle />
                  </div>
                  <div className="cancel-link" >
                    <span>
                      <div className="d-flex flex-column">
                        <strong>Additional Information:</strong>
                        <a
                          href={location.state.link}
                        >
                          Health and Safety Reminders - Click Here
                        </a>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex align-self-start align-items-center gap-4 mb-4 p-0 px-md-5">
                  <div>
                    <BsPeopleFill />
                  </div>
                  <div>
                    <span className="email-names">
                    {`${location.state.payload.internalParticipant || ''}${location.state.payload.internalParticipant && location.state.payload.externalParticipant ? '; ' : ''}${location.state.payload.externalParticipant || ''}`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center justify-content-center gap-4 mb-4 mt-md-4">
                <div className="info-icon">
                  <BsInfoCircle />
                </div>
                <div className="info-message">
                  <span>
                    You will receive an e-mail notification with this
                    information
                  </span>
                </div>
              </div>
            </div>
            <div className="book-cancel-footer d-flex justify-content-center p-0 px-2 mt-3 mt-md-5">
              <button
                className="home-book-btn"
                onClick={() => Navigation("/home")}
              >
                <span>Home</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookCancelModal;

import "../../../assets/css/approvals/approver management/EnableDisableDialog.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { t } from "i18next";
import React from "react";
import booking_warning from "../../../assets/img/booking_warning.png";
import booking_pending from "../../../assets/img/booking_pending.png";
import { SvgIcon } from "@progress/kendo-react-common";
import { xIcon } from "@progress/kendo-svg-icons";

const EnableDisableDialog = ({
  toggleDialog,
  dialogTitle,
  handleChecked,
  limit,
}) => {
  return (
    <>
      {limit ? (
        <Dialog
          className="enableDisableDialog"
          onClose={toggleDialog}
          width={442}
        >
          <div>
            <img
              src={booking_pending}
              alt="Warning Symbol"
            />
          </div>
          <div className="maxApprover">
              <p>
                You can only select a maximum of <b>three</b> approvers!
              </p>
              <p>Return to the approvers page to select them.</p>
          </div>

          <div className="buttons">
            <button
              onClick={toggleDialog}
              className="Yesbtn btn"
              style={{
                padding: "20px",
              }}
            >
              {t("return")}
            </button>
          </div>
        </Dialog>
      ) : (
        <Dialog
          className="enableDisableDialog"
          onClose={toggleDialog}
          width={442}
        >
          <div className="close-btn-container">
            <button onClick={toggleDialog}>
              <SvgIcon icon={xIcon} size="medium" />
            </button>
          </div>
          <div className="title">
            <h3
              className="title-dialog"
              style={{
                textAlign: "center",
                gap: "25px",
                marginTop: "25px",
                lineHeight: "30px",
              }}
            >
              {dialogTitle}
            </h3>
          </div>
          <div className="img-container">
            <img src={booking_warning} alt="warning"/>
          </div>
          <div className="buttons">
            <button
              onClick={handleChecked}
              className="Yesbtn btn"
              style={{
                padding: "20px",
              }}
            >
              {t("Yes")}
            </button>
            <button
              onClick={toggleDialog}
              className="Nobtn"
              style={{
                padding: "20px",
                color: "#16bae7",
                borderColor: "#16bae7",
                backgroundColor: "transparent",
              }}
            >
              {t("No")}
            </button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default EnableDisableDialog;

/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog } from "@progress/kendo-react-dialogs";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { Notification } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { Loader } from "@progress/kendo-react-indicators";
import FormInput from "../FormInput";
import OfficeAreas from "./OfficeAreas";
import "../../../../assets/css/admin/countries and offices/OfficesDialog.css";
import AreasTable from "./AreasTable";
import { BsInfo, BsArrowLeftCircle, BsUpload, BsPlusLg } from "react-icons/bs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { officeChange } from "../../../../reducer/slice/reloadSlice";
import fetchClient from "../../../../axios/fetch";
import fetchImg from "../../../../axios/imgFetch";
import axios from "axios";
import { useTranslation } from "react-i18next";

const OfficesDialog = ({
  mode,
  toggleDialog,
  selected,
  countries,
  setNotif,
  getOffices,
}) => {
  const today = new Date();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // initial data structure
  const initialData = {
    officeId: 0,
    officeName: "",
    officeAddress: "",
    cityId: null,
    country:
      localStorage.getItem("country_code") === "ph" ? "Philippines" : "Colombia",
    officeImg: null,
    officethumb: null,
    areas: [],
    startOfficeHour: new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate(),
      8,
      0,
      0
    ),
    endOfficeHour: new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate(),
      22,
      0,
      0
    ),
  };

  // stores office data changes
  const [officeData, setOfficeData] = useState(initialData);

  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  // stores an object with properties that states true or false depending if a component has an error
  const [errors, setErrors] = useState({});

  // stores city array depending on the selected country
  const [currentCities, setCurrentCities] = useState([]);

  // tracks total area capacity
  const [totalCapacity, setTotalCapacity] = useState(0);

  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [areasMode, setAreasMode] = useState("");
  const [currentAreas, setCurrentAreas] = useState([]);
  const [areaToEdit, setAreaToEdit] = useState(null);
  const [areasNotif, setAreasNotif] = useState({
    type: "success",
    msg: "",
  });
  const [imgState, setImgState] = useState({
    officeLayout: null,
    imgThumbnail: null,
    imgThumbnailName: "",
  });

  // disable state for cities dropdown
  // const [isDisabled, setIsDisabled] = useState(mode === "edit" ? false : true);

  // counts how many times cities were changed so dropdown data will be set to default if value is not zero
  //const getCitiesCount = useRef(mode === "edit" ? 0 : 1);

  useEffect(() => {
    if (mode === "edit") {
      getOffice();
    }
    getCities();
  }, []);

  const getCities = () => {
    fetchClient()
      .get("api/city/1")
      .then((res) => {
        setCurrentCities(res.data.data);
      });
  };

  const reloadOffices = () => {
    dispatch(officeChange());
  };

  const getAllAreas = (prevState) => {
    fetchClient()
      .get(`api/office/getallareabyoffice?office=${selected.id}`)
      .then((res) => {
        setOfficeData({ ...prevState, areas: res.data.data });
      })
      .then(() => setIsLoading(false));
    // .catch((err) => console.log(err));
  };

  const getOffice = () => {
    fetchClient()
      .get(`api/office/${selected.id}`)
      .then((res) => {
        const country = localStorage.getItem("country_code");
        const startTime = res.data.data.startOfficeHour.split(":");
        const endTime = res.data.data.endOfficeHour.split(":");
        const data = {
          ...res.data.data,
          country: country === "ph" ? "Philippines" : "Colombia",
          startOfficeHour: new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            today.getDay(),
            startTime[0],
            startTime[1],
            startTime[2]
          ),
          endOfficeHour: new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            today.getDay(),
            endTime[0],
            endTime[1],
            endTime[2]

          )
         
        };
//         data.endOfficeHour.setMinutes(data.endOfficeHour.getMinutes() + 1);

       
        setOfficeData(data);

        let tempImg = {};
        // put images to image state
        tempImg = {
          officeLayout: null,
          imgThumbnail: fetchImg(data.imgThumbnail),
          imgThumbnailName: data.imgThumbnail,
        };
        if (data.officeLayout) {
          tempImg = {
            ...tempImg,
            officeLayout: fetchImg(data.officeLayout),
          };
          setLayoutStyling(fetchImg(data.officeLayout));
        }
        setImgState(tempImg);
        return { ...data, officethumb: null, officeImg: null };
      })
      .then((prevState) => getAllAreas(prevState))
      .catch((err) => {
        // console.log(err);
        toggleDialog();
        setNotif({
          type: "error",
          msg: "Cannot load office, try again later.",
        });
        setTimeout(() => {
          setNotif({
            type: "success",
            msg: "",
          });
        }, 10000);
      });
  };

  // clicks the hidden file input elements
  const clickUploadBtn = (e) => {
    if (e.currentTarget.className.includes("thumbnail-upload")) {
      document.querySelector("#uploadbtn").click();
    } else if (e.currentTarget.className.includes("layout-upload")) {
      document.querySelector("#layoutbtn").click();
    }
  };

  // sets the styling of the upload office layout button
  const setLayoutStyling = (img) => {
    // change appearance of the layout
    const backdrop = document.querySelector(".layout-upload > div");
    const layoutDiv = document.querySelector(".layout-upload");
    backdrop.style.backdropFilter = "brightness(35%)";
    layoutDiv.style.background = `url("${img}") no-repeat center / cover`;
  };

  const handleLayout = (e) => {
    setLayoutStyling(URL.createObjectURL(e.target.files[0]));
    // stores uploaded office layout
    setImgState({ ...imgState, officeLayout: e.target.files[0] });
  };

  const handleChange = (e) => {
    if (e.target.name === "cityId") {
      setOfficeData({
        ...officeData,
        cityName: e.target.value,
        cityId: currentCities.find((city) => city.cityName === e.target.value)
          .id,
      });
    } else if (e.target.name === "imgThumbnail") {
      setImgState({
        ...imgState,
        imgThumbnail: e.target.files[0],
        imgThumbnailName: e.target.files[0].name,
      });
    } else {
      setOfficeData({
        ...officeData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const areasValidate = () => {
    // validate every field in area
    const areas = [];
    const pattern = /^[1-9]\d*$/;
    if (mode === "create" || areasMode === "create") {
      const finArea = currentAreas
        .filter((area) => area.roomName)
        .map((item) => ({
          ...item,
          seatMaxRange: item.seatMaxRange || totalCapacity,
        }));

      finArea.forEach((area) => {
        // check if inputted capacity value is a number
        if (!area.capacity || !area.capacity.toString().match(pattern)) {
          areas.push({
            id: area.id,
            capacity: true,
          });
          // check if meeting room capacity is less than 2
        } else if (area.areaType === "meeting room" && area.capacity < 2) {
          areas.push({
            id: area.id,
            capacity: true,
          });
          // check if meeting pod capacity is less than 2
        } else if (area.areaType === "meeting pod" && area.capacity < 2) {
          areas.push({
            id: area.id,
            capacity: true,
          });
        } else if (area.areaType === "individual" && area.capacity < 1) {
          areas.push({
            id: area.id,
            capacity: true,
          });
        }

        if (
          area.seatMaxRange - area.seatMinRange + 1 ===
          Number(area.capacity)
        ) {
          // compare current area to other areas
          // check if there are overlaps
          const toCompare = finArea
            ?.concat(mode !== "create" ? officeData.areas : [])
            ?.sort((a, b) => (a.seatMinRange > b.seatMinRange ? 1 : -1));

          toCompare.forEach((item) => {
            if (item.id === area.id) {
              return;
            } else if (toCompare.indexOf(item) < toCompare.indexOf(area)) {
              // items that has less index of area,
              // area.seatMinRange > item.seatMaxRange
              if (!(area.seatMinRange - item.seatMaxRange > 0)) {
                areas.push({
                  id: area.id,
                  rangeOverlap: true,
                });
              }
            } else if (toCompare.indexOf(item) > toCompare.indexOf(area)) {
              // items that has greater index of area
              // item.seatMinRange > area.seatMaxRange
              if (!(item.seatMinRange - area.seatMaxRange > 0)) {
                areas.push({
                  id: area.id,
                  rangeOverlap: true,
                });
              }
            }
          });
        } else {
          areas.push({
            id: area.id,
            range: true,
          });
        }
      });
    } else {
      const semiArea = currentAreas.find((area) => area.id === areaToEdit.id);
      const area = {
        ...semiArea,
        seatMaxRange: semiArea.seatMaxRange || totalCapacity,
      };

      // compare current area to other areas
      // check if there are overlaps
      const toCompare = officeData.areas
        .map((item) => (item.id === area.id ? area : item))
        .sort((a, b) => (a.seatMinRange > b.seatMinRange ? 1 : -1));
      const obj = {
        id: area.id,
      };

      if (!area.roomName) {
        obj.roomName = true;
      }

      if (!area.capacity || !area.capacity.toString().match(pattern)) {
        obj.capacity = true;
      }

      if (area.seatMaxRange - area.seatMinRange + 1 === Number(area.capacity)) {
        toCompare.forEach((item) => {
          if (item.id === area.id) {
            return;
          } else if (toCompare.indexOf(item) < toCompare.indexOf(area)) {
            // items that has less index of area,
            // area.seatMinRange > item.seatMaxRange
            if (!(area.seatMinRange - item.seatMaxRange > 0)) {
              obj.rangeOverlap = true;
            }
          } else if (toCompare.indexOf(item) > toCompare.indexOf(area)) {
            // items that has greater index of area
            // item.seatMinRange > area.seatMaxRange
            if (!(item.seatMinRange - area.seatMaxRange > 0)) {
              obj.rangeOverlap = true;
            }
          }
        });
      } else {
        obj.range = true;
      }

      if (
        obj.hasOwnProperty("roomName") ||
        obj.hasOwnProperty("capacity") ||
        obj.hasOwnProperty("rangeOverlap") ||
        obj.hasOwnProperty("range")
      ) {
        areas.push(obj);
      }
    }

    return areas;
  };

  const handleSaveArea = () => {
    const areas = areasValidate();
    setErrors({ areas });
    if (areas.length > 0) {
      if (areasMode === "edit") {
        if (areas[0].capacity) {
          setErrorMsg("*Please enter a valid capacity value");
        } else if (areas[0].roomName) {
          setErrorMsg("*Please complete the missing fields to continue");
        } else if (areas[0].rangeOverlap) {
          setErrorMsg("*Seat range overlaps with other existing areas.");
        } else if (areas[0].range) {
          setErrorMsg("*Seat range doesn't match the number of capacity.");
        }
      } else {
        if (areas.some((item) => item.capacity)) {
          setErrorMsg("*Please enter a valid capacity value");
        } else if (areas.some((item) => item.rangeOverlap)) {
          setErrorMsg("*Seat range overlaps with other existing areas.");
        } else if (areas.some((item) => item.range)) {
          setErrorMsg("*Seat range doesn't match the number of capacity.");
        }
      }
    } else {
      if (areasMode === "create") {
        setShowLoader(true);
        const finArea = currentAreas
          .filter((area) => area.roomName)
          .map((area) => ({
            ...area,
            seatMaxRange: area.seatMaxRange || totalCapacity,
          }));

        // checks if an area was added
        if (finArea.length > 0) {
          const addedAreas = finArea.map((area) => {
            let { id, ...newArea } = area;
            return newArea;
          });
          // wait for 0.8s before executing this function
          setTimeout(() => {
            axios
              .all(
                // add validated areas one by one
                addedAreas.map((area) =>
                  fetchClient().post("api/office/createarea", {
                    ...area,
                    areaId: 0,
                    officeId: selected.id,
                    capacity: Number(area.capacity),
                  })
                )
              )
              .then((res) => {
                // console.log(res);
                setAreasNotif({
                  type: "success",
                  msg: t("add_success"),
                });
                getAllAreas(officeData);
              })
              .catch((err) => {
                // console.log(err);
                setAreasNotif({
                  type: "error",
                  msg: "Something went wrong, area(s) not added",
                });
              })
              .finally(() => {
                setShowLoader(false);
                setCurrentAreas([]);
                setAreasMode("");
                setTimeout(() => {
                  setAreasNotif({
                    type: "success",
                    msg: "",
                  });
                }, 2500);
              });
          }, 800);

          // setOfficeData({
          //   ...officeData,
          //   areas: officeData.areas.concat(finArea)
          // });
          // finArea.forEach((area) => {
          //   setNewAreasId((prevAreas) => prevAreas.concat(area.id));
          // });
        } else {
          setShowLoader(false);
          setCurrentAreas([]);
          setAreasMode("");
        }
      } else {
        setShowLoader(true);
        const finArea = currentAreas.find((area) => area.id === areaToEdit.id);
        // checks if the area was edited
        if (JSON.stringify(finArea) !== JSON.stringify(areaToEdit)) {
          // if edited area name is removed or left empty, it will not save the changes
          setTimeout(() => {
            fetchClient()
              .put("api/office/updatearea", {
                ...finArea,
                officeId: selected.id,
                capacity: Number(finArea.capacity),
              })
              .then((res) => {
                // console.log(res);
                setAreasNotif({
                  type: "success",
                  msg: "Area edited successfully",
                });
                getAllAreas(officeData);
              })
              .catch((err) => {
                setAreasNotif({
                  type: "error",
                  msg: "Something went wrong, area not edited",
                });
              })
              .finally(() => {
                setAreaToEdit(null);
                setShowLoader(false);
                setCurrentAreas([]);
                setAreasMode("");
                setTimeout(() => {
                  setAreasNotif({
                    type: "success",
                    msg: "",
                  });
                }, 2500);
              });
          }, 800);

          // setOfficeData({
          //   ...officeData,
          //   areas: officeData.areas.map((area) =>
          //     area.id === finArea.id ? finArea : area
          //   )
          // });

          // // checks if the edited area is an existing area
          // // only existing areas should be added to edited areas state
          // if (!newAreasId.includes(finArea.id)) {
          //   setEditedAreasId(editedAreasId.concat(finArea.id));
          // }
        } else {
          setAreaToEdit(null);
          setShowLoader(false);
          setCurrentAreas([]);
          setAreasMode("");
        }
      }
      setErrorMsg("");
      // remove areas from errors
      setErrors({});
    }
  };

  const showNotif = (status) => {
    if (status === 200) {
      setNotif({
        type: "success",
        msg: `${
          mode === "edit" ? officeData.officeName : "New office "
        } customized successfully.`,
      });
    } else {
      setNotif({
        type: "error",
        msg: "Oops... Something went wrong.",
      });
    }
    setTimeout(() => {
      setNotif({
        type: "success",
        msg: "",
      });
    }, 10000);
  };

  // checks each input for errors and returns the object containing the errors
  const validate = () => {
    const errors = {};
    if (!officeData.officeName) {
      errors.officeName = true;
    }
    if (!officeData.officeAddress) {
      errors.officeAddress = true;
    }
    if (!officeData.country) {
      errors.country = true;
    }
    if (!officeData.cityId) {
      errors.cityId = true;
    }
    if (imgState.imgThumbnail === null) {
      errors.imgThumbnail = true;
    }
    if (imgState.officeLayout === null) {
      errors.officeLayout = true;
    }
    const areas = areasValidate();
    if (areas.length > 0) {
      errors.areas = areas;
    }

    return errors;
  };

  // commented out because country will be static
  // useEffect(() => {
  //   // fetches cities whenever the selected country changes
  //   if (officeData.country) {
  //     // to ensure the selected city is within the selected country
  //     if (getCitiesCount.current !== 0) {
  //       setIsDisabled(true);
  //       setOfficeData({ ...officeData, cityName: "" });
  //     }

  //     const id = countries.find(
  //       (country) => country.name === officeData.country
  //     ).id;
  //     fetchClient()
  //       .get(`/api/city/${id}`)
  //       .then((res) => {
  //         setCurrentCities(res.data.data);
  //         setIsDisabled(false);
  //       })
  //       .catch((err) => console.log(err));
  //     getCitiesCount.current += 1;
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [officeData.country]);

  useEffect(() => {
    if (isSubmit) {
      // checks if the errors object contains any error
      if (Object.keys(errors).length === 0) {
        const finArea =
          mode === "edit"
            ? officeData.areas
            : currentAreas.filter((area) => area.roomName);

        const imgForm = new FormData();
        if (imgState.officeLayout) {
          imgForm.append("officeImg", imgState.officeLayout);
        }
        imgForm.append("officethumb", imgState.imgThumbnail);

        if (finArea.length > 0) {
          if (mode === "edit") {
            let { areas, ...newOfficeData } = officeData;

            axios
              .all([
                fetchClient().put("api/office", {
                  ...newOfficeData,
                  startOfficeHour: officeData.startOfficeHour
                    .toTimeString()
                    .split(" ")[0],
                  endOfficeHour: officeData.endOfficeHour
                    .toTimeString()
                    .split(" ")[0],
                }),
                fetchClient().post(
                  `api/office/fileupload?officeid=${officeData.id}`,
                  imgForm,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                ),
              ])
              // .then(() => {
              //   if (areasToDelete.length > 0) {
              //     return axios.all(
              //       areasToDelete.map((id) =>
              //         fetchClient().delete(`api/office/RemoveOfficeArea/${id}`)
              //       )
              //     );
              //   }
              // })
              // .then(() => {
              //   if (editedAreasId.length > 0) {
              //     const editedAreas = officeData.areas.filter((area) =>
              //       editedAreasId.includes(area.id)
              //     );

              //     return axios.all(
              //       editedAreas.map((area) =>
              //         fetchClient().put("api/office/updatearea", {
              //           ...area,
              //           officeId: selected.id,
              //           capacity: Number(area.capacity)
              //         })
              //       )
              //     );
              //   }
              // })
              // .then(() => {
              //   if (newAreasId.length > 0) {
              //     const addedAreas = officeData.areas.filter((area) =>
              //       newAreasId.includes(area.id)
              //     );

              //     const newAddedAreas = addedAreas.map((area) => {
              //       let { id, ...newArea } = area;
              //       return newArea;
              //     });

              //     return axios.all(
              //       newAddedAreas.map((area) =>
              //         fetchClient().post("api/office/createarea", {
              //           ...area,
              //           areaId: 0,
              //           officeId: selected.id,
              //           capacity: Number(area.capacity)
              //         })
              //       )
              //     );
              //   }
              // })
              .then(() => {
                showNotif(200);
                getOffices();
                reloadOffices();
              })
              .catch((err) => {
                showNotif(-1);
                // console.log(err);
              });
          } else {
            fetchClient()
              .post("api/office", {
                ...officeData,
                areas: finArea.map((area) => ({
                  ...area,
                  id: 0,
                  seatMaxRange: area.seatMaxRange || totalCapacity,
                  capacity: Number(area.capacity),
                })),
                startOfficeHour: officeData.startOfficeHour
                  .toTimeString()
                  .split(" ")[0],
                endOfficeHour: officeData.endOfficeHour
                  .toTimeString()
                  .split(" ")[0],
              })
              .then((res) => {
                const id = res.data.data.id;
                fetchClient()
                  .post(`api/office/fileupload?officeid=${id}`, imgForm, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((res) => {
                    // console.log(res);
                    showNotif(res.status);
                    getOffices();
                    reloadOffices();
                  });
                // .catch((err) => console.log(err));
              });
            // .catch((err) => console.log(err));
          }
          setErrorMsg("");
          setIsSubmit(false);
          toggleDialog();
        } else {
          setIsSubmit(false);
          setErrorMsg("*Please submit atleast one area");
        }
      } else {
        if (errors.areas) {
          if (errors.areas.some((item) => item.capacity)) {
            setErrorMsg("*Please enter a valid capacity value");
          } else if (errors.areas.some((item) => item.rangeOverlap)) {
            setErrorMsg("*Seat range overlaps with other existing areas.");
          } else if (errors.areas.some((item) => item.range)) {
            setErrorMsg("*Seat range doesn't match the number of capacity.");
          }
        } else {
          setErrorMsg("*Please complete the missing fields to continue");
        }

        setIsSubmit(false);
      }
    }
  }, [isSubmit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (mode === "create") {
      setErrors(validate()); // calls validate function and stores the returned error object to the errors state
    }
  };

  return (
    <Dialog className="office-dialog" onClose={toggleDialog}>
      <div className="co-dialog-offices">
        {mode === "edit" && isLoading && (
          <div className="overlay">
            <p>Loading</p>
            <Loader size="large" type="pulsing" />
          </div>
        )}
        <div className="main-cont">
          <h1>
            {mode === "edit"
              ? `Edit (${selected.officeName}) parameters`
              : "Customize the new office"}
          </h1>
          <div className="form container-fluid p-0 m-0">
            <div className="form-content">
              <div className="row p-0 m-0 w-100">
                <div className="basic-details col-12 col-md-7 p-0 m-0">
                  <div className="row m-0">
                    <FormInput
                      placeholder={"Type Name"}
                      handleChange={handleChange}
                      type="basic"
                      value={officeData.officeName}
                      error={errors.officeName}
                      name="officeName"
                      label={`*${t("office_name")}`}
                    />
                    <FormInput
                      placeholder="Type Address"
                      handleChange={handleChange}
                      type="basic"
                      value={officeData.officeAddress}
                      error={errors.officeAddress}
                      name="officeAddress"
                      label={`*${t("office_address")}`}
                    />
                  </div>
                  <div className="row m-0">
                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <Label>*{t("country")}</Label>
                      <DropDownList
                        name="country"
                        style={
                          errors.country
                            ? { border: "1px solid #f31700" }
                            : { border: "1px solid rgba(66, 66, 66, 0.5)" }
                        }
                        onChange={handleChange}
                        disabled={true}
                        value={officeData.country}
                        data={countries.map((country) => country.name)}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <Label>*{t("city")}</Label>
                      <DropDownList
                        name="cityId"
                        style={
                          errors.cityId
                            ? { border: "1px solid #f31700" }
                            : { border: "1px solid rgba(66, 66, 66, 0.5)" }
                        }
                        onChange={handleChange}
                        value={
                          officeData.cityName ||
                          currentCities.find(
                            (city) => selected?.cityId === city.id
                          )?.cityName ||
                          "Select"
                        }
                        data={currentCities.map((city) => city.cityName)}
                      />
                    </div>
                  </div>
                  <div className="thumbnail-input col-12 col-md-6 mb-3">
                    <Label>
                      {mode === "edit"
                        ? `*${t("change_thumbnail")}`
                        : `*${t("choose_thumbnail")}`}
                    </Label>
                    <input
                      onChange={handleChange}
                      type="file"
                      name="imgThumbnail"
                      id="uploadbtn"
                      accept="image/*"
                    />
                    <button
                      style={
                        errors.imgThumbnail
                          ? { border: "1px solid #f31700" }
                          : { border: "1px solid rgba(66, 66, 66, 0.5)" }
                      }
                      onClick={clickUploadBtn}
                      className="thumbnail-upload mb-4 w-100"
                    >
                      <p>{imgState.imgThumbnailName || "Select"}</p>
                      <BsUpload className="upload-icon" />
                    </button>
                  </div>
                </div>
                <div className="layout-input col-12 col-md-5 col-lg-5">
                  <input
                    onChange={handleLayout}
                    type="file"
                    name="officeLayout"
                    id="layoutbtn"
                    accept="image/*"
                  />
                  <button
                    style={
                      errors.officeLayout
                        ? { border: "1px dashed #f31700" }
                        : { border: "1px dashed #16bae7" }
                    }
                    onClick={clickUploadBtn}
                    className="layout-upload w-100"
                  >
                    <div>
                      <BsUpload className="upload-icon" />
                      <p>
                        {imgState.officeLayout
                          ? t("change_layout")
                          : t("upload_office")}
                      </p>
                    </div>
                  </button>
                </div>
              </div>
              <div className="areas">
                <div className="title">
                  {areasMode && (
                    <BsArrowLeftCircle
                      onClick={handleSaveArea}
                      className="back"
                    />
                  )}
                  <div className="main-area">
                    <h2>{t("Areas")}</h2>
                    <Tooltip
                      anchorElement="target"
                      position="top"
                      openDelay={100}
                      appendTo={document.querySelector(".k-dialog")}
                    >
                      <BsInfo className="info" title={t("area_tooltip")} />
                    </Tooltip>
                  </div>
                </div>
                <Fade>
                  {areasNotif.msg && (
                    <Notification
                      type={{
                        style: areasNotif.type,
                        icon: true,
                      }}
                      closable={false}
                    >
                      <span>{areasNotif.msg}</span>
                    </Notification>
                  )}
                </Fade>
                {mode === "edit" && !areasMode && (
                  <button onClick={() => setAreasMode("create")}>
                    <BsPlusLg />
                    <p>{t("add_aree")}</p>
                  </button>
                )}
                <div className="area-capacity row p-0 m-0">
                  {mode === "create" ? (
                    <OfficeAreas
                      currentAreas={currentAreas}
                      setCurrentAreas={setCurrentAreas}
                      errors={errors}
                      setTotalCapacity={setTotalCapacity}
                    />
                  ) : !areasMode ? (
                    <AreasTable
                      officeData={officeData}
                      setAreaToEdit={setAreaToEdit}
                      setAreasMode={setAreasMode}
                      setAreasNotif={setAreasNotif}
                      getAllAreas={getAllAreas}
                    />
                  ) : (
                    <>
                      <OfficeAreas
                        allAreas={officeData.areas}
                        currentAreas={currentAreas}
                        areaToEdit={areaToEdit}
                        setCurrentAreas={setCurrentAreas}
                        errors={errors}
                        areasMode={areasMode}
                        setTotalCapacity={setTotalCapacity}
                      />
                      {showLoader && (
                        <div className="save-loader">
                          <p>Saving</p>
                          <Loader type="pulsing" />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="hours row justify-content-start align-items-center gap-2">
                <p className="col-12 col-md-4 col-lg-3 text-start">
                  {t("avail_work_schedule")}:
                </p>
                <div className="col-6 d-flex gap-4">
                  <div>
                    <Label editorValid={true}>*{t("Start_hour")}</Label>
                    <TimePicker
                      onChange={handleChange}
                      format="hh:mm a"
                      name="startOfficeHour"
                    //  disabled={true}
                      steps={{
                        hour: 0,
                        minute: 30,
                        second: 60,
                      }}
                      value={new Date(officeData.startOfficeHour)}
                    />
                  </div>
                  <div>
                    <Label editorValid={true}>*{t("End_hour")}</Label>
                    <TimePicker
                      onChange={handleChange}
                      format="hh:mm a"
                      name="endOfficeHour"
                      // disabled={true}
                      steps={{
                        hour: 0,
                        minute: 30,
                        second: 60,
                      }}
                      value={new Date(officeData.endOfficeHour)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <p className="errorMsg">{errorMsg}</p>
            <div className="k-form-buttons mb-3">
              <button type={"button"} onClick={toggleDialog}>
                {t("cancel")}
              </button>
              <button
                className="btn"
                disabled={areasMode}
                onClick={handleSubmit}
                type={"submit"}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default OfficesDialog;

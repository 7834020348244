import React, { useEffect, useState } from "react";
import { BsImages } from "react-icons/bs";
import "../../assets/css/home/HomeBookModule.css";
import { Modal, ModalBody } from "react-bootstrap";
// import officeLayout from "../../assets/img/office-layout.png";
import HomeIndividual from "./HomeIndividual";
import HomeNavigation from "./HomeNavigation";
import HomeMeetingPod from "./HomeMeetingPod";
import HomeMeetingRoom from "./HomeMeetingRoom";
import { Loader } from "@progress/kendo-react-indicators";
import { useTranslation } from "react-i18next";
import fetchImg from "../../axios/imgFetch";
import fetchClient from "../../axios/fetch";
import { useSelector } from "react-redux";

const HomeBookModule = () => {
  const { t } = useTranslation();
  //useStates
  const officeTrigger = useSelector((state) => state.reload.office);
  const [modalShow, setModalShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState("individual");
  const [isBooking, setIsBooking] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [officeLayout, setOfficeLayout] = useState("");

  useEffect(() => {
    getOfficeLayout();
  }, [officeTrigger]);

  //Functions
  const setModalHide = () => setModalShow(!modalShow);
  //RadioButton
  const handleChange = (e) => {
    if (e.value === "individual") {
      setErrorMessage();
    } else if (e.value === "meeting pod") {
      setErrorMessage();
    } else if (e.value === "meeting room") {
      setErrorMessage();
    }
    setSelectedValue(e.value);
  };

  const getOfficeLayout = () => {
    fetchClient()
      .get("api/office/" + localStorage.getItem("office"))
      .then((res) => setOfficeLayout(res.data.data.officeLayout));
    // .catch((err) => console.log(err))
  };

  return (
    <>
      <div className="home-booking-box p-2 py-4 p-md-5">
        {isBooking && (
          <div className="overlay">
            <p>Booking</p>
            <Loader size="large" type="pulsing" />
          </div>
        )}
        <div className="home-box">
          <header className="home-header mx-2 mx-md-0">
            <h3>{t("book_a_seat")}</h3>
            <div className="floor-layout">
              <button className="floor-layout-btn" onClick={setModalHide}>
                <span>{t("view_floor_layout")}</span>
                <BsImages className="image-icon" />
              </button>
              <Modal
                show={modalShow}
                onHide={setModalShow}
                dialogClassName="modal-style"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton></Modal.Header>
                <ModalBody className="align-self-center">
                  {officeLayout ? (
                    <img
                      className="office-layout"
                      src={fetchImg(officeLayout)}
                      alt="office layout"
                    />
                  ) : (
                    <p className="no-layout">No layout available</p>
                  )}
                </ModalBody>
              </Modal>
            </div>
          </header>
          <div className="home-content row p-2 p-md-0 m-0 ">
            <HomeNavigation
              selectedValue={selectedValue}
              handleChange={handleChange}
            />
            {selectedValue === "individual" ? (
              <HomeIndividual
                selectedValue={selectedValue}
                setIsBooking={setIsBooking}
                setErrorMessage={setErrorMessage}
              />
            ) : selectedValue === "meeting pod" ? (
              <>
                <HomeMeetingPod
                  selectedValue={selectedValue}
                  setIsBooking={setIsBooking}
                  setErrorMessage={setErrorMessage}
                />
              </>
            ) : (
              <>
                <HomeMeetingRoom
                  selectedValue={selectedValue}
                  setIsBooking={setIsBooking}
                  setErrorMessage={setErrorMessage}
                />
              </>
            )}
          </div>
          <div className="err-message">
            <p>{errorMessage}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBookModule;

import AdminHome from "././user constraint/AdminHome";
import SeatCapacity from "././seat capacity/SeatCapacity";
import CountriesOffices from "././countries and offices/CountriesOffices";
import AdditionalInformation from "././additional information/AdditionalInformation";

const AdminContent = ({ content }) => {
  switch (content) {
    case "admin-home":
      return <AdminHome />;
    case "admin-seat":
      return <SeatCapacity />;
    case "admin-co":
      return <CountriesOffices />;
    case "admin-ai":
      return <AdditionalInformation />;
    default:
      break;
  }
};

export default AdminContent;

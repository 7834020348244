import "../../assets/css/login/InvalidLogin.css";
import { useTranslation } from "react-i18next";
const InvalidLogin = () => {
  const { t } = useTranslation();
  return (
    <div className="invalid-login">
      <div className="d-flex flex-column gap-4 justify-content-center align-items-center p-3">
        <h1 className="fw-bold">{t("validate")}</h1>
        <p>{t("hr")}</p>
      </div>
    </div>
  );
};

export default InvalidLogin;

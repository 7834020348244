import "../../assets/css/approvals/RejectDialog.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { t } from "i18next";
import React from "react";
import { TextArea } from "@progress/kendo-react-inputs";

const RejectDialog = ({
  toggleDialog,
  handleRejectSave,
  handleChangeReason,
  value,
}) => {
  return (
    <Dialog className="reject-dialog" onClose={toggleDialog} width={450}>
      <div className="title">
          {t("reason_for_rejection_request")}
      </div>
      <div className="reason-container">
        <div>{t("write_the_reason_for_rejection")}</div>
        <TextArea
          value={value}
          placeholder="Type Reason"
          onChange={handleChangeReason}
        />
      </div>
      <div className="buttons">
        <button
          onClick={toggleDialog}
          className="Nobtn"
        >
          {t("cancel")}
        </button>

        <button
          onClick={handleRejectSave}
          className="Yesbtn btn"
        >
          {t("save")}
        </button>
      </div>
    </Dialog>
  );
};

export default RejectDialog;
